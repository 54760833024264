import Box from "@jodo-tech/ui-kit-common/design/Box";
import Typography from "@jodo-tech/ui-kit-common/design/Typography";
import moment from "moment";

const PaymentPageUserSummary = ({ userFields, transactionId }) => {
  return (
    <Box>
      {(userFields || [])
        ?.filter((item) => !!item?.fieldValue || !!item?.payload?.value)
        ?.map((item, index) => {
          const label = item?.fieldLabel;
          const value = (() => {
            let tempValue = item?.fieldValue;
            if (item?.fieldType === "object") {
              tempValue = item?.payload?.value?.label;
            } else if (item?.fieldType === "date") {
              tempValue = moment(item?.fieldValue, "YYYY-MM-DD").format("DD-MM-YYYY");
            }
            return tempValue;
          })();
          return (
            <Typography
              key={index}
              sx={{
                typography: { xs: "body2", md: "body1" },
                overflowWrap: "anywhere",
              }}
              mb={2}
              maxWidth={{ xs: "350px", md: "500px" }}
            >
              {label} : <b>{value}</b>
            </Typography>
          );
        })}

      {transactionId && (
        <Typography sx={{ typography: { xs: "body2", md: "body1" } }}>
          Transaction ID : <b>{transactionId}</b>
        </Typography>
      )}
    </Box>
  );
};

export default PaymentPageUserSummary;
