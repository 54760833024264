export const PaymentPageConfigTypes = {
  get PAGES() {
    return {
      PAGE_SETTINGS: "page_settings",
      META: "payments_page",
      USER: "user_detail_item",
      PAYMENT: "payment_item",
      PREVIEW: "page_preview",
    };
  },

  get FORM_TYPES() {
    return {
      DEFAULT: {
        label: "Blank page",
        code: "DEFAULT",
      },
      INTEGRATIONS: {
        label: "Order based pay",
        code: "INTEGRATIONS",
      },
    };
  },

  get POSSIBLE_USER_IDENTIFIER_FIELDS() {
    return [
      {
        label: "Admission Number",
        payload_key: "feeorder.admission_number",
      },
      {
        label: "Identifier",
        payload_key: "feeorder.identifier",
      },
      {
        label: "Payer Phone Number",
        payload_key: "feeorder.phone",
      },
    ];
  },

  get IDENTIFIER_TYPES() {
    return {
      AUTO_GENERATED: { label: "Auto generated", value: "auto_generated" },
      USER_GENERATED: { label: "User entered", value: "user_generated" },
    };
  },

  get ALL_IDENTIFIER_TYPES() {
    return [this.IDENTIFIER_TYPES.AUTO_GENERATED, this.IDENTIFIER_TYPES.USER_GENERATED];
  },

  get FIELD_VARIANTS() {
    return {
      SYSTEM: "system",
      CUSTOM: "custom",
      MANDATORY: "mandatory",
    };
  },

  get USER_FIELD_TYPES() {
    return {
      ALPHANUMERIC: "alphanumeric",
      DROPDOWN: "dropdown",
      DATE: "date",
    };
  },

  get PAYMENT_FIELD_TYPES() {
    return {
      FIXED: "fixed",
      CUSTOM: "custom",
      DROPDOWN: "dropdown",
    };
  },

  get USER_LOOKUP_FIELDS() {
    return [
      {
        code: "identifier",
        label: "Identifier",
      },
      {
        code: "feeorder.new_admission",
        label: "New Admission",
      },
      {
        code: "academic_year_start",
        label: "Academic year",
      },
      {
        code: "stream_id",
        label: "Stream",
      },
      {
        code: "grade_id",
        label: "Stream, Grade",
      },
      {
        code: "date_of_birth",
        label: "Date of Birth",
      },
      {
        code: "admission_number",
        label: "Admission Number",
      },
      {
        code: "feeorder.phone",
        label: "Payer Phone Number",
      },
      {
        code: "feeorder.name",
        label: "Student Name",
      },
    ];
  },
};

export const PaymentPageViewTypes = {
  get STATUS() {
    return {
      EXPIRED: "PAGE_EXPIRED",
      FAILED: "FAILED",
      SUCCESS: "ALREADY_PAID",
      NOTFOUND: "PAGE_NOT_FOUND",
      CANCELLED: "PAGE_CANCELLED",
      USER_CANCELLED: "USER_CANCELLED",
      API_ERROR: "API_ERROR",
      REDIRECT: "REDIRECT",
    };
  },
};
