import Stack from "@jodo-tech/ui-kit-common/design/Stack";
import MuiSkeleton from "@jodo-tech/ui-kit-common/design/Skeleton";
import Box from "@jodo-tech/ui-kit-common/design/Box";

const Skeleton = () => {
  return (
    <Box>
      <Box display="grid" gridTemplateColumns={{ xs: "1fr", md: "1fr 500px" }}>
        <Stack direction="column" sx={{ px: { sx: 0, md: 3 } }}>
          <MuiSkeleton variant="rectangular" width="100%" height="35vh" />
          <MuiSkeleton variant="text" width="100%" sx={{ height: { xs: "18vh", md: "20vh" } }} />
          <MuiSkeleton variant="text" width="100%" sx={{ height: { xs: "18vh", md: "25vh" } }} />
          <MuiSkeleton variant="text" width="100%" sx={{ height: { xs: "17vh", md: "20vh" } }} />
        </Stack>
        <MuiSkeleton
          variant="rectangular"
          sx={{
            width: { xs: "100%", md: "450px" },
            height: { xs: "10vh", md: "97vh" },
            px: { sx: 0, md: 2 },
          }}
        />
      </Box>
    </Box>
  );
};

export default Skeleton;
