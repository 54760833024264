import Box from "@jodo-tech/ui-kit-common/design/Box";
import Typography from "@jodo-tech/ui-kit-common/design/Typography";
import Divider from "@jodo-tech/ui-kit-common/design/Divider";
import CurrencyRupee from "@mui/icons-material/CurrencyRupee";

const PaymentPageFeeSummary = ({ paymentFields, totalAmount }) => {
  return (
    <Box display="flex" flexDirection="column" minHeight={{ xs: "auto", md: "560px" }}>
      <Box flex={1}>
        <Box mb={{ xs: 3, md: 5 }}>
          <Typography
            sx={{
              typography: {
                xs: "subtitle2",
                md: "subtitle1",
                overflowWrap: "anywhere",
              },
            }}
          >
            <b>Payment Info</b>
          </Typography>
        </Box>
        {(paymentFields || [])
          ?.filter((item) => item?.fieldChecked)
          ?.map((item, index) => {
            const label = item?.fieldLabel;
            const value = item?.fieldType === "object" ? item?.payload?.value?.amount : item?.fieldValue;
            if (!value || value === 0) return <></>;
            return (
              <Box key={index} display="flex" alignItems="center" justifyContent="space-between" mb={3}>
                <Typography
                  sx={{
                    typography: {
                      xs: "body2",
                      md: "body1",
                      overflowWrap: "anywhere",
                    },
                  }}
                >
                  {label}
                </Typography>
                <Typography
                  sx={{
                    typography: {
                      xs: "body2",
                      md: "body1",
                      overflowWrap: "anywhere",
                    },
                  }}
                >
                  <Box display="flex" justifyContent="flex-end" alignItems="center">
                    <CurrencyRupee fontSize="small" />
                    {(+value || 0).toFixed(2)}
                  </Box>
                </Typography>
              </Box>
            );
          })}
      </Box>
      <Divider sx={{ mb: 2 }} />
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          sx={{
            typography: { xs: "body1", md: "h6", overflowWrap: "anywhere" },
          }}
        >
          <b>Total Amount</b>
        </Typography>
        <Typography
          sx={{
            typography: { xs: "body1", md: "h6", overflowWrap: "anywhere" },
          }}
        >
          <b>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <CurrencyRupee fontSize="small" />
              {totalAmount}
            </Box>
          </b>
        </Typography>
      </Box>
    </Box>
  );
};

export default PaymentPageFeeSummary;
