import { validator } from "@jodo-tech/ui-kit-common/validation";
import { PaymentPageConfigTypes } from "../../../configs/types";

export const pageConfigValidationSchema = validator.object().shape({
  form_type: validator.string().trim().required("Form Type is required"),
  config: validator.object().shape({
    is_student_auto_create_allowed: validator.boolean().required("Please select if student auto create is allowed"),
    is_auto_assignment_enabled: validator.boolean().required("Please select if auto assignment is enabled"),
    student_identifier_type: validator
      .string()
      .when("is_student_auto_create_allowed", {
        is: true,
        then: validator
          .string()
          .trim()
          .oneOf(PaymentPageConfigTypes.ALL_IDENTIFIER_TYPES.map((field) => field?.value))
          .required("Please select student identifier type")
          .typeError("Please select student identifier type"),
      })
      .typeError("Please select student identifier type"),
    student_identifier_field: validator.string().when("student_identifier_type", {
      is: PaymentPageConfigTypes.IDENTIFIER_TYPES.USER_GENERATED.value,
      then: validator
        .string()
        .trim()
        .oneOf(PaymentPageConfigTypes.POSSIBLE_USER_IDENTIFIER_FIELDS.map((field) => field?.payload_key))
        .required("Please select student identifier field")
        .typeError("Please select student identifier field"),
      otherwise: validator.string().nullable(),
    }),
    student_identifier_template: validator.string().when("student_identifier_type", {
      is: PaymentPageConfigTypes.IDENTIFIER_TYPES.AUTO_GENERATED.value,
      then: validator
        .string()
        .trim()
        .required("Please select student identifier template")
        .typeError("Please select student identifier template"),
      otherwise: validator.string().nullable(),
    }),
  }),
});
