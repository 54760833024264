const SuccessInfographic = () => {
  return (
    <svg width="157" height="266" viewBox="0 0 157 266" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="157" height="266" rx="10" fill="url(#paint0_linear_3865_13074)" />
      <rect x="8.0918" y="8.10938" width="140.814" height="251.402" rx="10" fill="url(#paint1_linear_3865_13074)" />
      <rect x="19" y="19.0508" width="118.155" height="215.72" rx="10" fill="url(#paint2_linear_3865_13074)" />
      <path
        d="M78.0484 92.8906C59.1664 92.8906 43.8418 108.215 43.8418 127.097C43.8418 145.979 59.1664 161.304 78.0484 161.304C96.9305 161.304 112.255 145.979 112.255 127.097C112.255 108.215 96.9305 92.8906 78.0484 92.8906ZM78.0484 154.463C62.9633 154.463 50.6831 142.182 50.6831 127.097C50.6831 112.012 62.9633 99.732 78.0484 99.732C93.1335 99.732 105.414 112.012 105.414 127.097C105.414 142.182 93.1335 154.463 78.0484 154.463ZM91.3206 114.407L71.2071 134.52L64.7763 128.089C63.4422 126.755 61.2872 126.755 59.9531 128.089C58.6191 129.423 58.6191 131.578 59.9531 132.912L68.8126 141.772C70.1467 143.106 72.3017 143.106 73.6358 141.772L96.1779 119.23C97.512 117.896 97.512 115.741 96.1779 114.407C94.8439 113.073 92.6547 113.073 91.3206 114.407Z"
        fill="#02B170"
      />
      <rect x="32.3711" y="244.914" width="19.4227" height="6.86944" rx="3.43472" fill="#A7A7A7" />
      <rect x="69.5977" y="244.914" width="19.4227" height="6.86944" rx="3.43472" fill="#A7A7A7" />
      <rect x="106.825" y="244.914" width="19.4227" height="6.86944" rx="3.43472" fill="#A7A7A7" />
      <defs>
        <linearGradient
          id="paint0_linear_3865_13074"
          x1="78.5"
          y1="0"
          x2="78.5"
          y2="266"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EEEEEE" />
          <stop offset="1" stopColor="#F8F8F8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3865_13074"
          x1="78.499"
          y1="8.10937"
          x2="78.499"
          y2="259.512"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E6E6E6" />
          <stop offset="0.932874" stopColor="#E4E4E4" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3865_13074"
          x1="78.0773"
          y1="19.0508"
          x2="78.0773"
          y2="234.77"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EEEEEE" />
          <stop offset="1" stopColor="#F8F8F8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SuccessInfographic;
