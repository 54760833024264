import { validator } from "@jodo-tech/ui-kit-common/validation";
import moment from "moment";

export const phoneNumberRegex = /^[0-9]{10}$/;
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const metaConfigValidationSchema = validator.object().shape({
  name: validator
    .string()
    .trim()
    .test("len", "Page name must be less than 128 characters", (val) => !!val && val.length < 128)
    .required("Page Name is required"),
  description: validator.string().trim().optional().nullable(),
  send_fee_receipt: validator.string().trim().required("Send Fee Receipt is required"),
  expiry_date: validator
    .date()
    .typeError("Please put valid date")
    .min(moment().subtract(1, "day"), "Expiry date cannot be in past")
    .optional()
    .nullable(),
  email: validator.string().trim().optional().nullable().matches(emailRegex, {
    message: "Please put valid email address",
    excludeEmptyString: true,
  }),
  phone_number: validator.string().trim().optional().nullable().matches(phoneNumberRegex, {
    message: "Please put valid 10 digit mobile number",
    excludeEmptyString: true,
  }),
});

export function convertStringToHTMLString(s) {
  // detect is string contains any kind of html tag
  const alreadyContainsHTML = s.includes("<p>") || s.includes("<li>") || s.includes("<div>");

  if (!alreadyContainsHTML) {
    const renderString = s
      .split("\n")
      .map((line) => `<p>${line.trim()}</p>`)
      .join("");
    return `<div>${renderString}</div>`;
  } else return s;
}
