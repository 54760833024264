import { useContext, useEffect, useState } from "react";
import {
  PaymentPageScreens,
  ScreenComponents,
  appendQueryParams,
  cacheImages,
  getTotalAmount,
  isMobile,
  onlySelectedPaymentFields,
  paymentPageValidationSchema,
  tazapaySDKWidth,
} from "./PaymentPage.utils";
import {
  ApiError,
  CashfreeSDKError,
  EasebuzzSDKError,
  GeneralError,
  RazorpaySDKError,
  TazapaySDKError,
} from "@jodo-tech/ui-kit-common/utils/error";
import { validate } from "@jodo-tech/ui-kit-common/validation";
import { PaymentVendorTypes, PaymentVendors } from "@jodo-tech/ui-kit-common/utils/paymentVendors";
import { notify } from "@jodo-tech/ui-kit-common/design/Notify";
import { PaymentPageContext } from "../../../context/paymentPage.context";
import { PaymentPageViewTypes } from "../../../configs/types";
import { ApiDataModify } from "../../../helpers/api.util";
import { COMMON_ERROR_MESSAGE, DEFAULT_ERROR, PENDING_TXN_ERR_MSG } from "../../../configs/constant";
import { deepTrim, isValidAmount } from "../../../helpers";

const usePaymentPage = ({ paymentPageId, previewData }) => {
  const { mutation, query, callbackUrl, orderId, tracker, tazapaySdkEnv, cashfreeSdkEnv } =
    useContext(PaymentPageContext);
  const [loading, setLoading] = useState({
    mounting: false,
    userEvents: false,
  });
  const [paymentPageApiError, setPaymentPageApiError] = useState(null);

  const [isSubmittedOnce, setIsSubmittedOnce] = useState(false);
  const [paymentTransaction, setPaymentTransaction] = useState({});
  const [paymentStatus, setPaymentStatus] = useState("default");
  const [paymentOrderState, setPaymentOrderState] = useState({});
  const [shouldShowLogo, setShouldShowLogo] = useState(false);
  const [renderJSON, setRenderJSON] = useState({});
  const [showCashfreeModal, setShowCashfreeModal] = useState(false);
  const [showTazapayModal, setShowTazapayModal] = useState(false);
  const [isInternationalPaymentEnabled, setIsInternationalPaymentEnabled] = useState(true);
  const [showCountrySelectModal, setShowCountrySelectModal] = useState(false);

  const [screenName, setScreenName] = useState(
    isMobile ? PaymentPageScreens.PaymentPageMobilePage : PaymentPageScreens.PaymentPage,
  );
  const [systemFieldOptionsMap, setSystemFieldOptionsMap] = useState({});
  const [collectorId, setCollectorId] = useState(null);

  const [formAreaEl, setFormAreaEl] = useState({
    offsetTop: 0,
  });

  const isOrderMode = !!orderId;
  const isCallbackMode = !!callbackUrl;
  const isPreview = !!previewData;

  const { collector, sendFeeReceipt } = renderJSON;

  const validatePaymentPage = () => {
    const { error } = validate(renderJSON, paymentPageValidationSchema);
    setIsSubmittedOnce(true);
    if (error) {
      const firstErrorPath = Object.keys(error).find((err) => !err?.startsWith?.("__jodo"));
      const div = document.querySelector(`[data-error="${firstErrorPath}"]`);
      if (div) {
        div.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start",
        });
      }
      return false;
    }
    return true;
  };

  const ApiHandlers = {
    getPaymentPage: async (params) => {
      try {
        setLoading((prev) => ({ ...prev, mounting: true }));
        const res = await query?.getPaymentPage?.({
          paymentPageId,
          orderId,
          params,
        });
        const { data } = res;
        const hasInternationalPayments = data.products?.INTERNATIONAL_PAYMENT?.is_active;
        setIsInternationalPaymentEnabled(hasInternationalPayments);
        const imgSrc = data?.collector?.logo_url;
        let showLogo = false;
        try {
          if (!imgSrc) throw new Error("No logo");
          await cacheImages({ src: imgSrc });
          showLogo = true;
        } catch (error) {
          showLogo = false;
        }
        setShouldShowLogo(showLogo);
        setCollectorId(data?.collector?.id);
        const modifiedData = ApiDataModify.getPaymentPage({ result: data });
        const systemFieldPromises = [];
        const modifiedUserFormData = [...modifiedData?.userFormFields];
        (modifiedUserFormData || []).forEach((item) => {
          const fUIKey = item?.fieldUIKey || "";
          if (fUIKey.includes("grade")) {
            systemFieldPromises.push(OptionsHandler.gradeOption({ data, item }));
          }
          if (fUIKey.includes("stream")) {
            systemFieldPromises.push(OptionsHandler.streamOption({ data, item }));
          }
          if (fUIKey.includes("AY")) {
            systemFieldPromises.push(OptionsHandler.AyOption({ data, item }));
          }
          if (item?.fieldType === "object" && item?.fieldOptions?.type === "static") {
            if (item?.payload?.value?.internalId !== ("-1" || null || undefined)) {
              item.fieldValue = item?.payload?.value?.internalId;
            } else {
              item.fieldValue = "";
            }
          }
        });

        await Promise.all(systemFieldPromises);

        modifiedData.userFormFields = modifiedUserFormData;

        setRenderJSON(modifiedData);

        if (isOrderMode) {
          const { error } = validate(modifiedData, paymentPageValidationSchema);
          if (error) {
            throw new GeneralError(`get_payment_page_validation_failed`, error);
          }
          ClickHandlers.onPayNowClick(modifiedData, {
            isInternationalMode: false,
          });
        }
        setPaymentPageApiError(null);
        setLoading((prev) => ({ ...prev, mounting: false }));
      } catch (err) {
        const errType = err?.data?.type || "";
        let orderStatus = "error";
        if (errType === PaymentPageViewTypes.STATUS.SUCCESS) {
          orderStatus = "success";
        }
        if (isOrderMode) {
          let errMessageType = "get_payment_page_failed";
          if (err instanceof GeneralError && err.context === "get_payment_page_validation_failed") {
            errMessageType = "Invalid configuration, please contact Jodo support! ";
          }
          tracker?.captureErrMessage?.(errMessageType);
          if (isCallbackMode) {
            setPaymentPageApiError(PaymentPageViewTypes.STATUS.REDIRECT);
            const params = [
              ["status", orderStatus],
              ["message", errMessageType],
              ["order_id", orderId],
            ];
            const newUrl = appendQueryParams(callbackUrl, params);
            window.location.href = newUrl;
          } else {
            setPaymentPageApiError(errType || PaymentPageViewTypes.STATUS.FAILED);
          }
          return;
        }
        console.error(err);
        if (!(err instanceof ApiError)) {
          tracker?.captureException?.(err);
        }
        setPaymentPageApiError(errType || PaymentPageViewTypes.STATUS.API_ERROR);
        setLoading((prev) => ({ ...prev, mounting: false }));
      }
    },
    createOrder: async (payload) => {
      try {
        setLoading((prev) => ({ ...prev, userEvents: true }));
        const res = await mutation?.createOrder?.({ payload });
        const { data } = res;
        if (!data) {
          throw new GeneralError("paynow-createOrder-sdk_order-not-exists", {
            message: "Nothing to be paid.",
          });
        }
        setPaymentOrderState({
          feeOrderId: data?.id,
          paymentId: data?.sdk_order?.payment_id,
        });
        setLoading((prev) => ({ ...prev, userEvents: false }));
        const vendorName = data?.sdk_order?.vendor || "";
        const vendorNameUppercase = (vendorName || "").toUpperCase();
        if (vendorNameUppercase === PaymentVendorTypes.RAZORPAY.code) {
          const rzSdk = data?.sdk_order;
          await PaymentVendors.razorpay.process(rzSdk);
        } else if (vendorNameUppercase === PaymentVendorTypes.EASEBUZZ.code) {
          const access_key = data?.sdk_order?.easebuzz?.token;
          await PaymentVendors.easebuzz.process({ access_key });
        } else if (vendorNameUppercase === PaymentVendorTypes.TAZAPAY.code) {
          const token = data?.sdk_order?.tazapay?.token;
          if (!tazapaySdkEnv) {
            throw new GeneralError("paynow.tazapay.sdkEnv", {
              message: `Env is required for tazapay`,
            });
          }
          setShowTazapayModal(true);
          const sdkModalStyle = {
            container_maxWidth: `${tazapaySDKWidth}px`,
          };
          await PaymentVendors.tazapay.process({
            token,
            env: tazapaySdkEnv,
            styles: sdkModalStyle,
          });
          setShowTazapayModal(false);
        } else if (vendorNameUppercase === PaymentVendorTypes.CASHFREE.code) {
          if (!cashfreeSdkEnv) {
            throw new GeneralError("paynow.cashfree.sdkEnv", {
              message: `Env is required for cashfree`,
            });
          }
          const paymentSessionId = data?.sdk_order?.cashfree?.payment_session_id;
          setShowCashfreeModal(true);
          const cashfreeParentEl = document.getElementById("cashfree_modal_content_id");
          // resetting when user wants to pay again
          if (cashfreeParentEl) cashfreeParentEl.innerHTML = "";
          await PaymentVendors.cashfree.process({
            env: cashfreeSdkEnv,
            parentElement: cashfreeParentEl,
            paymentSessionId: paymentSessionId,
          });
          setShowCashfreeModal(false);
        } else {
          throw new GeneralError("paynow.getVendor.unregisterVendorError", {
            message: `Payment vendor not registered in ui, we got-${vendorName}`,
          });
        }
        return {
          feeOrderId: data?.id,
          paymentId: data?.sdk_order?.payment_id,
        };
      } catch (err) {
        setShowTazapayModal(false);
        setShowCashfreeModal(false);
        setLoading((prev) => ({ ...prev, userEvents: false }));
        if (err instanceof ApiError) {
          notify.error({
            message:
              "We are unable to process the transaction. Please refresh and try again! Sorry for the inconvenience caused.",
          });
          if (isOrderMode) {
            if (isCallbackMode) {
              setPaymentPageApiError(PaymentPageViewTypes.STATUS.REDIRECT);
              const params = [
                ["status", "error"],
                ["message", "create_order_failed"],
                ["order_id", orderId],
              ];
              const newUrl = appendQueryParams(callbackUrl, params);
              window.location.href = newUrl;
            } else {
              throw err;
            }
          }
        } else {
          throw err;
        }
      }
    },
    getPaymentStatus: async (params) => {
      try {
        setLoading((prev) => ({ ...prev, mounting: true }));
        const res = await query?.getPaymentStatus?.({ params });
        const { data } = res;
        setLoading((prev) => ({ ...prev, mounting: false }));
        return data;
      } catch (err) {
        notify.error({ message: err?.data?.message || DEFAULT_ERROR });
        console.error(err);
        if (!(err instanceof ApiError)) {
          tracker?.captureException?.(err);
        }
        if (isOrderMode && isCallbackMode) {
          setPaymentPageApiError(PaymentPageViewTypes.STATUS.REDIRECT);
          const params = [
            ["status", "error"],
            ["message", "payment_status_failed"],
            ["order_id", orderId],
          ];
          const newUrl = appendQueryParams(callbackUrl, params);
          window.location.href = newUrl;
        }

        setLoading((prev) => ({ ...prev, mounting: false }));
      }
    },
    getPaymentReceipt: async (params) => {
      try {
        setLoading((prev) => ({ ...prev, userEvents: true }));
        const res = await query?.getPaymentReceipt?.({ params });
        setLoading((prev) => ({ ...prev, userEvents: false }));
        return res;
      } catch (err) {
        notify.error({ message: err?.data?.message || DEFAULT_ERROR });
        if (isOrderMode && isCallbackMode) {
          setPaymentPageApiError(PaymentPageViewTypes.STATUS.REDIRECT);
          const params = [
            ["status", "error"],
            ["message", "payment_receipt_failed"],
            ["order_id", orderId],
          ];
          const newUrl = appendQueryParams(callbackUrl, params);
          window.location.href = newUrl;
        }
        console.error(err);
        setLoading((prev) => ({ ...prev, userEvents: false }));
      }
    },
    grade: async ({ collectorId, params }) => {
      try {
        setSystemFieldOptionsMap((prev) => ({
          ...prev,
          "system.userDetails.grade": [{ label: "...Loading", value: null, internalId: "-1" }],
        }));
        const res = await query?.getCollectorGrades?.({ collectorId, params });
        const result = res?.data;
        const grades = ApiDataModify.getCollectorGrades({ result });
        setSystemFieldOptionsMap((prev) => ({
          ...prev,
          "system.userDetails.grade": grades,
        }));
        return { apiData: grades, error: null };
      } catch (err) {
        notify.error({ message: err?.data?.message || DEFAULT_ERROR });
        setSystemFieldOptionsMap((prev) => ({
          ...prev,
          "system.userDetails.grade": [{ label: "No Options", value: null, internalId: "-1" }],
        }));
        console.error(err);
        if (!(err instanceof ApiError)) {
          tracker?.captureException?.(err);
        }
        return { apiData: null, error: err };
      }
    },
    stream: async ({ collectorId, params }) => {
      try {
        setLoading((prev) => ({ ...prev, userEvents: true }));
        const res = await query?.getCollectorStreams?.({ collectorId, params });
        const result = res?.data;
        const streams = ApiDataModify.getCollectorStreams({ result });
        setSystemFieldOptionsMap((prev) => ({
          ...prev,
          "system.userDetails.stream": streams,
        }));
        setLoading((prev) => ({ ...prev, userEvents: false }));
        return { apiData: streams, error: null };
      } catch (err) {
        notify.error({ message: err?.data?.message || DEFAULT_ERROR });
        setLoading((prev) => ({ ...prev, userEvents: false }));
        console.error(err);
        if (!(err instanceof ApiError)) {
          tracker?.captureException?.(err);
        }
        return { apiData: null, error: err };
      }
    },
    academicYear: async ({ collectorId, params }) => {
      try {
        setSystemFieldOptionsMap((prev) => ({
          ...prev,
          "system.userDetails.AY": [{ label: "...Loading", value: null, internalId: "-1" }],
        }));
        const res = await query?.getCollectorAY?.({ collectorId, params });
        const result = res?.data;
        const AY = ApiDataModify.getCollectorAY({ result });
        setSystemFieldOptionsMap((prev) => ({
          ...prev,
          "system.userDetails.AY": AY,
        }));
        return { apiData: AY, error: null };
      } catch (err) {
        notify.error({ message: err?.data?.message || DEFAULT_ERROR });
        setSystemFieldOptionsMap((prev) => ({
          ...prev,
          "system.userDetails.AY": [{ label: "No Options", value: null, internalId: "-1" }],
        }));
        console.error(err);
        if (!(err instanceof ApiError)) {
          tracker?.captureException?.(err);
        }
        return { apiData: null, error: err };
      }
    },
  };

  const ClickHandlers = {
    onRetryPaymentClick: () => {
      setPaymentStatus("default");
      if (isMobile) {
        setScreenName(PaymentPageScreens.PaymentPageMobilePage);
        return;
      }
      setScreenName(PaymentPageScreens.PaymentPage);
    },
    onPayNowClick: async (data, { isInternationalMode }, selectedCountry) => {
      try {
        if (isPreview) {
          notify.success({ message: "Payment Done" });
          return;
        }

        if (+getTotalAmount(data) <= 0) {
          notify.error({
            message: "Amount to be paid should be greater than 0",
          });
          tracker?.captureErrMessage?.("Amount to be paid should be greater than 0");
          return;
        }

        if (!validatePaymentPage()) return;

        const payload = ApiDataModify.createOrderPayload({
          result: onlySelectedPaymentFields(data),
        });
        // we are trimming payload of any possible white space
        deepTrim(payload);
        const internationalPayload = isInternationalMode
          ? {
              country: {
                code: selectedCountry?.value,
              },
              product: "INTERNATIONAL_PAYMENT",
            }
          : {};
        const creteOrderResp = await ApiHandlers.createOrder({
          ...payload,
          ...internationalPayload,
        });
        const { feeOrderId, paymentId } = creteOrderResp || {};
        if (!feeOrderId && !paymentId) {
          return;
        }
        const paymentStatus = await ApiHandlers.getPaymentStatus({
          fee_order_id: feeOrderId,
          send_receipt: sendFeeReceipt,
          payment_id: paymentId,
          page_id: paymentPageId,
          ...(isInternationalMode ? { product: "INTERNATIONAL_PAYMENT" } : {}),
        });
        setPaymentTransaction(paymentStatus);
        setPaymentStatus("success");
        let successScreenNames = [];
        if (isMobile) {
          let mobileSuccessScreen = [...PaymentPageScreens.PaymentPageSuccessMobile];
          if (!sendFeeReceipt) {
            mobileSuccessScreen = mobileSuccessScreen.filter(
              (item) => item !== ScreenComponents.PAYMENT_PAGE_MOBILE_NAVIGATION_HOLDER,
            );
          }
          successScreenNames = mobileSuccessScreen;
        } else {
          successScreenNames = PaymentPageScreens.PaymentPageSuccess;
        }
        if (isOrderMode) {
          if (isCallbackMode) {
            setPaymentPageApiError(PaymentPageViewTypes.STATUS.REDIRECT);
            const params = [
              ["status", "success"],
              ["order_id", orderId],
            ];
            const newUrl = appendQueryParams(callbackUrl, params);
            window.location.href = newUrl;
          } else {
            setPaymentPageApiError(PaymentPageViewTypes.STATUS.SUCCESS);
          }
        } else {
          successScreenNames.length > 0 && setScreenName(successScreenNames);
        }
      } catch (error) {
        if (paymentOrderState?.feeOrderId && paymentOrderState?.paymentId) {
          // THIS IS REQUIRED FOR BACKEND TO PROPAGATE PAYMENT STATUS
          await ApiHandlers.getPaymentStatus({
            fee_order_id: paymentOrderState?.feeOrderId,
            send_receipt: sendFeeReceipt,
            payment_id: paymentOrderState?.paymentId,
            page_id: paymentPageId,
            ...(isInternationalMode ? { product: "INTERNATIONAL_PAYMENT" } : {}),
          });
        }
        let errScreenNames = [];
        let errPaymentStatus = "";
        let errMessage = "";

        if (error instanceof TazapaySDKError) {
          errMessage = "Unable to open tazapay sdk, cannot process payment";
          errPaymentStatus = "error";
        } else if (error instanceof CashfreeSDKError) {
          if (error.context === "usePayment.cashfree.onSuccess") {
            errMessage = error?.data?.transaction?.txMsg;
          } else if (error.context === "usePayment.cashfree.onFailure") {
            errMessage = error?.data?.order?.errorText || error?.data?.transaction?.txMsg;
          }
          errMessage = errMessage || COMMON_ERROR_MESSAGE;
          errPaymentStatus = "error";
        } else if (error instanceof EasebuzzSDKError) {
          if (error?.data?.status === "userCancelled") {
            return; // do nothing, manage silently
          } else if (error?.data?.status === "pending") {
            errMessage = PENDING_TXN_ERR_MSG;
            errPaymentStatus = "pending";
          } else {
            errPaymentStatus = "error";
            const uppercaseErrorMsg = (error?.data?.error || "").trim().toUpperCase();
            errMessage = uppercaseErrorMsg === "NA" ? COMMON_ERROR_MESSAGE : error?.data?.error; // don't show NA
          }
        } else if (error instanceof RazorpaySDKError) {
          if (error?.data?.description === "modalDismissed") {
            return; // do nothing, manage silently
          }
          errMessage = error?.data?.description;
          errPaymentStatus = "error";
        } else if (error instanceof ApiError) {
          errMessage = error?.data?.message;
          errPaymentStatus = "error";
        } else {
          errMessage = error?.message || COMMON_ERROR_MESSAGE;
          errPaymentStatus = "error";
          tracker?.captureException?.(errMessage);
        }
        if (errPaymentStatus === "pending") {
          errScreenNames = isMobile
            ? PaymentPageScreens.PaymentPagePendingMobile
            : PaymentPageScreens.PaymentPagePending;
        } else if (errPaymentStatus === "error") {
          errScreenNames = isMobile
            ? PaymentPageScreens.PaymentPageFailureMobile
            : PaymentPageScreens.PaymentPageFailure;
        }
        if (isOrderMode) {
          if (isCallbackMode) {
            setPaymentPageApiError(PaymentPageViewTypes.STATUS.REDIRECT);
            setPaymentPageApiError(PaymentPageViewTypes.STATUS.REDIRECT);
            const params = [
              ["status", "error"],
              ["message", errMessage],
              ["order_id", orderId],
            ];
            const newUrl = appendQueryParams(callbackUrl, params);
            window.location.href = newUrl;
          } else {
            setPaymentPageApiError(PaymentPageViewTypes.STATUS.FAILED);
          }
        } else {
          if (errPaymentStatus) setPaymentStatus(errPaymentStatus);
          if (errScreenNames.length > 0) setScreenName(errScreenNames);
        }
        notify.error({ message: errMessage });
        console.error(errMessage);
      }
    },
    onUserFieldChange: (fieldInternalId, value) => {
      const { userFormFields } = renderJSON;
      let fieldChanged = {};
      let newUserFields = (userFormFields || [])?.map((item) => {
        if (item.fieldInternalId === fieldInternalId) {
          if (item?.fieldType === "object") {
            if (
              item?.fieldUIKey === "system.userDetails.stream" &&
              systemFieldOptionsMap?.["system.userDetails.grade"]
            ) {
              ApiHandlers.grade({
                collectorId,
                params: { stream_id: value?.id },
              });
              fieldChanged = { ...fieldChanged, stream: true };
            }
            return {
              ...item,
              fieldValue: value?.internalId,
              payload: { ...item?.payload, value: value || null },
            };
          } else if (item?.fieldType === "date") {
            return {
              ...item,
              fieldValue: value,
              payload: { ...item?.payload, value: value || null },
            };
          } else {
            return {
              ...item,
              fieldValue: value,
              payload: { ...item?.payload, value: value || null },
            };
          }
        }
        return item;
      });

      if (fieldChanged?.stream) {
        newUserFields = newUserFields?.map((item) => {
          if (item?.fieldUIKey === "system.userDetails.grade") {
            return {
              ...item,
              payload: { ...item.payload, value: null },
              fieldValue: null,
            };
          }

          return item;
        });
      }

      setRenderJSON((currentData) => ({
        ...currentData,
        userFormFields: newUserFields,
      }));
    },
    onPaymentFieldChange: (fieldInternalId, value) => {
      const { userPaymentFields } = renderJSON;
      const newPaymentFields = (userPaymentFields || [])?.map((item) => {
        if (item.fieldInternalId === fieldInternalId) {
          if (item?.fieldType === "object") {
            return {
              ...item,
              fieldValue: value?.internalId,
              payload: { ...item?.payload, value: value },
              fieldChecked: true,
            };
          } else {
            if (isValidAmount(value))
              return {
                ...item,
                fieldValue: value,
                payload: {
                  ...item?.payload,
                  value: (value || "").trim(),
                  fieldChecked: true,
                },
              };
            else return item;
          }
        }
        return item;
      });
      setRenderJSON((currentData) => ({
        ...currentData,
        userPaymentFields: newPaymentFields,
      }));
    },
    onPaymentFieldSelectionChange: (fieldInternalId, checked) => {
      const { userPaymentFields } = renderJSON;
      const newPaymentFields = (userPaymentFields || [])?.map((item) => {
        if (item.fieldInternalId === fieldInternalId) {
          return { ...item, fieldChecked: checked };
        }
        return item;
      });

      setRenderJSON((currentData) => ({
        ...currentData,
        userPaymentFields: newPaymentFields,
      }));
    },
    onPaymentFieldSelectAllChange: (checked) => {
      const { userPaymentFields } = renderJSON;
      const newPaymentFields = (userPaymentFields || [])?.map((item) => {
        if (item.fieldRequired) return item;
        return { ...item, fieldChecked: checked };
      });
      setRenderJSON((currentData) => ({
        ...currentData,
        userPaymentFields: newPaymentFields,
      }));
    },
    onDownloadReceiptClick: async () => {
      try {
        const binaryFile = await ApiHandlers.getPaymentReceipt({
          fee_order_id: paymentOrderState?.feeOrderId,
          send_receipt: sendFeeReceipt,
          payment_id: paymentOrderState?.paymentId,
          collector_id: collector?.id,
        });
        if (binaryFile) {
          const url = window.URL.createObjectURL(new Blob([binaryFile]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "receipt.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
        // eslint-disable-next-line no-empty
      } catch (err) {}
    },
    onCloseSelectCountryModal: () => {
      setShowCountrySelectModal(false);
    },
    onOpenSelectCountryModal: () => {
      if (isPreview) {
        notify.success({ message: "Payment Done" });
        return;
      }
      if (!validatePaymentPage()) return;
      setShowCountrySelectModal(true);
    },
    onSubmitSelectCountryModal: ({ selectedCountry }) => {
      ClickHandlers.onPayNowClick(renderJSON, { isInternationalMode: true }, selectedCountry);
      setShowCountrySelectModal(false);
    },
    onCloseTazaPayModal: () => {
      ClickHandlers.onCloseSelectCountryModal();
      setShowTazapayModal(false);
    },
    onCloseCashfreeModal: () => {
      setShowCashfreeModal(false);
    },
  };

  const OptionsHandler = {
    gradeOption: async ({ data, item }) => {
      try {
        const { apiData: grades, error } = await ApiHandlers.grade({
          collectorId: data?.collector?.id,
          params: {},
        });
        if (error) {
          throw new Error(error);
        }
        if (grades) {
          const gradeOption = grades.find((grade) => grade?.id === item?.payload?.value?.id);
          item.payload.value = gradeOption;
          item.fieldValue = gradeOption?.internalId || "";
          return { apiData: item, error: null };
        }
      } catch (error) {
        return { apiData: null, error: error };
      }
    },
    streamOption: async ({ data, item }) => {
      try {
        const { apiData: streams, error } = await ApiHandlers.stream({
          collectorId: data?.collector?.id,
          params: {},
        });
        if (error) {
          throw new Error(error);
        }
        if (streams) {
          const streamOption = streams.find((stream) => stream?.name === item?.payload?.value?.name);
          item.payload.value = streamOption;
          item.fieldValue = streamOption?.internalId || "";
          return { apiData: item, error: null };
        }
      } catch (error) {
        return { apiData: null, error: error };
      }
    },
    AyOption: async ({ data, item }) => {
      try {
        const { apiData: AY, error } = await ApiHandlers.academicYear({
          collectorId: data?.collector?.id,
          params: {},
        });
        if (error) {
          throw new Error(error);
        }
        if (AY) {
          const AyOption = AY.find(
            (ay) =>
              ay?.academic_year_start === item?.payload?.value?.academic_year_start &&
              ay?.academic_year_end === item?.payload?.value?.academic_year_end,
          );
          item.payload.value = AyOption;
          item.fieldValue = AyOption?.internalId || "";
          return { apiData: item, error: null };
        }
      } catch (error) {
        return { apiData: null, error: error };
      }
    },
  };

  useEffect(() => {
    if (!isOrderMode) {
      window.onbeforeunload = () => true;
    }

    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    if (isPreview) {
      const modifiedData = ApiDataModify.getPaymentPage({
        result: previewData,
      });
      setRenderJSON(modifiedData);
      const imgSrc = modifiedData?.collector?.collectorLogoUrl;
      if (!imgSrc) setShouldShowLogo(false);
      cacheImages({ src: imgSrc })
        .then(() => {
          setShouldShowLogo(true);
        })
        .catch(() => {
          setShouldShowLogo(false);
        });
      return;
    }
    ApiHandlers.getPaymentPage({});
  }, []);

  useEffect(() => {
    const _formAreaEl = document.querySelector(`[data-name="empty-form-holder"]`);
    if (!_formAreaEl) return;
    if (_formAreaEl?.offsetTop !== formAreaEl?.offsetTop) {
      // this is the hacky way to tap onto DOM attributes, every time
      // use Ref get called only once, and don't change,
      // we need to calculate this after few render cycle once image is pixel painted to dom
      setFormAreaEl({ offsetTop: _formAreaEl?.offsetTop });
    }
  });

  return {
    renderJSON,
    isOrderMode,
    isPreview,
    shouldShowLogo,
    formAreaEl,
    paymentOrderState,
    ApiHandlers,
    OptionsHandler,
    ClickHandlers,
    screenName,
    paymentStatus,
    paymentTransaction,
    isSubmittedOnce,
    paymentPageApiError,
    systemFieldOptionsMap,
    loading,
    showCashfreeModal,
    showTazapayModal,
    isInternationalPaymentEnabled,
    showCountrySelectModal,
  };
};

export default usePaymentPage;
