import { BrowserRouter, Route, Routes } from "react-router-dom";

import MakePaymentPage from "../organism/MakePayment/MakePayment.page";
import NotFound from "../organism/NotFound/NotFound.page";

export default function Lazy() {
  return (
    <BrowserRouter>
      <Routes>
        {/* use outlet concept if you want to render nested routes under a component */}
        <Route path="/pages/:payment_id" element={<MakePaymentPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
