const ErrorInfographic = () => {
  return (
    <svg width="157" height="266" viewBox="0 0 157 266" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="157" height="266" rx="10" fill="url(#paint0_linear_3865_13020)" />
      <rect x="8.0918" y="8.10938" width="140.814" height="251.402" rx="10" fill="url(#paint1_linear_3865_13020)" />
      <rect x="19" y="19.0508" width="118.155" height="215.72" rx="10" fill="url(#paint2_linear_3865_13020)" />
      <path
        d="M78.0484 109.994C79.9298 109.994 81.4691 111.533 81.4691 113.415V127.097C81.4691 128.979 79.9298 130.518 78.0484 130.518C76.1671 130.518 74.6278 128.979 74.6278 127.097V113.415C74.6278 111.533 76.1671 109.994 78.0484 109.994ZM78.0142 92.8906C59.1322 92.8906 43.8418 108.215 43.8418 127.097C43.8418 145.979 59.1322 161.304 78.0142 161.304C96.9305 161.304 112.255 145.979 112.255 127.097C112.255 108.215 96.9305 92.8906 78.0142 92.8906ZM78.0484 154.463C62.9291 154.463 50.6831 142.217 50.6831 127.097C50.6831 111.978 62.9291 99.732 78.0484 99.732C93.1678 99.732 105.414 111.978 105.414 127.097C105.414 142.217 93.1678 154.463 78.0484 154.463ZM81.4691 144.201H74.6278V137.359H81.4691V144.201Z"
        fill="#E23434"
      />
      <rect x="32.3711" y="244.914" width="19.4227" height="6.86944" rx="3.43472" fill="#A7A7A7" />
      <rect x="69.5977" y="244.914" width="19.4227" height="6.86944" rx="3.43472" fill="#A7A7A7" />
      <rect x="106.825" y="244.914" width="19.4227" height="6.86944" rx="3.43472" fill="#A7A7A7" />
      <defs>
        <linearGradient
          id="paint0_linear_3865_13020"
          x1="78.5"
          y1="0"
          x2="78.5"
          y2="266"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EEEEEE" />
          <stop offset="1" stopColor="#F8F8F8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3865_13020"
          x1="78.499"
          y1="8.10937"
          x2="78.499"
          y2="259.512"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E6E6E6" />
          <stop offset="0.932874" stopColor="#E4E4E4" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3865_13020"
          x1="78.0773"
          y1="19.0508"
          x2="78.0773"
          y2="234.77"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EEEEEE" />
          <stop offset="1" stopColor="#F8F8F8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ErrorInfographic;
