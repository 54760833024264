import Typography from "@jodo-tech/ui-kit-common/design/Typography";
import React, { Component, CSSProperties } from "react";

import { Sentry } from "../vendors/sentry";

interface IProp {
  children: React.ReactNode;
}
interface IState {
  hasError: boolean;
  error: any;
}

const Styles: { [key: string]: CSSProperties } = {
  page: {
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    maxWidth: "400px",
  },
  title: {},
  description: {},
};

class ErrorBoundary extends Component<IProp, IState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: any) {
    console.error(error);
    if (error.name !== "ChunkLoadError") {
      Sentry.captureException(error);
    }
  }

  render() {
    const { error, hasError } = this.state;

    const errorDetails = {
      title: "An Error Occurred",
      description: "An error has occurred, please refresh and try again.",
    };

    if (error?.name === "ChunkLoadError") {
      errorDetails.title = "Application Updated";
      errorDetails.description =
        "This application has been updated, please refresh your browser to see the latest content.";
    }

    if (hasError) {
      return (
        <div style={Styles.page}>
          <div style={Styles.content}>
            <div style={Styles.title}>
              <Typography variant="h5">{errorDetails.title}</Typography>
            </div>
            <div style={Styles.description}>
              <Typography variant="subtitle1">{errorDetails.description}</Typography>
            </div>
          </div>
        </div>
      );
    }
    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
