import "./index.css";
import "@jodo-tech/ui-kit-common/index.css";

import { ThemeProvider } from "@jodo-tech/ui-kit-common/design/theme";
import React from "react";
import ReactDOM from "react-dom";

import App from "./pages/App";
import { Sentry } from "./vendors/sentry";

Sentry.init();
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={{}}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root") as HTMLElement,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
