/* eslint-disable no-unused-vars */
import React from "react";

export const PaymentPageContext = React.createContext({
  tracker: {
    captureException: (err) => {
      return undefined;
    },
    captureErrMessage: (message) => {
      return undefined;
    },
    analyticsTracker: (error) => {
      return undefined;
    },
  },
  query: {
    getPaymentPage: ({ paymentPageId, orderId, params }) =>
      new Promise((resolve) => {
        return undefined;
      }),
    getPaymentStatus: ({ params }) =>
      new Promise((resolve) => {
        return undefined;
      }),
    getPaymentReceipt: ({ params }) =>
      new Promise((resolve) => {
        return undefined;
      }),
    getCollectorGrades: ({ collectorId, params }) =>
      new Promise((resolve) => {
        return undefined;
      }),
    getCollectorStreams: ({ collectorId, params }) =>
      new Promise((resolve) => {
        return undefined;
      }),
    getCollectorAY: ({ collectorId, params }) =>
      new Promise((resolve) => {
        return undefined;
      }),
  },
  mutation: {
    createOrder: ({ payload }) =>
      new Promise((resolve) => {
        return undefined;
      }),
  },
  containerHeight: "100vh",
  tazapaySdkEnv: undefined,
  cashfreeSdkEnv: undefined,
});
