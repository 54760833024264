import Box from "@jodo-tech/ui-kit-common/design/Box";
import Button from "@jodo-tech/ui-kit-common/design/Button";
import Grid from "@jodo-tech/ui-kit-common/design/Grid";
import Alert from "@jodo-tech/ui-kit-common/design/Alert";
import Typography from "@jodo-tech/ui-kit-common/design/Typography";
import CircularProgress from "@jodo-tech/ui-kit-common/design/CircularProgress";
import IconButton from "@jodo-tech/ui-kit-common/design/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import Add from "@mui/icons-material/Add";
import { useContext, useState, useEffect } from "react";
import SelectItems from "../../../design/SelectItems";
import UserRowFields from "./UserConfig.RowFields";
import FieldOptionalSwitcher from "../../../design/FieldOptionalSwitcher/FieldOptionalSwitcher.molecule";
import { PaymentPageConfigTypes } from "../../../configs/types";
import { notify } from "@jodo-tech/ui-kit-common/design/Notify";
import { nanoid } from "@jodo-tech/ui-kit-common/utils/nanoId";
import { PaymentPageConfigContext } from "../../../context/paymentPageConfig.context";
import { CUSTOM_FIELD_LABEL, DEFAULT_ERROR, LOOKUP_FIELD_TOPIC } from "../../../configs/constant";
import { ApiDataModify } from "../../../helpers/api.util";
import { getInfoText } from "./UserConfig.utils";
import { ApiError } from "@jodo-tech/ui-kit-common/utils/error";
import { payPageSx } from "../Common/Common.utils";
import { EventEmitter } from "@jodo-tech/ui-kit-common/utils/emitter";

const currentPage = PaymentPageConfigTypes.PAGES.USER;

const Title = ({ values, items, handlerFunctions, totalItems }) => {
  return (
    <Grid
      container
      sx={{
        px: 3,
        pt: 2,
        pb: 1,
        position: "sticky",
        top: 0,
        background: "white",
        zIndex: 2,
        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.05), 0px 1px 10px rgba(0, 0, 0, 0.1);",
      }}
    >
      <Grid container xs={6} justifyContent="flex-start">
        <Typography variant="subtitle1" sx={{ ...payPageSx.blackText, my: "auto" }}>
          <b>User Details</b>
        </Typography>
      </Grid>
      <Grid container justifyContent="flex-end" xs={6} textAlign="end">
        <>
          <Grid item xs="auto" mr={4}>
            <Button variant="text" startIcon={<Add color="primary" />} onClick={handlerFunctions.onAddCustom}>
              Add custom field
            </Button>
          </Grid>
          <Grid item xs="auto">
            <SelectItems
              buttonTitle="Add system field"
              labelKey="group_label"
              valueKey="group_id"
              disableKey="disable_in_list"
              values={values}
              items={items}
              onChange={(fields) => handlerFunctions.onAddSystemField({ items: fields })}
              totalItems={totalItems}
            />
          </Grid>
        </>
      </Grid>
    </Grid>
  );
};

const UserForm = ({ data, onChange, errors, entireUiData }) => {
  const { query, tracker } = useContext(PaymentPageConfigContext);
  const [collectorFields, setCollectorFields] = useState([]);
  const [fieldItems, setFieldItems] = useState([]);
  const [dragIndex, setDragIndex] = useState(-1);
  const [dragOverIndex, setDragOverIndex] = useState(-1);
  const [loading, setLoading] = useState(true);

  const pageSettingsData = entireUiData[PaymentPageConfigTypes.PAGES.PAGE_SETTINGS] || {};
  const studentIdentifierField = pageSettingsData?.config?.student_identifier_field || null;
  const isStudentCreateEnabled = pageSettingsData?.config?.is_student_auto_create_allowed || false;

  const lookupFields = (EventEmitter.getData(LOOKUP_FIELD_TOPIC) || {})?.lookupFields || [];

  const apiHandlerFunctions = {
    fetchCollectorFields: async () => {
      try {
        setLoading(true);
        if (!query?.getCollectorFields) return console.error("query?.getCollectorFields is not defined");
        const result = await query?.getCollectorFields?.();
        const fields = ApiDataModify.getCollectorFields({
          result,
          isStudentCreateEnabled,
          studentIdentifierField,
        });

        // here we are filtering out mandatory fields because we don't want to show them in the list
        let mandatoryFields =
          fields?.filter((item) => item?.variant === PaymentPageConfigTypes.FIELD_VARIANTS.MANDATORY) || [];

        let identifierField = fields?.filter((item) => item?.is_identifier) || [];

        setCollectorFields(fields);

        // here we are checking if we have some previously saved data in DB
        if (data.length) {
          // here we are adding labels from DB to the fields
          // for fields that are not in DB we are adding label "Custom field"
          // this is done because we want to show labels for all fields in the list
          mandatoryFields = mandatoryFields?.map((item) => {
            let itemToUpdate = { ...item };
            const mField = data?.find((field) => field?.group_id === item?.group_id);
            if (mField) {
              const updatedChildren = item?.children?.map((child, index) => {
                return {
                  ...child,
                  label: mField?.children?.[index]?.label,
                };
              });
              itemToUpdate = { ...item, children: updatedChildren };
            }
            return itemToUpdate;
          });

          identifierField = identifierField?.map((item) => {
            let itemToUpdate = { ...item };
            const idField = data.find((field) => field?.group_id === item?.group_id);
            if (idField) {
              const updatedChildren = item?.children?.map((child, index) => {
                return { ...child, label: idField?.children?.[index]?.label };
              });
              itemToUpdate = {
                ...item,
                children: updatedChildren,
                is_user_action: false,
              };
            }
            return itemToUpdate;
          });

          const dataWithSystemLabels = data
            .filter((item) => item?.is_user_action)
            .map((item) => {
              let itemToUpdate = { ...item };
              const systemField = fields?.find((field) => field?.group_id === item?.group_id);
              if (systemField) {
                const updatedChildren = item?.children?.map((child, index) => {
                  return {
                    ...child,
                    payload_label: systemField?.children?.[index]?.payload_label,
                  };
                });
                if (systemField?.group_id === identifierField?.[0]?.group_id) {
                  return (itemToUpdate = {
                    ...item,
                    ...identifierField?.[0],
                    children: updatedChildren,
                  });
                }
                itemToUpdate = { ...item, children: updatedChildren };
              } else {
                const updatedChildren = item?.children?.map((child) => {
                  return { ...child, payload_label: CUSTOM_FIELD_LABEL };
                });
                itemToUpdate = { ...item, children: updatedChildren };
              }
              return itemToUpdate;
            });

          let finalState = [...mandatoryFields, ...dataWithSystemLabels];
          if (
            !dataWithSystemLabels.find((item) => item?.group_id === identifierField[0]?.group_id) &&
            !mandatoryFields.find((item) => item?.group_id === identifierField[0]?.group_id)
          ) {
            finalState = [...mandatoryFields, ...identifierField, ...dataWithSystemLabels];
          }
          setFieldItems(finalState);
          onChange({ currentPage, data: finalState });
        } else {
          // here we are adding mandatory fields to the list
          // this is because we always want to show mandatory fields in the list
          let finalState = [...mandatoryFields];
          if (!mandatoryFields.find((item) => item?.group_id === identifierField[0]?.group_id)) {
            finalState = [...mandatoryFields, ...identifierField];
          }
          setFieldItems(finalState);
          onChange({ currentPage, data: finalState });
        }
      } catch (err) {
        notify.error({ message: err?.data?.message || DEFAULT_ERROR });
        console.error(err);
        if (!(err instanceof ApiError)) {
          tracker?.captureException?.(err);
        }
      } finally {
        setLoading(false);
      }
    },
  };

  const handlerFunctions = {
    onAddCustom: () => {
      const updatedItems = [
        ...fieldItems,
        {
          group_id: nanoid(),
          group_label: CUSTOM_FIELD_LABEL,
          variant: PaymentPageConfigTypes.FIELD_VARIANTS.CUSTOM,
          optional: false,
          is_user_action: true,
          item_priority: fieldItems?.length + 1,
          children: [
            {
              payload_key: null,
              payload_label: CUSTOM_FIELD_LABEL,
              field_type: PaymentPageConfigTypes.USER_FIELD_TYPES.ALPHANUMERIC,
              is_identifier: false,
              internalId: nanoid(),
              label: "",
              variant: PaymentPageConfigTypes.FIELD_VARIANTS.CUSTOM,
              optional: false,
            },
          ],
        },
      ];
      setFieldItems(updatedItems);
      onChange({ currentPage, data: [...updatedItems] });
    },
    onRemoveGroup: ({ groupId }) => {
      const updatedItems = fieldItems?.filter((item) => item?.group_id !== groupId);
      setFieldItems(updatedItems);
      onChange({ currentPage, data: [...updatedItems] });
    },
    onChangeOptional: ({ groupId, optional }) => {
      const updatedItems = fieldItems?.map((item) => {
        if (item?.group_id === groupId) {
          const updatedChildren = item?.children.map((child) => {
            return { ...child, optional };
          });
          return { ...item, optional, children: updatedChildren };
        }
        return item;
      });
      setFieldItems(updatedItems);
      onChange({ currentPage, data: [...updatedItems] });
    },
    onRemoveField: ({ internalId }) => {
      const updatedItems = fieldItems?.filter((item) => item.internalId !== internalId);
      const updatedItemsWithPriorityChanged = updatedItems.map((user, index) => {
        return { ...user, item_priority: index };
      });
      setFieldItems(updatedItemsWithPriorityChanged);
      onChange({ currentPage, data: updatedItemsWithPriorityChanged });
    },
    onAddSystemField: ({ items }) => {
      const otherThanSystemFields = fieldItems?.filter(
        (item) => item?.variant !== PaymentPageConfigTypes.FIELD_VARIANTS.SYSTEM || item?.is_identifier,
      );
      const filterIdentifier = items.filter((item) => !item.is_identifier);
      const updatedItems = [...otherThanSystemFields, ...filterIdentifier];
      const updatedItemsWithPrioritySorted = updatedItems.sort((a, b) => {
        return a?.item_priority - b?.item_priority;
      });
      const updatedItemsWithPriorityChanged = updatedItemsWithPrioritySorted.map((user, index) => {
        return { ...user, item_priority: index };
      });

      setFieldItems(updatedItemsWithPriorityChanged);
      onChange({ currentPage, data: [...updatedItemsWithPriorityChanged] });
    },
    onEditField: ({ internalId, formData }) => {
      const updatedItems = fieldItems?.map((item) => {
        const updatedChildren = item?.children?.map((child) => {
          if (child.internalId === internalId) {
            return { ...child, ...formData };
          }
          return child;
        });
        return { ...item, children: updatedChildren };
      });
      setFieldItems(updatedItems);
      onChange({ currentPage, data: [...updatedItems] });
    },
  };

  const dragFunctions = {
    handleDragStart: (e, index) => {
      setDragIndex(index);
      e.target.style.opacity = 0.4;
    },

    handleDrop: (e, index) => {
      e?.stopPropagation?.(); // stops the browser from redirecting.
      const newList = [...fieldItems];
      // change the position of the dragged item's to the dropped item
      if (dragIndex === index) return;
      if (dragIndex > index) {
        newList.splice(index, 0, newList[dragIndex]);
        newList.splice(dragIndex + 1, 1);
      } else {
        newList.splice(index + 1, 0, newList[dragIndex]);
        newList.splice(dragIndex, 1);
      }
      newList.forEach((item, index) => {
        item.item_priority = index;
      });
      setFieldItems([...newList]);
      onChange({ currentPage, data: [...newList] });
    },
    handleDragEnd: (e) => {
      e?.stopPropagation?.();
      setDragIndex(-1);
      setDragOverIndex(-1);
      e.target.style.opacity = 1;
    },
    handleDragOver: (e, index) => {
      e?.preventDefault?.();
      if (dragOverIndex !== index) {
        setDragOverIndex(index);
      }
    },
    getStyle: (index) => {
      if (dragOverIndex !== -1 && dragIndex !== -1) {
        if (index === dragOverIndex && index > dragIndex) {
          // highlighting the hovered row
          return {
            cursor: "move",
            "&:after": {
              content: '""',
              display: "flex",
              width: "100%",
              height: "60px",
              border: "2px solid #ddd",
            },
          };
        }
        if (index === dragOverIndex && index < dragIndex) {
          // highlighting the hovered row
          return {
            cursor: "move",
            "&:before": {
              content: '""',
              display: "flex",
              width: "100%",
              height: "60px",
              border: "2px solid #ddd",
            },
          };
        }
        // de-highlighting the un-hovered row
        return { cursor: "move", mb: 1 };
      }
      return { mb: 1 };
    },
  };

  useEffect(() => {
    apiHandlerFunctions.fetchCollectorFields();
  }, [studentIdentifierField]);

  if (loading) {
    return (
      <Box display="grid" sx={{ placeItems: "center", height: "100vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  const infoText = getInfoText({
    isStudentCreateEnabled,
    lookupFields,
    studentIdentifierField,
  });

  return (
    <>
      <Title
        // values should be only system fields
        values={fieldItems.filter((item) => item?.variant === PaymentPageConfigTypes.FIELD_VARIANTS.SYSTEM)}
        // items should be only system fields
        items={collectorFields.filter((item) => item?.variant === PaymentPageConfigTypes.FIELD_VARIANTS.SYSTEM)}
        handlerFunctions={handlerFunctions}
        totalItems={fieldItems?.length}
      />

      <Box
        p={3}
        sx={{
          overflowY: "scroll",
        }}
      >
        {infoText && (
          <Alert
            variant="filled"
            severity="info"
            sx={{
              mb: 2,
              background: "#EAF0FB",
              color: "primary.main",
              alignItems: "center",
            }}
          >
            <Typography variant="body2" sx={payPageSx.blackText}>
              {`User fields of ${infoText} will always be mandatory and will be auto added for the payer to fill in`}
            </Typography>
          </Alert>
        )}

        {fieldItems
          ?.sort((a, b) => {
            return a?.item_priority - b?.item_priority;
          })
          ?.map((group, gIndex) => {
            const enableDragging = group?.variant !== PaymentPageConfigTypes.FIELD_VARIANTS.MANDATORY;
            return (
              <Box
                key={group?.group_id}
                sx={dragFunctions.getStyle(gIndex)}
                draggable={enableDragging}
                onDragEnd={(e) => enableDragging && dragFunctions.handleDragEnd(e)}
                onDragStart={(e) => {
                  enableDragging && dragFunctions.handleDragStart(e, gIndex);
                }}
                onDrop={(e) => enableDragging && dragFunctions.handleDrop(e, gIndex)}
                onDragOver={(e) => {
                  enableDragging && dragFunctions.handleDragOver(e, gIndex);
                }}
              >
                <Grid
                  container
                  item
                  direction="row"
                  alignItems="flex-start"
                  sx={{
                    background: "#FCFCFC",
                    padding: 1,
                    borderRadius: 1,
                    my: 1,
                  }}
                  flexWrap="nowrap"
                  xs={12}
                >
                  <Grid item xs={"auto"} mr="auto">
                    <IconButton
                      size="small"
                      sx={{
                        cursor: "move",
                        color:
                          group?.variant === PaymentPageConfigTypes.FIELD_VARIANTS.MANDATORY ? "lightgray" : "initial",
                      }}
                      disabled={group?.variant === PaymentPageConfigTypes.FIELD_VARIANTS.MANDATORY}
                    >
                      <MenuIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                  <Grid container item xs justifyContent="space-around" rowGap={3}>
                    {group?.children?.map((item, index) => (
                      <UserRowFields
                        gIndex={gIndex}
                        index={index}
                        fieldData={item}
                        onChange={handlerFunctions.onEditField}
                        errors={errors}
                        key={item?.internalId}
                      />
                    ))}
                  </Grid>
                  <Grid container item xs={3} justifyContent="center" pt={1} ml="auto">
                    {group?.variant === PaymentPageConfigTypes.FIELD_VARIANTS.MANDATORY || group?.is_identifier ? (
                      <Typography variant="caption" mx="auto" sx={payPageSx.blackText}>
                        Mandatory
                      </Typography>
                    ) : (
                      <>
                        <FieldOptionalSwitcher
                          onChange={({ checked }) =>
                            handlerFunctions.onChangeOptional({
                              groupId: group?.group_id,
                              optional: checked,
                            })
                          }
                          data={group}
                          checked={group?.optional}
                          disabled={false}
                        />
                        <IconButton
                          sx={{ p: 0, ml: 1.5 }}
                          disableRipple
                          size="small"
                          onClick={() =>
                            handlerFunctions.onRemoveGroup({
                              groupId: group?.group_id,
                            })
                          }
                        >
                          <CloseIcon color="primary" fontSize="small" />
                        </IconButton>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Box>
            );
          })}
      </Box>
    </>
  );
};

export default UserForm;
