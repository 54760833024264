export const DEFAULT_ERROR = "Something went wrong while getting information. Please try again";
export const RAZORPAY_CHECKOUT_SCRIPT_URL = "https://checkout.razorpay.com/v1/checkout.js";
export const EASEBUZZ_CHECKOUT_SCRIPT_URL =
  "https://ebz-static.s3.ap-south-1.amazonaws.com/easecheckout/easebuzz-checkout.js";
export const PAYMENT_PAGE_COLLECTOR_ID_TOPIC = "paymentPage/view/context/collectorId";
export const PENDING_TXN_ERR_MSG =
  "Unable to confirm the status of this transaction. If the money has been debited from your account, no further action is required. Else, please retry the payment with a different payment method.";
export const COMMON_ERROR_MESSAGE = "Failed to update payment, please contact support.";
export const CUSTOM_FIELD_LABEL = "Custom field";
export const LOOKUP_FIELD_TOPIC = "payment-page-config/lookup-fields";
