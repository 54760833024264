export const AUTH_TOKEN_TOPIC = "paymentPage/authToken";
export const SUPPORT_PHONE_NUMBER = `9606108182`;
export const SUPPORT_EMAIL = `support@jodopay.com`;
export const EndpointBase = {
  md: {
    apiV1Internal: "api/v1/internal",
  },
  pay: {
    apiV1Client: "api/v1/client",
  },
};
