import { validator } from "@jodo-tech/ui-kit-common/validation";
import { PaymentPageConfigTypes } from "../../../configs/types";

export const validAmountRegex = /^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/;

export const paymentConfigValidationSchema = validator.array().of(
  validator.object().shape({
    amount_config: validator
      .array()
      .when("field_type", {
        is: PaymentPageConfigTypes.PAYMENT_FIELD_TYPES.CUSTOM,
        then: validator.array().of(
          validator.object().shape({
            amount: validator
              .string()
              .trim()
              .matches(validAmountRegex, "Please put a valid amount")
              .required("Please put a valid amount"),
          }),
        ),
        otherwise: validator.array().of(
          validator.object().shape({
            amount: validator
              .string()
              .trim()
              .test("len", "Amount must be greater than 0", (val) => !!val && +val > 0)
              .matches(validAmountRegex, "Please put a valid amount")
              .required("Please put a valid amount"),
          }),
        ),
      })
      .when("field_type", {
        is: PaymentPageConfigTypes.PAYMENT_FIELD_TYPES.DROPDOWN,
        then: validator.array().of(
          validator.object().shape({
            label: validator.string().trim().required("Label is required"),
          }),
        ),
        otherwise: validator.array().of(
          validator.object().shape({
            label: validator.string().trim().nullable().notRequired(),
          }),
        ),
      }),
    field_type: validator.string().required("Field Type is required"),
    label: validator
      .string()
      .test("len", "Label must be less than 128 characters", (val) => !!val && val.length < 128)
      .when("variant", {
        is: "custom",
        then: validator.string().required("Label is required, when field type is custom"),
      }),
    optional: validator.boolean().required("Optional is required"),
  }),
);
