export async function copyTextToClipboard(text) {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    throw new Error("Clipboard API not supported");
  }
}

export const isValidLabel = (label) => {
  if (!label) return true; // To make string empty if user trying to make it empty
  if (!label.trim()) return false; // if empty space do not change
  const shouldNotHaveDecimal = /^[^.\r\n]+$/;
  return shouldNotHaveDecimal.test(label);
};

export const isValidAmount = (amount) => {
  const amountRegex = /^[0-9]*(\.[0-9]{0,2})?$/;
  return amountRegex.test(amount);
};

export const toTitleCase = (str) => {
  return str
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
    .replace(/[-_]+(.)/g, (_, c) => ` ${c.toUpperCase()}`); // First char after each -/_
};

export const hasUserSelectedAnyIdentifier = ({
  isStudentCreateEnabled = false,
  studentIdentifierField = "",
  itemPayloadKey,
}) => {
  if (!isStudentCreateEnabled) return false;
  const identifierField = studentIdentifierField || "";
  const itemKey = itemPayloadKey;
  return identifierField === itemKey;
};

export const hasUserSelectedAnyItemOtherThanIdentifier = ({
  isStudentCreateEnabled = false,
  studentIdentifierField = "",
  itemPayloadKey,
}) => {
  if (!isStudentCreateEnabled) return false;
  const identifierField = studentIdentifierField || "";
  const itemKey = itemPayloadKey;
  // if user selects item as identifier then let identifier be enabled as he can change label
  if (identifierField === "feeorder.identifier") return false;
  // if user selects any item other than identifier for eg. Admission Number then we have to disable identifier
  if (identifierField !== "feeorder.identifier" && itemKey === "feeorder.identifier") return true;
};

export function deepTrim(obj) {
  for (const prop in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(prop)) {
      const value = obj[prop];
      const type = typeof value;

      if (value != null && (type === "string" || type === "object" || Array.isArray(value))) {
        if (type === "object") {
          deepTrim(obj[prop]);
        } else if (Array.isArray(value)) {
          for (let i = 0; i < value.length; i++) {
            deepTrim(value[i]);
          }
        } else {
          obj[prop] = obj[prop].trim();
        }
      }
    }
  }
}
