import { validator } from "@jodo-tech/ui-kit-common/validation";

import { PaymentPageConfigTypes } from "../../../configs/types";

export const isMobile = window.innerWidth <= 900; // we need this info at starting, materialMedia query doesn't give correct value on mount
export const maxPixel = window.innerWidth <= 650 ? window.innerWidth : 650;
export const tazapaySDKWidth = maxPixel - 32;
export const tazapayModalWidth = maxPixel;

const phoneNumberRegex = /^[0-9]{10}$/;
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const validAmountRegex = /^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/;

export const paymentPageValidationSchema = validator.object().shape({
  userPaymentFields: validator.array().of(
    validator.object().shape({
      fieldValue: validator.string().when(["fieldChecked", "fieldType"], (fieldChecked, fieldType, fvSchema) => {
        if (fieldChecked && fieldType !== "object") {
          return fvSchema
            .test("len", "Amount must be greater than equal to 0", (val) => !!val && +val >= 0)
            .matches(validAmountRegex, "Please put a valid amount")
            .required("Please put valid amount");
        }

        if (fieldChecked && fieldType === "object") {
          return fvSchema.typeError("Please select an option").required("Please select an option");
        }

        return fvSchema.nullable();
      }),
    }),
  ),

  userFormFields: validator.array().of(
    validator.object().shape({
      fieldValue: validator
        .string()
        .trim()
        .when(
          ["fieldRequired", "fieldAttribute", "fieldType"],
          (fieldRequired, fieldAttribute, fieldType, fvSchema) => {
            if (!fieldRequired) {
              if (fieldType === PaymentPageConfigTypes.USER_FIELD_TYPES.DATE) {
                return fvSchema
                  .test("date-test", "Please enter valid data", (value, validationContext) => {
                    const { createError } = validationContext;
                    if (value === "Invalid date") {
                      return createError({
                        message: "Please input a valid date",
                      });
                    }
                    return true;
                  })
                  .nullable();
              }
              return fvSchema.nullable();
            }
            if (fieldRequired) {
              if (fieldAttribute === "EMAIL") {
                return fvSchema.typeError("Please enter value").matches(emailRegex, "Please enter a valid email");
              }
              if (fieldAttribute === "PHONE") {
                return fvSchema
                  .typeError("Please enter value")
                  .matches(phoneNumberRegex, "Please enter a valid 10 digit phone number");
              }
              if (fieldType === PaymentPageConfigTypes.USER_FIELD_TYPES.DATE) {
                return fvSchema
                  .test("date-test", "Please enter valid data", (value, validationContext) => {
                    const { createError } = validationContext;
                    if (value === "Invalid date" || !value) {
                      return createError({
                        message: "Please input a valid date",
                      });
                    }
                    return true;
                  })
                  .typeError("Please input a valid date");
              }
              return fvSchema.typeError("Please enter value").required("Please enter value");
            }

            return fvSchema;
          },
        ),
    }),
  ),
});

export const ScreenComponents = {
  PAYMENT_PAGE_DESCRIPTION: "PAYMENT_PAGE_DESCRIPTION",
  PAYMENT_PAGE_FORM_HOLDER: "PAYMENT_PAGE_FORM_HOLDER",
  PAYMENT_PAGE_USER_FORM: "PAYMENT_PAGE_USER_FORM",
  PAYMENT_PAGE_PAYMENT_FORM: "PAYMENT_PAGE_PAYMENT_FORM",
  PAYMENT_PAGE_USER_SUMMARY: "PAYMENT_PAGE_USER_SUMMARY",
  PAYMENT_PAGE_PAYMENT_SUMMARY: "PAYMENT_PAGE_PAYMENT_SUMMARY",
  PAYMENT_PAGE_FOOTER: "PAYMENT_PAGE_FOOTER",
  PAYMENT_PAGE_PAY_NOW_BUTTON: "PAYMENT_PAGE_PAY_NOW_BUTTON",
  PAYMENT_PAGE_RETRY_PAYMENT_BUTTON: "PAYMENT_PAGE_RETRY_PAYMENT_BUTTON",
  PAYMENT_PAGE_DOWNLOAD_RECEIPT_BUTTON: "PAYMENT_PAGE_DOWNLOAD_RECEIPT_BUTTON",
  PAYMENT_PAGE_MOBILE_NAVIGATION_HOLDER: "PAYMENT_PAGE_MOBILE_NAVIGATION_HOLDER",
  PAYMENT_PAGE_DESKTOP_NAVIGATION_HOLDER: "PAYMENT_PAGE_DESKTOP_NAVIGATION_HOLDER",
};

export const PaymentPageScreens = {
  PaymentPage: [
    ScreenComponents.PAYMENT_PAGE_DESCRIPTION,
    ScreenComponents.PAYMENT_PAGE_FORM_HOLDER,
    ScreenComponents.PAYMENT_PAGE_FOOTER,
    ScreenComponents.PAYMENT_PAGE_USER_FORM,
    ScreenComponents.PAYMENT_PAGE_PAYMENT_FORM,
    ScreenComponents.PAYMENT_PAGE_DESKTOP_NAVIGATION_HOLDER,
    ScreenComponents.PAYMENT_PAGE_PAY_NOW_BUTTON,
  ],
  PaymentPageSuccess: [
    ScreenComponents.PAYMENT_PAGE_DESCRIPTION,
    ScreenComponents.PAYMENT_PAGE_FORM_HOLDER,
    ScreenComponents.PAYMENT_PAGE_FOOTER,
    ScreenComponents.PAYMENT_PAGE_USER_SUMMARY,
    ScreenComponents.PAYMENT_PAGE_PAYMENT_SUMMARY,
    ScreenComponents.PAYMENT_PAGE_DESKTOP_NAVIGATION_HOLDER,
    ScreenComponents.PAYMENT_PAGE_DOWNLOAD_RECEIPT_BUTTON,
  ],
  PaymentPageFailure: [
    ScreenComponents.PAYMENT_PAGE_DESCRIPTION,
    ScreenComponents.PAYMENT_PAGE_FORM_HOLDER,
    ScreenComponents.PAYMENT_PAGE_FOOTER,
    ScreenComponents.PAYMENT_PAGE_USER_SUMMARY,
    ScreenComponents.PAYMENT_PAGE_PAYMENT_SUMMARY,
    ScreenComponents.PAYMENT_PAGE_DESKTOP_NAVIGATION_HOLDER,
    ScreenComponents.PAYMENT_PAGE_RETRY_PAYMENT_BUTTON,
  ],
  PaymentPagePending: [
    ScreenComponents.PAYMENT_PAGE_DESCRIPTION,
    ScreenComponents.PAYMENT_PAGE_FORM_HOLDER,
    ScreenComponents.PAYMENT_PAGE_FOOTER,
    ScreenComponents.PAYMENT_PAGE_USER_SUMMARY,
    ScreenComponents.PAYMENT_PAGE_PAYMENT_SUMMARY,
  ],
  PaymentPageMobilePage: [
    ScreenComponents.PAYMENT_PAGE_DESCRIPTION,
    ScreenComponents.PAYMENT_PAGE_USER_FORM,
    ScreenComponents.PAYMENT_PAGE_FORM_HOLDER,
    ScreenComponents.PAYMENT_PAGE_PAYMENT_FORM,
    ScreenComponents.PAYMENT_PAGE_MOBILE_NAVIGATION_HOLDER,
    ScreenComponents.PAYMENT_PAGE_FOOTER,
    ScreenComponents.PAYMENT_PAGE_PAY_NOW_BUTTON,
  ],
  PaymentPageSuccessMobile: [
    ScreenComponents.PAYMENT_PAGE_DESCRIPTION,
    ScreenComponents.PAYMENT_PAGE_USER_SUMMARY,
    ScreenComponents.PAYMENT_PAGE_PAYMENT_SUMMARY,
    ScreenComponents.PAYMENT_PAGE_FORM_HOLDER,
    ScreenComponents.PAYMENT_PAGE_MOBILE_NAVIGATION_HOLDER,
    ScreenComponents.PAYMENT_PAGE_DOWNLOAD_RECEIPT_BUTTON,
    ScreenComponents.PAYMENT_PAGE_FOOTER,
  ],
  PaymentPageFailureMobile: [
    ScreenComponents.PAYMENT_PAGE_DESCRIPTION,
    ScreenComponents.PAYMENT_PAGE_USER_SUMMARY,
    ScreenComponents.PAYMENT_PAGE_PAYMENT_SUMMARY,
    ScreenComponents.PAYMENT_PAGE_FORM_HOLDER,
    ScreenComponents.PAYMENT_PAGE_MOBILE_NAVIGATION_HOLDER,
    ScreenComponents.PAYMENT_PAGE_RETRY_PAYMENT_BUTTON,
    ScreenComponents.PAYMENT_PAGE_FOOTER,
  ],
  PaymentPagePendingMobile: [
    ScreenComponents.PAYMENT_PAGE_USER_SUMMARY,
    ScreenComponents.PAYMENT_PAGE_PAYMENT_SUMMARY,
    ScreenComponents.PAYMENT_PAGE_FORM_HOLDER,
    ScreenComponents.PAYMENT_PAGE_FOOTER,
  ],
};

export const appendQueryParams = (url, params) => {
  let separator = url.includes("?") ? "&" : "?";
  const hashIndex = url.indexOf("#");

  if (hashIndex !== -1) {
    let urlWithoutHash = url.substring(0, hashIndex);
    const hash = url.substring(hashIndex);

    params.forEach(([paramName, paramValue]) => {
      urlWithoutHash += `${separator + encodeURIComponent(paramName)}=${encodeURIComponent(paramValue)}`;
      separator = "&";
    });

    return urlWithoutHash + hash;
  } else {
    params.forEach(([paramName, paramValue]) => {
      url += `${separator + encodeURIComponent(paramName)}=${encodeURIComponent(paramValue)}`;
      separator = "&";
    });

    return url;
  }
};

export const getPaymentFieldsSelectionStatus = ({ paymentFields }) => {
  if (!paymentFields || paymentFields.length === 0)
    return {
      isAllChecked: false,
      isAnyChecked: false,
      selectAllDisabled: false,
    };

  let mandatoryFieldsCount = 0;
  let checkedFieldsCount = 0;
  let isAllChecked = false;
  let isAnyChecked = false;
  let selectAllDisabled = false;

  for (const item of paymentFields) {
    if (item?.fieldChecked) {
      checkedFieldsCount++;
      isAnyChecked = true;
    }
    if (item?.fieldRequired) {
      mandatoryFieldsCount++;
    }
  }

  isAllChecked = checkedFieldsCount === paymentFields?.length;
  selectAllDisabled = mandatoryFieldsCount === paymentFields?.length;

  return {
    isAllChecked,
    isAnyChecked,
    selectAllDisabled,
  };
};

export const onlySelectedPaymentFields = (data) => {
  const { userPaymentFields } = data;
  const selectedPaymentFields = (userPaymentFields || []).filter((item) => item?.fieldChecked);
  return { ...data, userPaymentFields: selectedPaymentFields };
};

export const cacheImages = async ({ src }) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = resolve;
    img.onerror = reject;
  });
};

export const getTotalAmount = (renderJSON) => {
  const { userPaymentFields } = renderJSON;
  const totalAmount = (userPaymentFields || []).reduce((acc, curr) => {
    if (!curr.fieldChecked && !curr.fieldRequired) return acc;
    if (curr.fieldType === "number") {
      const amount = +curr?.fieldValue || 0;
      return +acc + +amount;
    }
    if (curr.fieldType === "object") {
      // if object get amount from payload value obj
      const amount = +curr?.payload?.value?.amount || 0;
      return +acc + +amount;
    }
    return 0;
  }, 0);
  return (+totalAmount || 0).toFixed(2);
};
