import { validator } from "@jodo-tech/ui-kit-common/validation";
import { PaymentPageConfigTypes } from "../../../configs/types";

export const userConfigValidationSchema = validator.array().of(
  validator.object().shape({
    children: validator.array().of(
      validator.object().shape({
        field_type: validator.string().trim().required("Field Type is required"),
        label: validator
          .string()
          .trim()
          .test("len", "Label must be less than 128 characters", (val) => !!val && val.length < 128)
          .required("Label is required"),
        dropdown_list_values: validator.array().when(["field_type", "variant"], {
          is: (field_type, variant) => field_type === "dropdown" && variant === "custom",
          then: validator.array().of(
            validator.object().shape({
              value: validator.string().trim().required("Value is required"),
            }),
          ),
          otherwise: validator.array().nullable(),
        }),
        optional: validator.boolean().required("Optional is required"),
      }),
    ),
  }),
);

export const getInfoText = ({ isStudentCreateEnabled, lookupFields, studentIdentifierField }) => {
  try {
    let infoText = "";
    if (lookupFields.length && !isStudentCreateEnabled) {
      const labelArray = PaymentPageConfigTypes.USER_LOOKUP_FIELDS.map((field) => {
        if (lookupFields.includes(field.code)) return field.label;
        return null;
      });

      infoText = labelArray
        .filter((data) => !!data)
        .join(", ")
        .replace(/,([^,]*)$/, " and$1");

      return infoText;
    }

    if (isStudentCreateEnabled && lookupFields.length) {
      let studentCreateFields = [
        "academic_year_start",
        "academic_year_end",
        "grade_id",
        "feeorder.new_admission",
        "feeorder.name",
      ];
      const lookupFieldsWithoutIdentifier = (lookupFields || []).filter((field) => field !== "identifier");

      if (studentIdentifierField) {
        studentCreateFields = [studentIdentifierField, ...studentCreateFields];
      }

      const unionFields = [...new Set([...studentCreateFields, ...lookupFieldsWithoutIdentifier])];
      // get the labels from lookup field codes
      const labelArray = PaymentPageConfigTypes.USER_LOOKUP_FIELDS.map((field) => {
        if (unionFields.includes(field?.code)) return field?.label;
        return null;
      });

      infoText = labelArray
        .filter((data) => !!data)
        .join(", ")
        .replace(/,([^,]*)$/, " and$1");

      return infoText;
    }

    return infoText;
  } catch (error) {
    return "";
  }
};
