import React from "react";
import Button from "@jodo-tech/ui-kit-common/design/Button";
import Chip from "@jodo-tech/ui-kit-common/design/Chip";
import Divider from "@jodo-tech/ui-kit-common/design/Divider";
import Grid from "@jodo-tech/ui-kit-common/design/Grid";
import Typography from "@jodo-tech/ui-kit-common/design/Typography";
import { payPageSx } from "./Common.utils";
import { PaymentPageConfigTypes } from "../../../configs/types";
import { EditOutlined } from "@mui/icons-material";

const formTypeMap = PaymentPageConfigTypes.FORM_TYPES;

const PageSettingsPreview = ({ data, onEditPageDetails }) => {
  const formTypeLabel = formTypeMap?.[data?.form_type]?.label;
  const autoCreateValue = data?.config?.is_student_auto_create_allowed ? "Yes" : "No";
  const autoAssignValue = data?.config?.is_auto_assignment_enabled ? "Yes" : "No";

  return (
    <>
      <Typography variant="body2" fontWeight="bolder" sx={{ ...payPageSx.blackText, mb: 2 }}>
        Page settings
      </Typography>
      <Grid container spacing={2} sx={{ mb: 3 }} columns={4}>
        <Grid item>
          <Chip sx={payPageSx.chip} label={formTypeLabel} />
        </Grid>

        <Grid item>
          <Chip sx={payPageSx.chip} label={`Auto create : ${autoCreateValue}`} />
        </Grid>

        <Grid item>
          <Chip sx={payPageSx.chip} label={`Auto assign : ${autoAssignValue}`} />
        </Grid>
      </Grid>

      <Button variant="text" startIcon={<EditOutlined color="primary" fontSize="small" />} onClick={onEditPageDetails}>
        Edit page settings
      </Button>

      <Divider
        sx={{
          ...payPageSx.divider,
        }}
      />
    </>
  );
};

export default PageSettingsPreview;
