import Box from "@jodo-tech/ui-kit-common/design/Box";
import Typography from "@jodo-tech/ui-kit-common/design/Typography";
import Phone from "@mui/icons-material/PhoneOutlined";
import Email from "@mui/icons-material/EmailOutlined";

const PaymentPageHeader = ({ logoUrl, title, pageName, phone, email, showLogo }) => {
  return (
    <>
      {showLogo && logoUrl && (
        <Box display="flex">
          <Box
            sx={{
              background: "#FFFFFF",
              borderRadius: "16px",
              padding: 2,
              pb: 1,
              mb: 2,
            }}
          >
            <img
              src={logoUrl}
              alt="logo"
              style={{
                height: "60px",
              }}
            />
          </Box>
        </Box>
      )}
      <Typography
        variant="h4"
        sx={{
          color: "#FFFFFF",
          overflowWrap: "anywhere",
          mb: 1,
          fontSize: "40px",
        }}
      >
        {title || "Jodo Payment Page"}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: "#FFFFFF",
          overflowWrap: "anywhere",
          mb: 3,
          fontWeight: 600,
        }}
      >
        {pageName || "Page Name"}
      </Typography>
      <Box
        display="flex"
        alignItems={{ xs: "flex-start", md: "center" }}
        mb={1}
        flexDirection={{ xs: "column", md: "row" }}
      >
        <Typography variant="caption" sx={{ color: "#FFFFFF", overflowWrap: "anywhere", mr: 2 }}>
          For support :
        </Typography>

        <Box display="flex" alignItems="center" mb={1} mt={1} mr={2}>
          <Phone sx={{ color: "#FFFFFF", mr: 1.5 }} />
          <a href={`tel:${phone || "9606108182"}`} style={{ textDecoration: "none" }}>
            <Typography variant="body2" sx={{ color: "#FFFFFF", overflowWrap: "anywhere" }}>
              {phone ? `+91 ${phone}` : "+91 9606108182"}
            </Typography>
          </a>
        </Box>
        <Box display="flex" alignItems="center">
          <Email sx={{ color: "#FFFFFF", mr: 1.5 }} />
          <a href={`mailto:${email || "support@jodopay.com"}`} style={{ textDecoration: "none" }}>
            <Typography variant="body2" sx={{ color: "#FFFFFF", overflowWrap: "anywhere" }}>
              {email || "support@jodopay.com"}
            </Typography>
          </a>
        </Box>
      </Box>
    </>
  );
};

export default PaymentPageHeader;
