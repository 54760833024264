import { PaymentPageConfigTypes } from "../configs/types";
import { nanoid } from "@jodo-tech/ui-kit-common/utils/nanoId";
import { hasUserSelectedAnyIdentifier, hasUserSelectedAnyItemOtherThanIdentifier } from "../helpers";
import get from "lodash/get";

const getFieldVariant = (item) => {
  if (item?.payload_key && ["feeorder.parent", "feeorder.email", "feeorder.phone"].includes(item?.payload_key))
    return PaymentPageConfigTypes.FIELD_VARIANTS.MANDATORY;
  if (item?.payload_key) return PaymentPageConfigTypes.FIELD_VARIANTS.SYSTEM;
  if (item?.collector_component_id) return PaymentPageConfigTypes.FIELD_VARIANTS.SYSTEM;
  return PaymentPageConfigTypes.FIELD_VARIANTS.CUSTOM;
};

const groupConfig = [{ payloadKey: "feeorder.board" }, { payloadKey: "feeorder.grade" }];

export const ApiDataModify = {
  getPaymentPageConfig: ({ result }) => {
    if (!result) throw new Error("No Result Found");
    const {
      id,
      url,
      status,
      lookup,
      is_editable,
      [PaymentPageConfigTypes.PAGES.USER]: userFields,
      [PaymentPageConfigTypes.PAGES.PAYMENT]: payFields,
      config,
      ...rest
    } = result;

    const mutatedUserFields = ApiDataModify.getCollectorFields({
      result: userFields,
      isStudentCreateEnabled: config?.is_student_auto_create_allowed,
      studentIdentifierField: config?.student_identifier_field,
    });

    const payFieldsWithInternalId = payFields.map((item) => ({
      ...item,
      internalId: item?.collector_component_id || nanoid(),
      variant: getFieldVariant({ ...item }),
    }));

    return {
      id,
      url,
      status,
      lookup,
      is_editable,
      [PaymentPageConfigTypes.PAGES.PAGE_SETTINGS]: {
        form_type: PaymentPageConfigTypes.FORM_TYPES.DEFAULT.code, // hardcoded for now
        config,
      },
      [PaymentPageConfigTypes.PAGES.USER]: [...mutatedUserFields],
      [PaymentPageConfigTypes.PAGES.PAYMENT]: payFieldsWithInternalId,
      [PaymentPageConfigTypes.PAGES.META]: {
        ...rest,
        ...rest.contact_details,
      },
    };
  },
  getCollectorComponents: ({ result }) => {
    if (!result)
      return [
        {
          value: "No Options Present",
          label: "No Options Present",
          internalId: "-1",
        },
      ];
    const resultWithInternalId = result.map((item) => ({
      ...item,
      internalId: item?.id || nanoid(),
      collector_component_id: item?.id,
      variant: getFieldVariant({ ...item, collector_component_id: item?.id }),
      optional: false,
      field_type: "fixed",
      id: null,
      amount_config: [
        {
          amount: "",
          internalId: null,
          label: null,
        },
      ],
    }));
    return resultWithInternalId;
  },
  getCollectorFields: ({ result, isStudentCreateEnabled, studentIdentifierField }) => {
    if (!result)
      return [
        {
          value: "No Options Present",
          label: "No Options Present",
          internalId: "-1",
        },
      ];

    const mockResultWithGroupId = result.map((item) => ({
      ...item,
      group_id: groupConfig.map((item) => item?.payloadKey).includes(item?.payload_key)
        ? `streamGroup`
        : item?.payload_key
        ? item?.payload_key
        : nanoid(),
    }));

    const groupedData = [];

    const isGroupPresent = (item) => {
      const groupId = item?.group_id;
      if (!groupId) return false;
      return groupedData?.some((group) => group?.group_id === groupId);
    };

    mockResultWithGroupId.forEach((item) => {
      const isIdentifier = hasUserSelectedAnyIdentifier({
        isStudentCreateEnabled,
        studentIdentifierField,
        itemPayloadKey: item?.payload_key,
      });
      const isOtherThanIdentifier = hasUserSelectedAnyItemOtherThanIdentifier({
        isStudentCreateEnabled,
        studentIdentifierField,
        itemPayloadKey: item?.payload_key,
      });
      const disabledInList = isOtherThanIdentifier || isIdentifier;
      const fieldVariant = getFieldVariant({ ...item });
      const groupId = item?.group_id;
      if (isGroupPresent(item)) {
        groupedData.forEach((group, index) => {
          if (group?.group_id === groupId) {
            groupedData[index] = {
              ...group,
              group_label: `${group?.group_label} + ${item?.label}`,
              children: [
                ...group?.children,
                {
                  ...item,
                  internalId: item?.payload_key || nanoid(),
                  payload_label: item?.label,
                  id: null,
                  variant: fieldVariant,
                  is_identifier: isIdentifier,
                  optional: item?.optional || false,
                },
              ],
            };
          }
        });
      } else {
        groupedData.push({
          group_id: item?.group_id,
          group_label: item?.label,
          variant: fieldVariant,
          is_identifier: isIdentifier,
          disable_in_list: disabledInList,
          optional: item?.optional || false,
          is_user_action:
            fieldVariant === PaymentPageConfigTypes.FIELD_VARIANTS.MANDATORY || isIdentifier ? false : true,
          children: [
            {
              ...item,
              internalId: item?.payload_key || nanoid(),
              payload_label: item?.label,
              id: null,
              variant: fieldVariant,
              is_identifier: isIdentifier,
              optional: item?.optional || false,
            },
          ],
        });
      }
    });

    return groupedData;
  },
  getPaymentPage: ({ result }) => {
    const renderJSON = {
      collector: {
        id: result?.collector?.id,
        collectorLogoUrl: result?.collector?.logo_url,
        collectorName: result?.collector?.name,
        payload: result?.collector?.payload,
      },
      paymentPageDescription: result?.description,
      paymentPageTitle: result?.label,
      paymentPageId: result?.id,
      sendFeeReceipt: result?.send_fee_receipt,
      paymentPageSupportContactDetails: {
        email: result?.support_details?.email ?? "support@jodopay.com",
        phone: result?.support_details?.phone_number ?? "9606108182",
      },
      userFormFields: (result?.data_items || [])?.map((item) => {
        return {
          payload: item?.payload,
          fieldLabel: item?.label,
          fieldType: item?.input_type,
          fieldAttribute: item?.field_type,
          fieldVariant: item?.type,
          fieldValue: item?.payload?.value,
          fieldInternalId: nanoid(),
          fieldOptions: item?.options,
          fieldRequired: item?.mandatory,
          fieldReadOnly: !item?.is_editable,
          fieldUIKey: item?.ui_key,
          fieldPriority: item?.item_priority,
          fieldHidden: item?.is_hidden || false,
        };
      }),
      userPaymentFields: (result?.payment_items || [])?.map((item) => {
        return {
          payload: item?.payload,
          fieldLabel: item?.label,
          fieldType: item?.input_type,
          fieldVariant: item?.type,
          fieldValue: item?.payload?.value,
          fieldInternalId: nanoid(),
          fieldOptions: item?.options,
          fieldRequired: item?.mandatory,
          fieldReadOnly: !item?.is_editable,
          fieldUIKey: item?.ui_key,
          fieldChecked: item?.mandatory || false,
          fieldPriority: item?.item_priority,
        };
      }),
    };

    return renderJSON;
  },
  createOrderPayload: ({ result }) => {
    const orderPayload = {
      page_id: result?.paymentPageId,
      instant_payment: true,
    };

    const producePayload = (obj) => {
      const payload = obj?.payload || {};
      const { fields, path, value } = payload;
      const ifAnyPayloadContainsValue = (fields || []).some((field) => {
        return (field.key || "").startsWith("value") ? true : false;
      });
      if (ifAnyPayloadContainsValue && (value === null || value === undefined)) {
        return;
      }

      (fields || []).forEach((field) => {
        if (get(payload, field.key) === null || get(payload, field.key) === undefined) return;
        orderPayload[`${path}.${field.payload_key}`] = get(payload, field.key);
      });
    };

    Object.values(result).forEach((value) => {
      if (value === null || value === undefined) return;
      if (typeof value === "object" && value?.payload) {
        producePayload(value);
      } else {
        if (Array.isArray(value)) {
          value.forEach((item) => {
            if (typeof item === "object" && item?.payload) {
              producePayload(item);
            }
          });
        }
      }
    });

    const unFlatten = function (data) {
      "use strict";
      if (Object(data) !== data || Array.isArray(data)) return data;
      const regex = /\.?([^.\[\]]+)|\[(\d+)\]/g;
      const resultholder = {};
      for (var p in data) {
        var cur = resultholder,
          prop = "",
          m;
        while ((m = regex.exec(p))) {
          cur = cur[prop] || (cur[prop] = m[2] ? [] : {});
          prop = m[2] || m[1];
        }
        cur[prop] = data[p];
      }
      return resultholder[""] || resultholder;
    };

    const unFlattenedPayload = unFlatten(orderPayload);

    const { payment_details, ...rest } = unFlattenedPayload;

    const finalPayload = {
      payment_details: payment_details?.filter((item) => !!item),
      ...rest,
    };

    return finalPayload;
  },
  getCollectorGrades: ({ result }) => {
    if (result?.length !== 0) {
      const resultWithinternalId = (result || [])?.map((item) => ({
        ...item,
        internalId: nanoid(),
        label: item?.name,
        value: item?.id,
      }));
      return resultWithinternalId;
    } else {
      return [
        {
          internalId: "-1",
          label: "No Options Present",
          value: "No Options Present",
        },
      ];
    }
  },
  getCollectorStreams: ({ result }) => {
    if (result?.length !== 0) {
      const resultWithinternalId = (result || [])?.map((item) => ({
        ...item,
        internalId: nanoid(),
        label: item?.name,
        value: item?.id,
      }));
      return resultWithinternalId;
    } else {
      return [
        {
          internalId: "-1",
          label: "No Options Present",
          value: "No Options Present",
        },
      ];
    }
  },
  getCollectorAY: ({ result }) => {
    if (result?.length !== 0) {
      const resultWithinternalId = (result || [])?.map((item) => ({
        ...item,
        internalId: nanoid(),
        label: item?.join?.(" -"),
        value: item?.join?.(" -"),
        academic_year_start: item?.[0],
        academic_year_end: item?.[1],
      }));
      return resultWithinternalId;
    } else {
      return [
        {
          internalId: "-1",
          label: "No Options Present",
          value: "No Options Present",
        },
      ];
    }
  },
};
