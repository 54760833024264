import { useContext } from "react";
import InfoPage from "../InfoPage";
import ExpiredInfographic from "../../../assets/ExpiredInfographic";
import ErrorInfographic from "../../../assets/ErrorInfographic";
import SuccessInfographic from "../../../assets/SuccessInfographic";
import NotFoundInfographic from "../../../assets/NotFoundInfographic";
import Button from "@jodo-tech/ui-kit-common/design/Button/Button.atom";
import { PaymentPageViewTypes } from "../../../configs/types";
import { PaymentPageContext } from "../../../context/paymentPage.context";
import { notify } from "@jodo-tech/ui-kit-common/design/Notify";

export const ExpiredPaymentPage = () => (
  <InfoPage
    infographic={<ExpiredInfographic />}
    title="Payment page expired"
    description={
      <>
        The payment page you are trying to use has expired. Contact <a href="tel:+919606108182">Jodo support</a> to
        receive another payment link
      </>
    }
  />
);

export const SomethingWentWrongPage = () => (
  <InfoPage
    infographic={<ExpiredInfographic />}
    title="Payment page unavailable"
    description={
      <>
        this payment page currently is not available, please try again in sometime. In case you are unable to proceed
        ahead please contact the institute or
        <a href="tel:+919606108182">Jodo support</a>
      </>
    }
  />
);

export const FailedPaymentPage = () => (
  <InfoPage
    infographic={<ErrorInfographic />}
    title="Payment failed"
    description={
      <>There was an issue regarding this payment. Please visit the institute portal and retry the payment.</>
    }
  />
);

export const SuccessPaymentPage = () => (
  <InfoPage
    infographic={<SuccessInfographic />}
    title="Payment done successfully"
    description={
      <>You have already paid through the payment page. A receipt for the payment has been sent to your email address</>
    }
  />
);

export const RedirectPaymentPage = () => {
  const { callbackUrl, orderId, tracker } = useContext(PaymentPageContext);
  const handleReportIssue = () => {
    tracker?.captureErrMessage?.(
      `Redirection takes too long where order Id is=${orderId} and callback url is ${callbackUrl}`,
    );
    notify.success({ message: "Issue reported successfully" });
  };
  return (
    <InfoPage
      infographic={<ExpiredInfographic />}
      title="Redirection in progress..."
      description={
        <>
          Please wait while we redirect you to the institute page. if it doesn&apos;t get redirected in few seconds,
          then click here to report issue.
          <Button sx={{ mt: 2 }} variant="text" size="medium" onClick={handleReportIssue}>
            Report Issue
          </Button>
        </>
      }
    />
  );
};

export const NotFoundPaymentPage = () => (
  <InfoPage
    infographic={<NotFoundInfographic />}
    title="Payment page not found"
    description={
      <>
        The payment page you are trying to access does not exist. Please contact the institute or
        <a href="tel:+919606108182">Jodo support</a>
      </>
    }
  />
);

export const getOrderIdStatusPage = (status) => {
  const statusPages = {
    [PaymentPageViewTypes.STATUS.EXPIRED]: <ExpiredPaymentPage />,
    [PaymentPageViewTypes.STATUS.FAILED]: <FailedPaymentPage />,
    [PaymentPageViewTypes.STATUS.SUCCESS]: <SuccessPaymentPage />,
    [PaymentPageViewTypes.STATUS.NOTFOUND]: <NotFoundPaymentPage />,
    [PaymentPageViewTypes.STATUS.CANCELLED]: <NotFoundPaymentPage />,
    [PaymentPageViewTypes.STATUS.API_ERROR]: <SomethingWentWrongPage />,
    [PaymentPageViewTypes.STATUS.REDIRECT]: <RedirectPaymentPage />,
  };
  return statusPages[status] || statusPages[PaymentPageViewTypes.STATUS.NOTFOUND];
};
