/* eslint-disable no-case-declarations */
import Grid from "@jodo-tech/ui-kit-common/design/Grid";
import Box from "@jodo-tech/ui-kit-common/design/Box";
import Typography from "@jodo-tech/ui-kit-common/design/Typography";
import InputAdornment from "@jodo-tech/ui-kit-common/design/InputAdornment";
import Checkbox from "@jodo-tech/ui-kit-common/design/Checkbox";
import Divider from "@jodo-tech/ui-kit-common/design/Divider";
import TextField from "@jodo-tech/ui-kit-common/design/TextField";
import MenuItem from "@jodo-tech/ui-kit-common/design/MenuItem";
import IconButton from "@jodo-tech/ui-kit-common/design/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import get from "lodash/get";
import CurrencyRupee from "@mui/icons-material/CurrencyRupee";
import LockOutlined from "@mui/icons-material/LockOutlined";
import { getPaymentFieldsSelectionStatus } from "./PaymentPage.utils";

const PaymentPagePaymentForm = ({
  userPaymentFields,
  onChange,
  onSelectionChange,
  onSelectionAllChange,
  totalAmount,
  errors,
  isOrderMode,
}) => {
  const { isAllChecked, isAnyChecked, selectAllDisabled } = getPaymentFieldsSelectionStatus({
    paymentFields: userPaymentFields,
  });

  const createMenuItems = (options) => {
    return options?.map((option, index) => {
      const label = option?.label;
      const amount = option?.amount;
      const value = option?.internalId;
      return (
        <MenuItem key={index} value={value}>
          <Typography variant="body2">
            {label} - ₹{amount}
          </Typography>
        </MenuItem>
      );
    });
  };

  const getPaymentFieldInput = (item, index) => {
    const inputDisabled = item?.fieldReadOnly || !item?.fieldChecked;
    switch (item.fieldType) {
      case "object":
        let options = [
          {
            amount: "No Options Present",
            label: "No Options Present",
            internalId: "-1",
          },
        ];
        if (item?.fieldOptions?.data && item?.fieldOptions?.data?.length !== 0) {
          options = item?.fieldOptions?.data;
        }

        const dropdownOptions = createMenuItems(options);

        return (
          <TextField
            select
            size="small"
            disabled={inputDisabled}
            SelectProps={{
              displayEmpty: true,
              renderValue: (value) => {
                if (value === null || value === undefined || value === "" || value === 0) {
                  return <Typography color="text.disabled">{item?.fieldLabel}</Typography>;
                }
                const selectedOption = (options || [])?.find((option) => {
                  return option?.internalId === value;
                });

                return (
                  <Typography variant="body2">
                    {selectedOption?.label} - ₹{selectedOption?.amount}
                  </Typography>
                );
              },
            }}
            fullWidth
            value={item?.fieldValue === undefined ? "" : item?.fieldValue}
            data-error={`userPaymentFields[${index}].fieldValue`}
            error={!inputDisabled && !!get(errors, `userPaymentFields[${index}].fieldValue`)}
            helperText={!inputDisabled && get(errors, `userPaymentFields[${index}].fieldValue`)}
            variant="outlined"
            onChange={(e) => {
              if (e.target.value === "-1") return;
              onChange(
                item?.fieldInternalId,
                (options || [])?.find((option) => {
                  return option?.internalId === e.target.value;
                }),
              );
            }}
            InputProps={{
              endAdornment: item?.fieldValue !== null &&
                item?.fieldValue !== undefined &&
                item?.fieldValue !== "" &&
                item?.fieldValue !== 0 && (
                  <InputAdornment position="end" sx={{ mr: 2 }}>
                    <IconButton
                      disabled={inputDisabled}
                      onClick={() => {
                        onChange(item?.fieldInternalId, null);
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              sx: {
                input: {
                  cursor: inputDisabled ? "not-allowed" : "auto",
                },
                backgroundColor: inputDisabled ? "grey.100" : "white",
              },
            }}
          >
            {dropdownOptions}
          </TextField>
        );

      default:
        return (
          <TextField
            size="small"
            variant="outlined"
            value={item?.fieldValue}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CurrencyRupee fontSize="small" color={!item?.fieldChecked ? "disabled" : "inherit"} />
                </InputAdornment>
              ),
              endAdornment: item?.fieldReadOnly && (
                <InputAdornment position="end">
                  <LockOutlined fontSize="small" />
                </InputAdornment>
              ),
            }}
            data-error={`userPaymentFields[${index}].fieldValue`}
            error={!!get(errors, `userPaymentFields[${index}].fieldValue`)}
            helperText={get(errors, `userPaymentFields[${index}].fieldValue`)}
            inputProps={{
              readOnly: item?.fieldReadOnly,
            }}
            sx={{
              input: {
                cursor: inputDisabled ? "not-allowed" : "auto",
              },
              backgroundColor: inputDisabled ? "grey.100" : "white",
            }}
            disabled={inputDisabled}
            onChange={(e) => onChange(item?.fieldInternalId, e.target.value)}
            fullWidth
          />
        );
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        mb={{ xs: 3, md: 3 }}
        mt={{
          xs: 0,
          md: 5,
        }}
      >
        <CurrencyRupee />
        <Typography
          sx={{
            typography: {
              xs: "subtitle2",
              md: "subtitle1",
              overflowWrap: "anywhere",
            },
            ml: 1,
          }}
        >
          <b>Payment details</b>
        </Typography>
      </Box>

      <Grid container mb={{ xs: 3, md: 3 }}>
        <Grid item>
          <Checkbox
            sx={{ p: 0, pr: 2 }}
            size="small"
            checked={isAllChecked}
            indeterminate={!isAllChecked && isAnyChecked}
            disabled={selectAllDisabled}
            onChange={(e, checked) => {
              onSelectionAllChange(checked);
            }}
            disableRipple={true}
          />
        </Grid>
        <Grid item>
          <Typography
            sx={{
              typography: {
                xs: "body2",
                md: "body1",
                overflowWrap: "anywhere",
              },
              pr: { xs: 0, md: 2 },
              mb: { xs: 1, md: 0 },
            }}
          >
            Select all
          </Typography>
        </Grid>
      </Grid>

      {(userPaymentFields || [])?.map((item, index) => {
        let checkBoxDisabled = item?.fieldRequired;

        if (isOrderMode) {
          checkBoxDisabled = item?.fieldRequired;
        }

        return (
          <Grid
            container
            item
            mb={{ xs: 3, md: 2 }}
            alignItems="baseline"
            justifyContent="flex-start"
            key={item?.fieldInternalId}
          >
            <Grid
              container
              item
              xs={12}
              md={6}
              flexWrap="nowrap"
              alignItems={{ md: "flex-start", xs: "baseline" }}
              alignSelf={{ xs: "center", md: "flex-start" }}
              pt={{ xs: 0, md: 1 }}
            >
              <Grid item>
                <Checkbox
                  sx={{ p: 0, pr: 2 }}
                  size="small"
                  disabled={checkBoxDisabled}
                  checked={item?.fieldChecked || item?.fieldRequired}
                  onChange={(e) => onSelectionChange(item?.fieldInternalId, e.target.checked)}
                  disableRipple={true}
                />
              </Grid>

              <Grid item xs md>
                <Typography
                  sx={{
                    typography: {
                      xs: "body2",
                      md: "body1",
                      overflowWrap: "anywhere",
                    },
                    pr: { xs: 0, md: 2 },
                    mb: { xs: 1, md: 0 },
                  }}
                >
                  {item?.fieldLabel}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              {getPaymentFieldInput(item, index)}
            </Grid>
          </Grid>
        );
      })}

      <Grid container>
        <Grid item xs={12}>
          <Divider
            orientation="horizontal"
            sx={{
              mt: 3,
              mb: 2,
              color: "#f3f3f3",
            }}
          />
        </Grid>
        <Grid container item alignItems="center" justifyContent="space-between" mb={2}>
          <Grid item xs={6} md={3}>
            <Typography variant="subtitle1">
              <b>Total Amount</b>
            </Typography>
          </Grid>
          <Grid item xs md>
            <Typography textAlign="end" variant="subtitle1">
              <b>
                {
                  <Box display="flex" justifyContent="flex-end" alignItems="center">
                    <CurrencyRupee fontSize="small" />
                    {totalAmount}
                  </Box>
                }
              </b>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PaymentPagePaymentForm;
