/* eslint-disable no-case-declarations */
import Grid from "@jodo-tech/ui-kit-common/design/Grid";
import Box from "@jodo-tech/ui-kit-common/design/Box";
import Typography from "@jodo-tech/ui-kit-common/design/Typography";
import PersonOutline from "@mui/icons-material/PersonOutline";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@jodo-tech/ui-kit-common/design/TextField";
import InputAdornment from "@jodo-tech/ui-kit-common/design/InputAdornment";
import IconButton from "@jodo-tech/ui-kit-common/design/IconButton";
import MenuItem from "@jodo-tech/ui-kit-common/design/MenuItem";
import get from "lodash/get";
import moment from "moment";
import { DatePickerKit } from "@jodo-tech/ui-kit-common/design/DatePicker";

const PaymentPageUserForm = ({ userFormFields, onChange, errors, systemFieldOptionsMap }) => {
  const createMenuItems = (options) => {
    return options?.map((option, index) => {
      const label = option?.label;
      const value = option?.internalId;
      return (
        <MenuItem key={index} value={value}>
          {label}
        </MenuItem>
      );
    });
  };

  // disable academic year dropdown if grade is not selected and disable grade if stream is not selected
  const disableDropdown = (item) => {
    if (item?.fieldUIKey?.includes?.("grade")) {
      const stream = userFormFields.filter((item) => item?.fieldUIKey?.includes?.("stream"))?.[0];

      if (stream) {
        return !stream?.fieldValue;
      } else {
        return false;
      }
    }
    return false;
  };

  const getUserFieldInput = (item, index) => {
    switch (item.fieldType) {
      case "object":
        let options = [
          {
            value: "No Options Present",
            label: "No Options Present",
            internalId: "-1",
          },
        ];
        if (item?.fieldOptions?.data && item?.fieldOptions?.data?.length !== 0) {
          options = item?.fieldOptions?.data;
        } else {
          options = systemFieldOptionsMap[item?.fieldUIKey];
        }

        const isDropdownDisabled = disableDropdown(item) || item?.fieldReadOnly;

        const dropdownOptions = createMenuItems(options);

        return (
          <TextField
            select
            size="small"
            disabled={isDropdownDisabled}
            SelectProps={{
              displayEmpty: true,
              renderValue: (value) => {
                if (value === null || value === undefined || value === "") {
                  return <Typography color="text.disabled">{item?.fieldLabel}</Typography>;
                }
                return (options || [])?.find((option) => {
                  return option?.internalId === value;
                })?.label;
              },
            }}
            fullWidth
            value={item?.fieldValue === undefined ? "" : item?.fieldValue}
            data-error={`userFormFields[${index}].fieldValue`}
            error={!isDropdownDisabled && !!get(errors, `userFormFields[${index}].fieldValue`)}
            helperText={!isDropdownDisabled && get(errors, `userFormFields[${index}].fieldValue`)}
            variant="outlined"
            onChange={(e) => {
              if (e.target.value === "-1") return;
              onChange(
                item?.fieldInternalId,
                (options || [])?.find((option) => {
                  return option?.internalId === e.target.value;
                }),
              );
            }}
            InputProps={{
              endAdornment: item?.fieldValue !== null && item?.fieldValue !== undefined && item?.fieldValue !== "" && (
                <InputAdornment position="end" sx={{ mr: 2 }}>
                  <IconButton
                    disabled={isDropdownDisabled}
                    onClick={() => {
                      onChange(item?.fieldInternalId, null);
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
              sx: {
                input: {
                  cursor: isDropdownDisabled ? "not-allowed" : "auto",
                },
                backgroundColor: isDropdownDisabled ? "grey.100" : "white",
              },
            }}
          >
            {dropdownOptions}
          </TextField>
        );

      case "date":
        return (
          <DatePickerKit
            {...{
              format: {
                input: "DD-MM-YYYY",
                value: "YYYY-MM-DD",
                output: "YYYY-MM-DD",
              },
              value: item?.fieldValue,
              textFieldProps: {
                fullWidth: true,
                size: "small",
                variant: "outlined",
                placeholder: item?.fieldLabel,
                disabled: item?.fieldReadOnly,
                onKeyDown: (e) => {
                  e.preventDefault();
                },
                sx: {
                  input: {
                    cursor: item?.fieldReadOnly ? "not-allowed" : "auto",
                  },
                  backgroundColor: item?.fieldReadOnly ? "grey.100" : "white",
                },
                error: !!get(errors, `userFormFields[${index}].fieldValue`),
                helperText: get(errors, `userFormFields[${index}].fieldValue`),
                "data-error": `userFormFields[${index}].fieldValue`,
              },
              onChange: (date) => {
                const pickedDate = moment(date);
                if (pickedDate.isValid()) {
                  onChange(item?.fieldInternalId, date);
                } else {
                  onChange(item?.fieldInternalId, null);
                }
              },
            }}
          />
        );

      default:
        return (
          <TextField
            size="small"
            placeholder={item?.fieldLabel}
            fullWidth
            disabled={item?.fieldReadOnly}
            sx={{
              input: {
                cursor: item?.fieldReadOnly ? "not-allowed" : "auto",
              },
              backgroundColor: item?.fieldReadOnly ? "grey.100" : "white",
            }}
            variant="outlined"
            value={item?.fieldValue}
            data-error={`userFormFields[${index}].fieldValue`}
            error={!!get(errors, `userFormFields[${index}].fieldValue`)}
            helperText={get(errors, `userFormFields[${index}].fieldValue`)}
            onChange={(e) => onChange(item?.fieldInternalId, e.target.value)}
          />
        );
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center" mb={{ xs: 3, md: 3 }}>
        <PersonOutline />
        <Typography
          sx={{
            typography: {
              xs: "subtitle2",
              md: "subtitle1",
              overflowWrap: "anywhere",
            },
            ml: 1,
          }}
        >
          <b>User details</b>
        </Typography>
      </Box>

      {(userFormFields || [])
        .filter((item) => !item?.fieldHidden)
        .map((item, index) => {
          return (
            <Grid container key={item?.fieldInternalId} item alignItems="flex-start" mb={{ xs: 4, md: 2 }}>
              <Grid
                container
                item
                xs
                md={6}
                pt={{ xs: 0, md: 1 }}
                pr={{
                  xs: 0,
                  md: 2,
                }}
                mb={{ xs: 1, md: 0 }}
                alignSelf="flex-start"
              >
                <Typography
                  sx={{
                    typography: {
                      xs: "body2",
                      md: "body1",
                      overflowWrap: "anywhere",
                    },
                    overflowWrap: "anywhere",
                  }}
                >
                  {item?.fieldLabel} {item?.fieldRequired ? "" : "(Optional)"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                {getUserFieldInput(item, index)}
              </Grid>
            </Grid>
          );
        })}
    </>
  );
};

export default PaymentPageUserForm;
