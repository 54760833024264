export const payPageSx = {
  disabledText: {
    color: "#A7A7A7",
  },
  divider: {
    color: "#F3F3F3",
    my: 3,
    borderColor: "unset",
  },
  grayText: {
    color: "#5F5F5F",
  },
  blackText: {
    color: "#171717",
  },
  inputFontSmall: {
    fontSize: "12px",
    color: "#171717",
  },
  inputFontLarge: {
    fontSize: "14px",
    color: "#171717",
  },
  chip: {
    backgroundColor: "#EAF0FB",
    height: "100%",
    span: {
      whiteSpace: "normal",
    },
    py: 1,
  },
};
export const richTextEditorSx = {
  listStyles: {
    ol: {
      listStyleType: "decimal !important",
    },
    ul: {
      listStyleType: "disc !important",
    },
  },
};
