import "suneditor/dist/css/suneditor.min.css";

import Box from "@jodo-tech/ui-kit-common/design/Box";
import { DatePickerKit } from "@jodo-tech/ui-kit-common/design/DatePicker";
import Grid from "@jodo-tech/ui-kit-common/design/Grid";
import InputAdornment from "@jodo-tech/ui-kit-common/design/InputAdornment";
import MenuItem from "@jodo-tech/ui-kit-common/design/MenuItem";
import TextField from "@jodo-tech/ui-kit-common/design/TextField";
import Typography from "@jodo-tech/ui-kit-common/design/Typography";
import { EmailOutlined, PhoneOutlined } from "@mui/icons-material";
import plugins from "suneditor/src/plugins";
import SunEditor from "suneditor-react";

import { PaymentPageConfigTypes } from "../../../configs/types";
import { payPageSx, richTextEditorSx } from "../Common/Common.utils";
import { convertStringToHTMLString } from "./MetaConfig.utils";

function MetaForm({ onChange, data, errors }) {
  const currentPage = PaymentPageConfigTypes.PAGES.META;
  return (
    <>
      <TextField
        id="standard-basic"
        placeholder="Enter page name"
        variant="standard"
        fullWidth
        size="small"
        sx={{ mb: 4 }}
        InputProps={{
          sx: {
            fontSize: "30px",
            fontWeight: 700,
            lineHeight: "45px",
          },
        }}
        value={data?.name || ""}
        onChange={(e) => {
          onChange({ currentPage, data: { name: e.target.value } });
        }}
        error={errors?.name}
        helperText={errors?.name}
      />

      <Box
        sx={{
          ...richTextEditorSx.listStyles,
        }}
      >
        <SunEditor
          setDefaultStyle="border-radius:8px; font-family:'Lato', sans-serif"
          height="200"
          setAllPlugins={false}
          setOptions={{
            plugins: [plugins.font, plugins.fontSize, plugins.list, plugins.link, plugins.formatBlock],
            buttonList: [["formatBlock"], ["bold", "underline", "italic", "strike"], ["link"], ["list"]],
            formats: ["p", "h1", "h2", "h3", "h4", "h5", "h6"],
            resizingBar: false,
            font: ["Lato", "sans-serif"],
          }}
          defaultValue={convertStringToHTMLString(data?.description || "")}
          onChange={(e) => {
            onChange({ currentPage, data: { description: e } });
          }}
        />
      </Box>

      <Grid container item justifyContent="space-between" sx={{ my: 3 }}>
        <Grid item xs={5.5}>
          <TextField
            error={errors?.send_fee_receipt}
            helperText={errors?.send_fee_receipt}
            select
            size="small"
            label="Send Fee Receipt"
            fullWidth
            InputLabelProps={{
              sx: payPageSx.inputFontLarge,
            }}
            InputProps={{
              sx: payPageSx.inputFontLarge,
            }}
            value={data?.send_fee_receipt}
            onChange={(e) => {
              onChange({
                currentPage,
                data: { send_fee_receipt: e.target.value },
              });
            }}
          >
            <MenuItem key="yes" value={"true"}>
              Yes
            </MenuItem>
            <MenuItem key="no" value={"false"}>
              No
            </MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={5.5}>
          <DatePickerKit
            {...{
              format: {
                input: "DD-MM-YYYY",
                value: "YYYY-MM-DD",
                output: "YYYY-MM-DD",
              },
              value: data?.expiry_date || null,
              textFieldProps: {
                label: (
                  <>
                    Expiry Date <small>(Optional)</small>
                  </>
                ),
                InputLabelProps: {
                  sx: payPageSx.inputFontLarge,
                },
                InputProps: {
                  sx: payPageSx.inputFontLarge,
                },
                fullWidth: true,
                size: "small",
                onKeyDown: (e) => {
                  e.preventDefault();
                },
                error: !!errors?.expiry_date,
                helperText: errors?.expiry_date,
              },
              onChange: (date) => {
                onChange({ currentPage, data: { expiry_date: date } });
              },
              datePickerProps: {
                disablePast: true,
              },
            }}
          />
        </Grid>
      </Grid>

      <Typography variant="subtitle2" sx={{ ...payPageSx.blackText, mt: 5, mb: 3, textAlign: "start" }}>
        <b>Contact information</b>
      </Typography>

      <TextField
        label={
          <>
            Phone Number <small>(Optional)</small>
          </>
        }
        InputLabelProps={{
          sx: payPageSx.inputFontLarge,
        }}
        InputProps={{
          sx: payPageSx.inputFontLarge,
          startAdornment: (
            <InputAdornment position="start">
              <PhoneOutlined color="primary" fontSize="small" />
            </InputAdornment>
          ),
        }}
        variant="outlined"
        fullWidth
        size="small"
        sx={{ mb: 3 }}
        value={data?.phone_number || ""}
        onChange={(e) => {
          onChange({ currentPage, data: { phone_number: e.target.value } });
        }}
        error={errors?.phone_number}
        helperText={errors?.phone_number}
      />

      <TextField
        label={
          <>
            Email Address <small>(Optional)</small>
          </>
        }
        InputLabelProps={{
          sx: payPageSx.inputFontLarge,
        }}
        InputProps={{
          sx: payPageSx.inputFontLarge,
          startAdornment: (
            <InputAdornment position="start">
              <EmailOutlined color="primary" fontSize="small" />
            </InputAdornment>
          ),
        }}
        variant="outlined"
        fullWidth
        size="small"
        value={data?.email || ""}
        onChange={(e) => {
          onChange({ currentPage, data: { email: e.target.value } });
        }}
        error={errors?.email}
        helperText={errors?.email}
      />
    </>
  );
}

export default MetaForm;
