import Backdrop from "@jodo-tech/ui-kit-common/design/Backdrop";
import Box from "@jodo-tech/ui-kit-common/design/Box";
import Captcha from "@jodo-tech/ui-kit-common/design/Captcha";
import CircularProgress from "@jodo-tech/ui-kit-common/design/CircularProgress";
import Footer from "@jodo-tech/ui-kit-common/design/Footer";
import LoginLayout from "@jodo-tech/ui-kit-common/design/LoginLayout";
import { notify } from "@jodo-tech/ui-kit-common/design/Notify";
import Typography from "@jodo-tech/ui-kit-common/design/Typography";
import { EventEmitter } from "@jodo-tech/ui-kit-common/utils/emitter";
import { GeneralError } from "@jodo-tech/ui-kit-common/utils/error";
import { InfoPage } from "./../../widget/payment-page";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import PhoneOutlineIcon from "@mui/icons-material/LocalPhoneOutlined";
import React, { CSSProperties, useEffect, useState } from "react";

import { AUTH_TOKEN_TOPIC, SUPPORT_EMAIL, SUPPORT_PHONE_NUMBER } from "../../helper/constants";
import { Env } from "../../helper/env";
import { tokenApi } from "../../vendors/axios";

const Styles: { [key: string]: CSSProperties } = {
  captchaClick: {
    marginTop: "40px",
    marginBottom: "40px",
  },
};

const getToken = async () => {
  const res = await tokenApi.get("/token");
  const result = res?.data;
  return result?.token || null;
};

const isCaptchaEnabledFromEnv = (Env.REACT_APP_CAPTCHA_PAGE_ENABLED || "enable") === "enable";

interface ICaptchaProps {
  children: React.ReactNode;
  isEnabled?: boolean;
}
const CaptchaPage = ({ children, isEnabled = true }: ICaptchaProps) => {
  const showCaptchaDefault = isCaptchaEnabledFromEnv && isEnabled;
  const [isValidated, setIsValidated] = useState(!showCaptchaDefault);
  const [apiToken, setApiToken] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [screenName, setScreenName] = useState<string>("None");

  useEffect(() => {
    EventEmitter.emit(AUTH_TOKEN_TOPIC, { token: null });
  }, []);

  useEffect(() => {
    if (isValidated && !apiToken && !loading) {
      setLoading(true);
      getToken()
        .then((token) => {
          if (!token) {
            throw new GeneralError("getTokenApi", { message: "Token not found" });
          }
          setApiToken(token);
          EventEmitter.emit(AUTH_TOKEN_TOPIC, { token });
          setScreenName("Success");
          setLoading(false);
        })
        .catch((err) => {
          let errMsg = "";
          console.error(err);
          if (err instanceof GeneralError) {
            errMsg = err.data?.message;
          }
          errMsg = errMsg || `Token api failed ${err?.message || ""}`;
          notify.error({ message: errMsg });
          setScreenName("Error");
          setLoading(false);
        });
    }
  }, [isValidated]);

  const items = [
    {
      key: "about_us",
      icon: null,
      text: `About us`,
      onClick: () => {
        window.open("https://www.jodo.in/about-us/", "_blank");
      },
    },
    {
      key: "tnc",
      icon: null,
      text: `Terms & Conditions`,
      onClick: () => {
        window.open("https://www.jodo.in/terms-and-conditions/", "_blank");
      },
    },
    {
      key: "privacy",
      icon: null,
      text: `Privacy policy`,
      onClick: () => {
        window.open("https://www.jodo.in/privacy-policy/", "_blank");
      },
    },
    {
      key: "phone",
      icon: <PhoneOutlineIcon fontSize="small" />,
      text: `+91-${SUPPORT_PHONE_NUMBER}`,
      onClick: () => {
        window.open(`tel:${SUPPORT_PHONE_NUMBER}`, "_blank");
      },
    },
    {
      key: "email",
      icon: <EmailIcon fontSize="small" />,
      text: SUPPORT_EMAIL,
      onClick: () => {
        window.open(`mailto:${SUPPORT_EMAIL}`, "_blank");
      },
    },
  ];

  if (screenName === "Success") return <>{children}</>;
  if (screenName === "Error")
    return <InfoPage infographic={null} title="Something went wrong" description="Please reload page to try again " />;

  return (
    <Box height="96vh" sx={{ position: "relative" }}>
      {loading && (
        <Backdrop sx={{ color: "#fff", zIndex: 100 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <LoginLayout
        produceName="Payment Page"
        subTitle="Pay your fees with ease using our omni-channel platform"
        form={
          <div>
            <Typography variant="h4" fontWeight={"700"} gutterBottom>
              Get started !
            </Typography>
            <Typography variant="subtitle1" fontWeight={"300"} gutterBottom>
              Complete the captcha to start paying your fees
            </Typography>
            <div style={Styles.captchaClick}>
              {!apiToken ? (
                <Captcha
                  sitekey={Env.REACT_APP_CAPTCHA_SITE_KEY}
                  onChange={() => {
                    setIsValidated(true);
                  }}
                />
              ) : null}
            </div>
          </div>
        }
        footer={
          <Footer
            items={items}
            itemsProps={{
              sx: {
                justifyContent: "flex-start",
                color: "GrayText",
              },
            }}
          />
        }
      />
    </Box>
  );
};

export default CaptchaPage;
