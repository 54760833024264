import { ApiError } from "@jodo-tech/ui-kit-common/utils/error";

import { EndpointBase } from "../../helper/constants";
import { Env } from "../../helper/env";
import { mdApi, payApi } from "../../vendors/axios";
import { Sentry } from "../../vendors/sentry";
import { PaymentPage } from "./../../widget/payment-page";

const MakePaymentContainer = ({
  paymentPageId,
  orderId,
  callbackUrl,
}: {
  paymentPageId: string;
  orderId?: string;
  callbackUrl?: string;
}) => {
  return (
    <PaymentPage
      context={{
        mutation: {
          createOrder: async ({ payload }: any) => {
            try {
              const response = await payApi.post(
                `${EndpointBase.pay.apiV1Client}/payment_page/create_order`,
                payload,
                {},
              );
              const result = response?.data;
              return result;
            } catch (error: any) {
              throw new ApiError("PaymentPage.PaymentPageApis.createOrder", error?.response?.data);
            }
          },
        },
        query: {
          getCollectorGrades: async ({ collectorId, params }: any) => {
            try {
              const response = await mdApi.get(`${EndpointBase.md.apiV1Internal}/collector/${collectorId}/grade`, {
                params,
              });
              const result = response?.data;
              return result;
            } catch (error: any) {
              throw new ApiError("PaymentPage.PaymentPageApis.getCollectorGrades", error?.response?.data);
            }
          },
          getCollectorStreams: async ({ collectorId, params }: any) => {
            try {
              const response = await mdApi.get(`${EndpointBase.md.apiV1Internal}/collector/${collectorId}/stream`, {
                params,
              });
              const result = response?.data;
              return result;
            } catch (error: any) {
              throw new ApiError("PaymentPage.PaymentPageApis.getCollectorStream", error?.response?.data);
            }
          },
          getCollectorAY: async ({ collectorId, params }: any) => {
            try {
              const response = await mdApi.get(
                `${EndpointBase.md.apiV1Internal}/collector/${collectorId}/academic_year`,
                {
                  params,
                },
              );
              const result = response?.data;
              return result;
            } catch (error: any) {
              throw new ApiError("PaymentPage.PaymentPageApis.getCollectorAY", error?.response?.data);
            }
          },
          getPaymentPage: async ({ paymentPageId, orderId, params }: any) => {
            try {
              const response = await payApi.get(`${EndpointBase.pay.apiV1Client}/payments-page`, {
                params: { page_id: paymentPageId, ...(orderId ? { order: orderId } : {}), ...params },
              });
              const result = response?.data;
              return result;
            } catch (error: any) {
              throw new ApiError("PaymentPage.PaymentPageApis.getPaymentPage", error?.response?.data);
            }
          },
          getPaymentReceipt: async ({ params }: any) => {
            try {
              const response = await payApi.get(`${EndpointBase.pay.apiV1Client}/payment_page/download_receipt`, {
                params,
                responseType: "arraybuffer",
              });
              const result = response?.data;
              return result;
            } catch (error: any) {
              throw new ApiError("PaymentPage.PaymentPageApis.getPaymentReceipt", error?.response?.data);
            }
          },

          getPaymentStatus: async ({ params }: any) => {
            try {
              const response = await payApi.get(`${EndpointBase.pay.apiV1Client}/payment_page/check_status`, {
                params,
              });
              const result = response?.data;
              return result;
            } catch (error: any) {
              throw new ApiError("PaymentPage.PaymentPageApis.getPaymentStatus", error?.response?.data);
            }
          },
        },
        containerHeight: "100vh",
        tracker: {
          analyticsTracker: () => {
            return;
          },
          captureException: (err: any) => {
            Sentry.captureException(err);
          },
          captureErrMessage: (message: string) => {
            Sentry.captureMessage(message);
          },
        },
        callbackUrl: callbackUrl,
        orderId: orderId,
        tazapaySdkEnv: Env.REACT_APP_TAZAPAY_SDK_ENV,
        cashfreeSdkEnv: Env.REACT_APP_CASHFREE_SDK_ENV,
      }}
      paymentPageId={paymentPageId}
    />
  );
};

export default MakePaymentContainer;
