// using as const in the end to let Typescript infer types and avoid modification to this object
export const Env = {
  REACT_APP_CAPTCHA_SITE_KEY: process.env.REACT_APP_CAPTCHA_SITE_KEY || "", // last ! is to tell typescript that this will be available
  REACT_APP_CAPTCHA_PAGE_ENABLED: process.env.REACT_APP_CAPTCHA_PAGE_ENABLED,

  REACT_APP_AUTH_MODE: process.env.REACT_APP_AUTH_MODE === "STATIC_AUTH" ? "STATIC_AUTH" : "GATEWAY_TOKEN",
  REACT_APP_STATIC_AUTH_CONFIG: JSON.parse(process.env.REACT_APP_STATIC_AUTH_CONFIG?.trim?.() || "{}"),

  REACT_APP_TOKEN_API_BASE_URL: process.env.REACT_APP_TOKEN_API_BASE_URL,
  REACT_APP_MD_API_BASE_URL: process.env.REACT_APP_MD_API_BASE_URL,
  REACT_APP_PAY_API_BASE_URL: process.env.REACT_APP_PAY_API_BASE_URL,

  REACT_APP_SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_ENV: process.env.REACT_APP_SENTRY_ENV,
  REACT_APP_SENTRY_SERVICE: process.env.REACT_APP_SENTRY_SERVICE,
  REACT_APP_VERSION: process.env.REACT_APP_VERSION,
  REACT_APP_TAZAPAY_SDK_ENV: process.env.REACT_APP_TAZAPAY_SDK_ENV === "PROD" ? "PROD" : "DEV",
  REACT_APP_CASHFREE_SDK_ENV: process.env.REACT_APP_CASHFREE_SDK_ENV === "PROD" ? "PROD" : "DEV",
} as const;
