import Select from "@jodo-tech/ui-kit-common/design/Select";
import Modal from "@jodo-tech/ui-kit-common/design/Modal";
import Typography from "@jodo-tech/ui-kit-common/design/Typography";
import Box from "@jodo-tech/ui-kit-common/design/Box";
import { countriesList } from "./PaymentPage.country";
import { useState } from "react";

const CountrySelectModal = ({ onClose, onOK }) => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const clickHandlers = {
    close: () => {
      setSelectedCountry(null);
      onClose();
      //setShowCountrySelectModal(false);
    },
    ok: () => {
      onOK({ selectedCountry });

      //ClickHandlers.onPayNowClick(renderJSON, { isInternationalMode: true });
      //setShowCountrySelectModal(false);
    },
  };

  return (
    <Modal
      open={true}
      title={"Select country"}
      onClose={clickHandlers.close}
      okButton={{
        children: "Continue to pay",
        disabled: !selectedCountry,
        onClick: clickHandlers.ok,
      }}
      cancelButton={{
        children: "Cancel",
        onClick: clickHandlers.close,
      }}
    >
      <Typography variant="body2" gutterBottom>
        This will be used to show the relevant information regarding available banks and payment methods
      </Typography>
      <Select
        sx={{ my: 3 }}
        value={selectedCountry?.value}
        placeholder="Select country"
        options={countriesList}
        size="small"
        onChange={(e, option) => {
          setSelectedCountry(option);
        }}
        renderOption={(props, option) => (
          <Box component="li" key={option.value} sx={{ padding: "0.5rem" }} {...props}>
            <Typography variant="body2">{option.flag}</Typography>
            <Typography variant="body2" sx={{ pl: "4px" }}>
              {option.label}
            </Typography>
          </Box>
        )}
      />
    </Modal>
  );
};

export default CountrySelectModal;
