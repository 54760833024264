import Box from "@jodo-tech/ui-kit-common/design/Box";
import Typography from "@jodo-tech/ui-kit-common/design/Typography";
import Footer from "@jodo-tech/ui-kit-common/design/Footer";
import { JodoLogo } from "@jodo-tech/ui-kit-common/icons/JodoLogo";

const PaymentPageFooter = ({ itemsProps, iconProps, logoSize, poweredBySx }) => {
  const items = [
    {
      key: "about_us",
      icon: null,
      text: `About us`,
      onClick: () => {
        window.open("https://www.jodo.in/about-us/", "_blank");
      },
    },
    {
      key: "tnc",
      icon: null,
      text: `Terms & Conditions`,
      onClick: () => {
        window.open("https://www.jodo.in/terms-and-conditions/", "_blank");
      },
    },
    {
      key: "privacy",
      icon: null,
      text: `Privacy policy`,
      onClick: () => {
        window.open("https://www.jodo.in/privacy-policy/", "_blank");
      },
    },
  ];
  return (
    <Box mt={5}>
      <Box display="flex" alignItems="center" mb={3} sx={poweredBySx}>
        <Typography variant="body2" sx={{ overflowWrap: "anywhere", mr: 1 }}>
          Powered by
        </Typography>
        <JodoLogo size={logoSize || 0.7} />
      </Box>
      <Footer
        items={items}
        // onClick={() => {}}
        itemsProps={
          itemsProps || {
            sx: {
              justifyContent: "flex-start",
              color: "GrayText",
            },
          }
        }
        iconProps={
          iconProps || {
            sx: {
              marginRight: 0,
            },
          }
        }
      />
    </Box>
  );
};

export default PaymentPageFooter;
