import Button from "@jodo-tech/ui-kit-common/design/Button";
import Grid from "@jodo-tech/ui-kit-common/design/Grid";
import Dialog from "@jodo-tech/ui-kit-common/design/Dialog";
import Paper from "@jodo-tech/ui-kit-common/design/Paper";
import Box from "@jodo-tech/ui-kit-common/design/Box";
import BottomNavigation from "@jodo-tech/ui-kit-common/design/BottomNavigation";
import CircularProgress from "@jodo-tech/ui-kit-common/design/CircularProgress";

import PaymentPage from "./PaymentPage";
import { PaymentPageConfigTypes } from "../../configs/types";
import { useContext, useEffect, useState } from "react";
import { notify } from "@jodo-tech/ui-kit-common/design/Notify";
import { PaymentPageConfigContext } from "../../context/paymentPageConfig.context";
import { ApiDataModify } from "../../helpers/api.util";

const Footer = ({ onDiscardPage, onBackToEdit }) => {
  const { paymentPageId } = useContext(PaymentPageConfigContext);
  return (
    <Grid container justifyContent="space-between" alignItems="center" direction="row" wrap="nowrap">
      <Grid container justifyContent="flex-start" item xs="auto" md="auto" wrap="nowrap">
        <Button variant="outlined" onClick={onDiscardPage}>
          Discard {paymentPageId ? "changes" : "page"}
        </Button>
      </Grid>
      <Grid container item direction="row" spacing={3} justifyContent="flex-end" xs="auto" md="auto" wrap="nowrap">
        <Grid item wrap="nowrap">
          <Button variant="contained" color="primary" onClick={onBackToEdit}>
            Back to edit
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const PreviewPage = ({ data, open, actions, containerHeight }) => {
  const { query, collectorId } = useContext(PaymentPageConfigContext);
  const [collectorData, setCollectorData] = useState({});
  const [dataForPreview, setDataForPreview] = useState({});
  const [systemFieldOptionsMap, setSystemFieldOptionsMap] = useState({});
  const [loading, setLoading] = useState(true);

  const systemFieldApis = {
    meta: async () => {
      const res = await query?.getCollectorMeta?.({ collectorId });
      setCollectorData(res);
    },
    grade: async ({ fieldUIKey, params }) => {
      if (!systemFieldOptionsMap[fieldUIKey]) {
        const result = await query?.getCollectorGrades?.({
          collectorId,
          params,
        });
        const grades = ApiDataModify.getCollectorGrades({ result });
        setSystemFieldOptionsMap((prev) => ({ ...prev, [fieldUIKey]: grades }));
      }
    },
    stream: async ({ fieldUIKey, params }) => {
      if (!systemFieldOptionsMap[fieldUIKey]) {
        const result = await query?.getCollectorStreams?.({
          collectorId,
          params,
        });
        const streams = ApiDataModify.getCollectorStreams({ result });
        setSystemFieldOptionsMap((prev) => ({
          ...prev,
          [fieldUIKey]: streams,
        }));
      }
    },
    academicYear: async ({ fieldUIKey, params }) => {
      if (!systemFieldOptionsMap[fieldUIKey]) {
        const result = await query?.getCollectorAcademicYears({
          collectorId,
          params,
        });
        const AY = ApiDataModify.getCollectorAY({ result });
        setSystemFieldOptionsMap((prev) => ({ ...prev, [fieldUIKey]: AY }));
      }
    },
  };

  const userData = data?.[PaymentPageConfigTypes.PAGES.USER] || [];
  const reducedUserData = userData?.reduce((acc, current) => {
    return [...acc, ...current?.children];
  }, []);

  const systemDBKeys = reducedUserData
    ?.filter((item) => item?.variant === "system" && item?.payload_key)
    ?.map((item) => {
      if (item.payload_key.includes("grade")) {
        return ["grade", item.payload_key];
      }
      if (item.payload_key.includes("board")) {
        return ["stream", item.payload_key];
      }
      if (item.payload_key.includes("academic_year_start")) {
        return ["academicYear", item.payload_key];
      }

      return null;
    })
    .filter((item) => !!item);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const systemFieldPromises = (systemDBKeys || [])?.map(([fieldUIKey, payloadKey]) =>
        systemFieldApis[fieldUIKey]({ fieldUIKey: payloadKey, params: {} }),
      );

      const metaPromise = systemFieldApis["meta"]();

      const promises = [metaPromise, ...systemFieldPromises];

      await Promise.all(promises).catch(() => {
        notify.error({
          message: "Something went wrong while fetching system fields",
        });
      });
      setLoading(false);
    })().catch(() => {
      setLoading(false);
      notify.error({
        message: "Something went wrong while fetching system fields",
      });
    });
  }, []);

  console.log({ data });
  useEffect(() => {
    if (!loading) {
      const modifiedData = {
        id: data?.[PaymentPageConfigTypes.PAGES.META]?.id,
        label: data?.[PaymentPageConfigTypes.PAGES.META]?.name,
        collector: {
          id: collectorData?.id,
          name: collectorData?.name,
          logo_url: collectorData?.logo_url,
        },
        description: data?.[PaymentPageConfigTypes.PAGES.META]?.description,
        support_details: {
          phone_number: data?.[PaymentPageConfigTypes.PAGES.META]?.phone_number,
          email: data?.[PaymentPageConfigTypes.PAGES.META]?.email,
        },
        data_items: reducedUserData.map((item) => ({
          label: item?.label || item?.payload_label || "Custom field",
          type: item?.variant,
          mandatory: !item?.optional,
          input_type: item?.field_type === "dropdown" ? "object" : item?.field_type,
          payload: {
            value: item?.value,
          },
          is_editable: true,
          options: {
            data: item?.dropdown_list_values ||
              systemFieldOptionsMap[item?.payload_key] || [
                {
                  internalId: "-1",
                  value: "No Options Present",
                  label: "No Options Present",
                },
              ],
          },
          ui_key: item?.payload_key,
        })),
        payment_items: data?.[PaymentPageConfigTypes.PAGES.PAYMENT]?.map((item) => ({
          label: item?.label || "Custom label",
          type: item?.variant,
          mandatory: !item?.optional,
          input_type: item?.field_type === "dropdown" ? "object" : item?.field_type,
          options: {
            data: item?.amount_config || [
              {
                internalId: "-1",
                amount: "No Options Present",
                label: "No Options Present",
              },
            ],
          },
          payload: {
            value: item?.field_type === "dropdown" ? null : item?.amount_config?.[0]?.amount,
          },
          is_editable: item?.field_type === "fixed" ? false : true,
        })),
      };
      setDataForPreview(modifiedData);
    }
  }, [loading]);

  if (loading) {
    return (
      <Box display="grid" sx={{ placeItems: "center", height: "100vh", background: "red" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Dialog fullScreen keepMounted open={open} scroll="paper">
      <Box>
        <PaymentPage
          context={{
            mutation: {
              createOrder: () =>
                new Promise(() => {
                  return undefined;
                }),
            },
            query: {
              getCollectorGrades: () =>
                new Promise(() => {
                  return undefined;
                }),
              getCollectorStreams: () =>
                new Promise(() => {
                  return undefined;
                }),
              getCollectorAY: () =>
                new Promise(() => {
                  return undefined;
                }),
              getPaymentPage: () =>
                new Promise(() => {
                  return undefined;
                }),
              getPaymentReceipt: () =>
                new Promise(() => {
                  return undefined;
                }),
              getPaymentStatus: () =>
                new Promise(() => {
                  return undefined;
                }),
            },
            containerHeight: containerHeight || "100vh",
            tracker: {
              analyticsTracker: () => {
                return undefined;
              },
              captureException: () => {
                return undefined;
              },
              captureErrMessage: () => {
                return undefined;
              },
            },
            tazapaySdkEnv: "DEV", // we want this to show preview page
            cashfreeSdkEnv: "DEV", // we want this to show preview page
          }}
          previewData={dataForPreview}
          paymentPageId={"-1"}
        />
        <Paper
          elevation={3}
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1,
          }}
        >
          <BottomNavigation
            showLabels
            sx={{
              px: {
                xs: 2,
                md: 5,
              },
              py: 2,
            }}
          >
            <Footer onBackToEdit={actions.onBackToEdit} onDiscardPage={actions.onDiscardPage} />
          </BottomNavigation>
        </Paper>
      </Box>
    </Dialog>
  );
};

export default PreviewPage;
