import ErrorBoundary from "./ErrorBoundary";
import AllRoutes from "./Lazy";

export default function App() {
  return (
    <ErrorBoundary>
      <AllRoutes />
    </ErrorBoundary>
  );
}
