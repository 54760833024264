import React from "react";
import Dialog from "@jodo-tech/ui-kit-common/design/Dialog";
import DialogTitle from "@jodo-tech/ui-kit-common/design/DialogTitle";
import IconButton from "@jodo-tech/ui-kit-common/design/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { isMobile, tazapayModalWidth } from "./PaymentPage.utils";

const TazaPayModal = ({ visible, onClose }) => {
  return (
    <Dialog
      open={true}
      // onClose={() => setShowTazapayModal(false)}

      // display:none is intentionally, as we always want to render the content of the dialog
      // so that whenever a payment is initiated,
      // the payment sdk makes sure to put the content on the div with that id.
      sx={{ display: visible ? undefined : "none" }}
      disableScrollLock
      fullScreen={isMobile}
      PaperProps={{
        sx: {
          minWidth: `${tazapayModalWidth}px`,
          "#tz-checkout": {
            flexDirection: "column",
          },
          borderRadius: "16px",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        International Payment
        <IconButton onClick={onClose} aria-label="close" sx={{ p: 0 }}>
          <CloseIcon color="primary" />
        </IconButton>
      </DialogTitle>
      {/* if you are changing this id, then look for impacted areas */}
      {/* this id should always be rendering over the the dom */}
      <div id="tz-checkout" />
    </Dialog>
  );
};

export default TazaPayModal;
