import * as SentryLib from "@sentry/react";
import { CaptureContext } from "@sentry/types";

import { Env } from "../helper/env";

const shouldTrackError = Env.REACT_APP_SENTRY_ENV !== "local";

export const Sentry = {
  init: () => {
    if (shouldTrackError) {
      SentryLib.init({
        dsn: Env.REACT_APP_SENTRY_DSN,
        environment: Env.REACT_APP_SENTRY_ENV,
        release: Env.REACT_APP_VERSION,
      });
      SentryLib.setTag("service", Env.REACT_APP_SENTRY_SERVICE);
    }
  },

  captureException: (err: any, context?: CaptureContext) => {
    if (!shouldTrackError) return;
    SentryLib.captureException(err, context);
  },

  captureMessage: (message: string, context?: CaptureContext) => {
    if (!shouldTrackError) return;
    SentryLib.captureMessage(message, context);
  },
};
