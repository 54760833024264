const NotFoundInfographic = () => {
  return (
    <svg width="475" height="164" viewBox="0 0 475 164" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M76.5296 164V32.5212L97.6332 32.9858L38.9605 104.997L33.3947 98.0283H141V130.55H15.7697L0 100.119L82.7911 0H114.562V164H76.5296Z"
        fill="url(#paint0_linear_3864_12956)"
      />
      <path
        d="M410.53 164V32.5212L431.633 32.9858L372.961 104.997L367.395 98.0283H475V130.55H349.77L334 100.119L416.791 0H448.562V164H410.53Z"
        fill="url(#paint1_linear_3864_12956)"
      />
      <path
        d="M303.384 36.2579C328.924 72.6445 320.129 122.851 283.742 148.384C247.356 173.924 197.156 165.129 171.616 128.742C146.076 92.3554 154.871 42.1491 191.258 16.6158C227.651 -8.92438 277.851 -0.12883 303.384 36.2579Z"
        fill="url(#paint2_linear_3864_12956)"
      />
      <path
        d="M292.787 43.6977C314.219 74.2345 306.841 116.354 276.304 137.786C245.768 159.217 203.648 151.839 182.216 121.302C160.785 90.7657 168.17 48.6461 198.7 27.2146C229.236 5.78317 271.363 13.161 292.787 43.6977Z"
        fill="url(#paint3_linear_3864_12956)"
      />
      <path
        d="M232.255 93.5023H229.758L247.204 111.666C249.29 113.855 247.751 117.447 244.741 117.447C243.817 117.447 242.928 117.071 242.278 116.387L221.651 94.9048C220.762 94.0154 220.283 92.784 220.283 91.5525C220.283 88.8502 222.472 86.661 225.175 86.661H232.255C238.276 86.661 243.27 82.2141 244.091 76.399H220.283C218.402 76.399 216.862 74.8597 216.862 72.9783C216.862 71.0969 218.402 69.5577 220.283 69.5577H243.065C241.149 65.5213 237.044 62.7163 232.255 62.7163H220.283C218.402 62.7163 216.862 61.177 216.862 59.2957C216.862 57.4143 218.402 55.875 220.283 55.875H254.49C256.371 55.875 257.91 57.4143 257.91 59.2957C257.91 61.177 256.371 62.7163 254.49 62.7163H246.759C248.401 64.7003 249.632 67.0264 250.351 69.5577H254.49C256.371 69.5577 257.91 71.0969 257.91 72.9783C257.91 74.8597 256.371 76.399 254.49 76.399H251.001C250.111 85.9768 242.038 93.5023 232.255 93.5023Z"
        fill="url(#paint4_linear_3864_12956)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3864_12956"
          x1="70.5"
          y1="0"
          x2="70.5"
          y2="164"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B3B3B3" />
          <stop offset="1" stopColor="#D7D7D7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3864_12956"
          x1="404.5"
          y1="0"
          x2="404.5"
          y2="164"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B3B3B3" />
          <stop offset="1" stopColor="#D7D7D7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3864_12956"
          x1="237.5"
          y1="163"
          x2="237.5"
          y2="2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B3B3B3" />
          <stop offset="1" stopColor="#D7D7D7" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3864_12956"
          x1="237.503"
          y1="150.051"
          x2="237.503"
          y2="14.9494"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E6E6E6" />
          <stop offset="0.932874" stopColor="#E4E4E4" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_3864_12956"
          x1="237.386"
          y1="55.875"
          x2="237.386"
          y2="117.447"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B3B3B3" />
          <stop offset="1" stopColor="#D7D7D7" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NotFoundInfographic;
