import BottomNavigation from "@jodo-tech/ui-kit-common/design/BottomNavigation";
import Card from "@jodo-tech/ui-kit-common/design/Card";
import Grid from "@jodo-tech/ui-kit-common/design/Grid";
import Paper from "@jodo-tech/ui-kit-common/design/Paper";

function FCSContainer({ leftComponent, rightComponent, bottomComponent, containerHeight }) {
  return (
    <Grid
      container
      sx={{
        position: "relative",
      }}
    >
      <Grid container item>
        <Grid
          item
          xs
          md
          sx={{
            height: { md: `${containerHeight}`, xs: "auto" },
            overflow: "scroll",
          }}
        >
          {leftComponent}
        </Grid>
        {rightComponent && (
          <Grid
            item
            ml={5}
            xs={12}
            md={7}
            sx={{
              height: { md: `${containerHeight}`, xs: "auto" },
              position: { md: "sticky", xs: "relative" },
              bottom: { md: "0px", xs: "auto" },
              top: { md: "0px", xs: "auto" },
              right: { md: "0px", xs: "auto" },
              background: "linear-gradient(269.06deg, #3360B1 1.66%, #2C67D3 98.56%)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: { md: "120px 0px", xs: "0px 0px" },
            }}
          >
            <Card
              elevation={5}
              sx={{
                position: { md: "absolute", xs: "relative" },
                right: { md: "60px" },
                left: "0px",
                top: { md: "40px" },
                bottom: { md: "40px" },
                backgroundColor: "white",
                boxShadow: "-2px 1px 18px rgba(0, 0, 0, 0.05)",
                overflowY: "scroll",
                borderRadius: { md: "16px", xs: 0 },
              }}
            >
              {rightComponent}
            </Card>
          </Grid>
        )}
      </Grid>

      <Paper
        sx={{
          position: { md: "sticky" },
          bottom: 0,
          left: 0,
          right: 0,
          width: "100%",
          zIndex: 1,
        }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          sx={{
            px: {
              xs: 2,
              md: 5,
            },
            py: 2,
          }}
        >
          {bottomComponent}
        </BottomNavigation>
      </Paper>
    </Grid>
  );
}

export default FCSContainer;
