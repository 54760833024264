import Box from "@jodo-tech/ui-kit-common/design/Box";
import Button from "@jodo-tech/ui-kit-common/design/Button";
import Checkbox from "@jodo-tech/ui-kit-common/design/Checkbox";
import FormControlLabel from "@jodo-tech/ui-kit-common/design/FormControlLabel";
import Grid from "@jodo-tech/ui-kit-common/design/Grid";
import MenuItem from "@jodo-tech/ui-kit-common/design/MenuItem";
import IconButton from "@jodo-tech/ui-kit-common/design/IconButton";
import TextField from "@jodo-tech/ui-kit-common/design/TextField";
import Typography from "@jodo-tech/ui-kit-common/design/Typography";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import SelectItems from "../../../design/SelectItems";
import QueueOutlinedIcon from "@mui/icons-material/QueueOutlined";
import FieldOptionalSwitcher from "../../../design/FieldOptionalSwitcher/FieldOptionalSwitcher.molecule";
import CircularProgress from "@jodo-tech/ui-kit-common/design/CircularProgress";
import Add from "@mui/icons-material/Add";
import { nanoid } from "@jodo-tech/ui-kit-common/utils/nanoId";
import { useContext, useEffect, useState } from "react";
import { PaymentPageConfigTypes } from "../../../configs/types";
import { notify } from "@jodo-tech/ui-kit-common/design/Notify";
import { PaymentPageConfigContext } from "../../../context/paymentPageConfig.context";
import { DEFAULT_ERROR } from "../../../configs/constant";
import { ApiDataModify } from "../../../helpers/api.util";
import FeeRowFields from "./PaymentConfig.RowFields";
import { ApiError } from "@jodo-tech/ui-kit-common/utils/error";
import { payPageSx } from "../Common/Common.utils";

const Title = ({ values, items, handlerFunctions, totalItems }) => {
  return (
    <Grid
      container
      sx={{
        px: 3,
        pt: 2,
        pb: 1,
        position: "sticky",
        top: 0,
        background: "white",
        zIndex: 2,
        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.05), 0px 1px 10px rgba(0, 0, 0, 0.1);",
      }}
    >
      <Grid container xs={6} justifyContent="flex-start">
        <Typography variant="subtitle1" sx={{ ...payPageSx.blackText, my: "auto" }}>
          <b>Fee details</b>
        </Typography>
      </Grid>
      <Grid container justifyContent="flex-end" xs={6} textAlign="end">
        <>
          <Grid item xs="auto" mr={4}>
            <Button variant="text" startIcon={<Add color="primary" />} onClick={handlerFunctions.onAddCustom}>
              Add custom
            </Button>
          </Grid>
          <Grid item xs="auto">
            <SelectItems
              buttonTitle="Add component"
              labelKey="label"
              valueKey="internalId"
              values={values}
              items={items}
              onChange={(fields) => handlerFunctions.onAddSystemField({ items: fields })}
              totalItems={totalItems}
            />
          </Grid>
        </>
      </Grid>
    </Grid>
  );
};

const currentPage = PaymentPageConfigTypes.PAGES.PAYMENT;

const PaymentForm = ({ data, onChange, errors }) => {
  const { query, collectorId, tracker } = useContext(PaymentPageConfigContext);
  const [componentItems, setComponentItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fieldItems, setFieldItems] = useState([]);
  const [bulkAction, setBulkAction] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [dragIndex, setDragIndex] = useState(-1);
  const [dragOverIndex, setDragOverIndex] = useState(-1);

  useEffect(() => {
    query
      .getCollectorComponents({ collectorId })
      .then((result) => {
        const res = ApiDataModify.getCollectorComponents({ result });
        setComponentItems(res);
        if (data?.length) {
          setFieldItems(data);
        }
        setLoading(false);
      })
      .catch((err) => {
        notify.error({ message: err?.data?.message || DEFAULT_ERROR });
        console.error(err);
        if (!(err instanceof ApiError)) {
          tracker?.captureException?.(err);
        }
        setLoading(false);
      });
  }, []);

  const handlerFunctions = {
    onAddCustom: () => {
      const updatedItems = [
        ...fieldItems,
        {
          internalId: nanoid(),
          collector_component_id: null,
          label: "",
          field_type: "fixed",
          variant: "custom",
          amount_config: [
            {
              amount: "",
              internalId: null,
              label: null,
            },
          ],
          optional: false,
          item_priority: fieldItems?.length + 1,
        },
      ];
      setFieldItems(updatedItems);
      onChange({ currentPage, data: [...updatedItems] });
    },
    onRemoveField: ({ internalId }) => {
      const updatedItems = fieldItems?.filter((item) => item.internalId !== internalId);
      const updatedItemsWithPriorityChanged = updatedItems.map((user, index) => {
        return { ...user, item_priority: index };
      });
      setFieldItems(updatedItemsWithPriorityChanged);
      onChange({ currentPage, data: updatedItemsWithPriorityChanged });
    },
    onAddSystemField: ({ items }) => {
      const customFields = fieldItems?.filter((item) => item.variant === "custom");
      const updatedItems = [...customFields, ...items];
      const updatedItemsWithPrioritySorted = updatedItems.sort((a, b) => {
        return a?.item_priority - b?.item_priority;
      });
      const updatedItemsWithPriorityChanged = updatedItemsWithPrioritySorted.map((component, index) => {
        return { ...component, item_priority: index };
      });

      setFieldItems(updatedItemsWithPriorityChanged);
      onChange({ currentPage, data: [...updatedItemsWithPriorityChanged] });
    },
    onEditField: ({ internalId, formData }) => {
      const updatedItems = fieldItems?.map((item) => {
        if (item.internalId === internalId) {
          return { ...item, ...formData };
        }
        return item;
      });
      setFieldItems(updatedItems);
      onChange({ currentPage, data: [...updatedItems] });
    },
    onClickBulkAction: () => {
      setBulkAction({
        field_type: "NA",
        optional: "NA",
      });
    },
    onCancelBulkAction: () => {
      setSelectedItems([]);
      setBulkAction(null);
    },
    onBulkSelectToggle: ({ formItem }) => {
      const updatedItems = selectedItems.includes(formItem.internalId)
        ? selectedItems.filter((internalId) => internalId !== formItem.internalId)
        : [...selectedItems, formItem.internalId];
      setSelectedItems(updatedItems);
    },
    onBulkSelectAllToggle: () => {
      const updatedItems = selectedItems.length === fieldItems.length ? [] : fieldItems.map((item) => item.internalId);
      setSelectedItems(updatedItems);
    },
    onEditBulkField: ({ formData }) => {
      setBulkAction({ ...bulkAction, ...formData });
    },
    onSaveBulkAction: () => {
      if (!(selectedItems?.length || 0)) {
        notify.warning({ message: "Please select at least one item" });
        return;
      }
      //filter out NA entries
      const tempBulkAction = { ...bulkAction };
      Object.entries(tempBulkAction).forEach(([key, value]) => {
        if (value === "NA") {
          delete tempBulkAction[key];
        }
      });

      const updatedItems = fieldItems.map((item) => {
        if (selectedItems.includes(item.internalId)) {
          return { ...item, ...tempBulkAction };
        }
        return item;
      });
      setFieldItems(updatedItems);
      onChange({ currentPage, data: [...updatedItems] });
      setBulkAction(null);
      setSelectedItems([]);
    },
  };

  const dragFunctions = {
    handleDragStart: (e, index) => {
      setDragIndex(index);
      e.target.style.opacity = 0.4;
    },

    handleDrop: (e, index) => {
      e?.stopPropagation?.();
      const newList = [...fieldItems];
      // change the position of the dragged item to the position of the dropped item
      if (dragIndex === index) return;
      if (dragIndex > index) {
        newList.splice(index, 0, newList[dragIndex]);
        newList.splice(dragIndex + 1, 1);
      } else {
        newList.splice(index + 1, 0, newList[dragIndex]);
        newList.splice(dragIndex, 1);
      }
      newList.forEach((item, index) => {
        item.item_priority = index;
      });
      setFieldItems([...newList]);
      onChange({ currentPage, data: [...newList] });
    },
    handleDragOver: (e, index) => {
      e?.preventDefault?.();
      if (dragOverIndex !== index) {
        setDragOverIndex(index);
      }
    },
    handleDragEnd: (e) => {
      e?.stopPropagation?.(); // stops the browser from redirecting.
      setDragIndex(-1);
      setDragOverIndex(-1);
      e.target.style.opacity = 1;
    },
    getStyle: (index) => {
      if (dragOverIndex !== -1 && dragIndex !== -1) {
        if (index === dragOverIndex && index > dragIndex) {
          // highlighting the hovered row
          return {
            cursor: "move",
            "&:after": {
              content: '""',
              display: "flex",
              width: "100%",
              height: "60px",
              border: "2px solid #ddd",
            },
          };
        }
        if (index === dragOverIndex && index < dragIndex) {
          // highlighting the hovered row
          return {
            cursor: "move",
            "&:before": {
              content: '""',
              display: "flex",
              width: "100%",
              height: "60px",
              border: "2px solid #ddd",
            },
          };
        }
        // de-highlighting the un-hovered row
        return { cursor: "move", mb: 1 };
      }
      return { mb: 1 };
    },
  };

  const disableBulkActionButton = fieldItems.length < 2;
  const disableSaveBulkAction =
    !selectedItems?.length || !Object.values(bulkAction).filter((item) => item !== "NA").length;

  if (loading) {
    return (
      <Box display="grid" sx={{ placeItems: "center", height: "100vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Title
        values={fieldItems.filter((item) => item?.variant === "system")}
        items={(componentItems || []).filter((item) => item?.variant === "system")}
        handlerFunctions={handlerFunctions}
        totalItems={fieldItems.length}
      />
      <Box
        p={3}
        sx={{
          overflowY: "scroll",
        }}
      >
        <Button
          variant="text"
          startIcon={<QueueOutlinedIcon />}
          onClick={handlerFunctions.onClickBulkAction}
          disabled={disableBulkActionButton}
          sx={{
            background: bulkAction && "#EFF5FF",
            mb: bulkAction ? 0 : 1,
          }}
        >
          Bulk Action
        </Button>
        {!!bulkAction && (
          <Grid
            container
            pt={4}
            px={1.5}
            mb={2}
            sx={{
              background: "#EFF5FF",
              borderRadius: "8px",
              borderTopLeftRadius: "0px",
            }}
          >
            <Grid container item xs={12} justifyContent="space-between">
              <Grid item xs={4}>
                <FormControlLabel
                  label={
                    <Typography variant="body2" sx={{ ...payPageSx.blackText }}>
                      Select All
                    </Typography>
                  }
                  control={
                    <Checkbox
                      size="small"
                      checked={selectedItems.length === fieldItems.length}
                      onChange={handlerFunctions.onBulkSelectAllToggle}
                    />
                  }
                />
              </Grid>
              <Grid item xs={3} mr="auto">
                <TextField
                  select
                  size="small"
                  label="Fee type"
                  fullWidth
                  InputLabelProps={{
                    sx: payPageSx.inputFontLarge,
                  }}
                  InputProps={{
                    sx: payPageSx.inputFontLarge,
                  }}
                  value={bulkAction?.field_type || "NA"}
                  onChange={(e) =>
                    handlerFunctions.onEditBulkField({
                      formData: { field_type: e.target.value },
                    })
                  }
                >
                  <MenuItem value={"NA"}>
                    <Typography variant="body2" sx={{ ...payPageSx.blackText }}>
                      NA
                    </Typography>
                  </MenuItem>
                  <MenuItem value={"fixed"}>
                    <Typography variant="body2" sx={{ ...payPageSx.blackText }}>
                      Fixed
                    </Typography>
                  </MenuItem>
                  <MenuItem value={"custom"}>
                    <Typography variant="body2" sx={{ ...payPageSx.blackText }}>
                      Custom
                    </Typography>
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  select
                  size="small"
                  label="Optional"
                  fullWidth
                  InputLabelProps={{
                    sx: payPageSx.inputFontLarge,
                  }}
                  InputProps={{
                    sx: payPageSx.inputFontLarge,
                  }}
                  value={`${bulkAction?.optional || "NA"}`}
                  onChange={(e) =>
                    handlerFunctions.onEditBulkField({
                      formData:
                        e.target.value === "NA"
                          ? { optional: "NA" }
                          : {
                              optional: e.target.value === "true" ? true : false,
                            },
                    })
                  }
                >
                  <MenuItem value={"NA"}>
                    <Typography variant="body2" sx={{ ...payPageSx.blackText }}>
                      NA
                    </Typography>
                  </MenuItem>
                  <MenuItem value={"true"}>
                    <Typography variant="body2" sx={{ ...payPageSx.blackText }}>
                      Yes
                    </Typography>
                  </MenuItem>
                  <MenuItem value={"false"}>
                    <Typography variant="body2" sx={{ ...payPageSx.blackText }}>
                      No
                    </Typography>
                  </MenuItem>
                </TextField>
              </Grid>
            </Grid>
            <Grid container item xs={12} mt={3} justifyContent="flex-end">
              <Grid item xs="auto" mr={5}>
                <Button variant="text" onClick={handlerFunctions.onCancelBulkAction}>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs="auto">
                <Button variant="text" onClick={handlerFunctions.onSaveBulkAction} disabled={disableSaveBulkAction}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}

        {fieldItems.length > 0 ? (
          fieldItems
            .sort((a, b) => {
              return a?.item_priority - b?.item_priority;
            })
            .map((item, index) => (
              <Box
                key={item?.internalId}
                sx={dragFunctions.getStyle(index)}
                draggable
                onDragEnd={(e) => dragFunctions.handleDragEnd(e)}
                onDragStart={(e) => dragFunctions.handleDragStart(e, index)}
                onDrop={(e) => dragFunctions.handleDrop(e, index)}
                onDragOver={(e) => dragFunctions.handleDragOver(e, index)}
              >
                <Grid
                  container
                  item
                  direction="row"
                  alignItems="flex-start"
                  sx={{
                    background: "#FCFCFC",
                    padding: 1,
                    borderRadius: 1,
                    my: 1,
                  }}
                  xs={12}
                  mt={2}
                  flexWrap={"nowrap"}
                >
                  <Grid item xs={"auto"} mr="auto">
                    {bulkAction ? (
                      <Checkbox
                        size="small"
                        onChange={() => {
                          handlerFunctions.onBulkSelectToggle({
                            formItem: item,
                          });
                        }}
                        checked={selectedItems.includes(item?.internalId)}
                      />
                    ) : (
                      <IconButton
                        size="small"
                        sx={{
                          cursor: "move",
                        }}
                        disabled={false}
                      >
                        <MenuIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Grid>
                  <Grid container item xs justifyContent="space-around">
                    <FeeRowFields
                      index={index}
                      isSelectable={!!bulkAction}
                      fieldData={item}
                      onChange={handlerFunctions.onEditField}
                      errors={errors}
                    />
                  </Grid>
                  <Grid container item xs="auto" justifyContent="center" pt={1} ml="auto">
                    <>
                      <FieldOptionalSwitcher
                        onChange={({ checked }) =>
                          handlerFunctions.onEditField({
                            internalId: item?.internalId,
                            formData: { optional: checked },
                          })
                        }
                        data={item}
                        checked={item?.optional}
                        disabled={!!bulkAction}
                      />
                      {!bulkAction && (
                        <IconButton
                          sx={{ p: 0, ml: 1.5 }}
                          disableRipple
                          size="small"
                          onClick={() =>
                            handlerFunctions.onRemoveField({
                              internalId: item?.internalId,
                            })
                          }
                        >
                          <CloseIcon color="primary" fontSize="small" />
                        </IconButton>
                      )}
                    </>
                  </Grid>
                </Grid>
              </Box>
            ))
        ) : (
          <Box display="grid" sx={{ placeItems: "center", height: "50vh" }}>
            <Typography variant="body1" fontWeight="600" color="GrayText">
              Please add at least one payment item to continue
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default PaymentForm;
