/* eslint-disable react-hooks/exhaustive-deps */
import Backdrop from "@jodo-tech/ui-kit-common/design/Backdrop";
import Box from "@jodo-tech/ui-kit-common/design/Box";
import Button from "@jodo-tech/ui-kit-common/design/Button";
import Card from "@jodo-tech/ui-kit-common/design/Card";
import CircularProgress from "@jodo-tech/ui-kit-common/design/CircularProgress";
import ColorfulBox from "@jodo-tech/ui-kit-common/design/ColorfulBox";
import Paper from "@jodo-tech/ui-kit-common/design/Paper";
import Stack from "@jodo-tech/ui-kit-common/design/Stack";
import Typography from "@jodo-tech/ui-kit-common/design/Typography";
import { validate } from "@jodo-tech/ui-kit-common/validation";
import { useRef } from "react";

import { PaymentPageContext } from "../../../context/paymentPage.context";
import { richTextEditorSx } from "../../PaymentPageConfig/Common/Common.utils";
import CashfreeModal from "./CashfreePayment.modal";
import CountrySelectModal from "./PaymentPage.CountrySelectModal";
import PaymentPageFeeSummary from "./PaymentPage.FeeSummary";
import PaymentPageFooter from "./PaymentPage.Footer";
import PaymentPageHeader from "./PaymentPage.Header";
import usePaymentPage from "./PaymentPage.Hook";
import { getOrderIdStatusPage } from "./PaymentPage.OrderStatusPages";
import PaymentPagePaymentForm from "./PaymentPage.PaymentForm";
import TazaPayModal from "./PaymentPage.TazaPayModal";
import PaymentPageUserForm from "./PaymentPage.UserForm";
import PaymentPageUserSummary from "./PaymentPage.UserSummary";
import { getTotalAmount, isMobile, paymentPageValidationSchema, ScreenComponents } from "./PaymentPage.utils";
import Skeleton from "./Skeleton";

const PaymentPageView = ({ previewData, paymentPageId }) => {
  const formAreaRef = useRef(null);
  const {
    renderJSON,
    isSubmittedOnce,
    ClickHandlers,
    isPreview,
    isOrderMode,
    screenName,
    shouldShowLogo,
    paymentStatus,
    paymentTransaction,
    paymentPageApiError,
    systemFieldOptionsMap,
    loading,
    formAreaEl,
    isInternationalPaymentEnabled,
    showCashfreeModal,
    showTazapayModal,
    showCountrySelectModal,
  } = usePaymentPage({
    paymentPageId,
    previewData,
  });

  let errors = {};

  if (isSubmittedOnce) {
    const { error } = validate(renderJSON, paymentPageValidationSchema);
    if (error) {
      errors = error;
    }
  }

  const totalAmount = getTotalAmount(renderJSON);

  const {
    paymentPageDescription,
    paymentPageTitle,
    paymentPageSupportContactDetails,
    userFormFields,
    userPaymentFields,
    collector,
    sendFeeReceipt,
  } = renderJSON;

  const PayNowBtn = (
    <Stack spacing={2}>
      <Button
        fullWidth
        variant="contained"
        onClick={() => {
          ClickHandlers.onPayNowClick(renderJSON, {
            isInternationalMode: false,
          });
        }}
        sx={{ mt: { sx: 2, md: 3 } }}
        disabled={+totalAmount <= 0}
      >
        Pay Now
      </Button>
      {isInternationalPaymentEnabled && (
        <Button variant="outlined" onClick={ClickHandlers.onOpenSelectCountryModal} disabled={+totalAmount <= 0}>
          Pay Using Non-Indian Banks or Cards
        </Button>
      )}
    </Stack>
  );

  const RetryPaymentBtn = (
    <Button
      fullWidth
      variant="contained"
      onClick={() => ClickHandlers.onRetryPaymentClick()}
      sx={{ mt: { sx: 2, md: 3 } }}
    >
      Retry Payment
    </Button>
  );

  const DownloadReceiptBtn = (
    <Button
      fullWidth
      variant="contained"
      onClick={() => ClickHandlers.onDownloadReceiptClick()}
      sx={{ mt: { sx: 2, md: 3 } }}
    >
      Download Receipt
    </Button>
  );

  const UserSummary = screenName.includes(ScreenComponents.PAYMENT_PAGE_USER_SUMMARY) && (
    <>
      {paymentStatus === "error" && (
        <Box mb={3}>
          <Typography
            sx={{
              typography: {
                xs: "body2",
                md: "body1",
                overflowWrap: "anywhere",
              },
            }}
          >
            There was an issue while processing your payment. Please try again or try using a different payment method.
          </Typography>
        </Box>
      )}
      {paymentStatus === "pending" && (
        <Box mb={3}>
          <Typography
            sx={{
              typography: {
                xs: "body2",
                md: "body1",
                overflowWrap: "anywhere",
              },
            }}
          >
            Your transaction is being processed. It might take a few seconds to update the transaction.
          </Typography>
        </Box>
      )}
      <PaymentPageUserSummary
        userFields={userFormFields}
        transactionId={paymentTransaction?.transaction_id}
        systemFieldOptionsMap={systemFieldOptionsMap}
      />
    </>
  );

  const PaymentSummary =
    screenName.includes(ScreenComponents.PAYMENT_PAGE_PAYMENT_SUMMARY) &&
    (isMobile ? (
      <Card
        sx={{
          background: "#ffffff",
          borderRadius: 2,
          p: 3,
          mt: 3,
          width: { sx: "auto", md: "502px" },
          height: "max-content",
        }}
        elevation={3}
      >
        <PaymentPageFeeSummary paymentFields={userPaymentFields} totalAmount={totalAmount} />
      </Card>
    ) : (
      <PaymentPageFeeSummary paymentFields={userPaymentFields} totalAmount={totalAmount} />
    ));

  const HeaderTitle = {
    default: collector?.collectorName,
    success: "Payment Complete",
    error: "Payment Failure",
    pending: "Payment Pending",
  };

  const pageTitle = HeaderTitle[paymentStatus];

  if (paymentPageApiError) {
    return getOrderIdStatusPage(paymentPageApiError);
  }

  const formMargin = 60;
  const formWidth = 502;
  const emptyWidth = formMargin + formWidth + formMargin;
  const formMarginTop = -(formAreaEl?.offsetTop || 0) + formMargin;

  if (loading?.mounting || false) {
    return <Skeleton />;
  }

  const gradientStyles = {
    default: "blue",
    success: "green",
    error: "red",
    pending: "yellow",
  };
  const gradient = gradientStyles[paymentStatus];

  const Actions = (
    <>
      {screenName.includes(ScreenComponents.PAYMENT_PAGE_PAY_NOW_BUTTON) && PayNowBtn}
      {screenName.includes(ScreenComponents.PAYMENT_PAGE_RETRY_PAYMENT_BUTTON) && RetryPaymentBtn}
      {screenName.includes(ScreenComponents.PAYMENT_PAGE_DOWNLOAD_RECEIPT_BUTTON) &&
        sendFeeReceipt &&
        DownloadReceiptBtn}
    </>
  );

  return (
    <>
      <CashfreeModal visible={showCashfreeModal} onClose={ClickHandlers.onCloseCashfreeModal} />
      <TazaPayModal visible={showTazapayModal} onClose={ClickHandlers.onCloseTazaPayModal} />
      <Backdrop sx={{ color: "#fff", zIndex: 999 }} open={loading?.userEvents || false}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        data-name="payment-page-container"
        sx={{
          width: "100vw",
          minHeight: "98vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ColorfulBox
          data-name="header"
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            mb: 2,
          }}
          gradient={gradient}
        >
          <Box
            data-name="actual-headerContent"
            sx={{
              flex: 1,
              pt: { xs: 3, md: 5 },
              px: { xs: 3, md: "60px" },
              pb: { xs: 3, md: 3 },
              mb: { xs: 0, md: 5 },
            }}
          >
            <PaymentPageHeader
              logoUrl={collector?.collectorLogoUrl}
              title={pageTitle}
              pageName={paymentPageTitle}
              phone={paymentPageSupportContactDetails?.phone}
              email={paymentPageSupportContactDetails?.email}
              showLogo={shouldShowLogo}
            />
          </Box>
          <Box
            data-name="empty-header"
            sx={{
              width: `${emptyWidth}px`,
            }}
          ></Box>
        </ColorfulBox>

        <Box
          data-name="bodyContent"
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            marginBottom: isPreview ? "100px" : undefined,
          }}
        >
          {screenName.includes(ScreenComponents.PAYMENT_PAGE_DESCRIPTION) && (
            <Box
              data-name="description-content"
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                px: { xs: 3, md: "60px" },
                mt: { xs: 3, md: 0 },
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {!!paymentPageDescription && paymentStatus === "default" && (
                  <Typography
                    sx={{
                      typography: { xs: "body2", md: "body1" },
                      overflowWrap: "anywhere",
                      whiteSpace: "pre-wrap",
                      ...richTextEditorSx.listStyles,
                    }}
                    dangerouslySetInnerHTML={{ __html: paymentPageDescription }}
                    component={"div"}
                  />
                )}

                {!isMobile && UserSummary}
              </Box>

              {!isMobile && (
                <Box sx={{ marginBottom: isMobile ? "50px" : 1 }}>
                  <PaymentPageFooter />
                </Box>
              )}
            </Box>
          )}

          {screenName.includes(ScreenComponents.PAYMENT_PAGE_FORM_HOLDER) && (
            <Box
              data-name="empty-form-holder"
              sx={{
                width: isMobile ? "100%" : `${emptyWidth}px`,
                display: "flex",
              }}
              ref={formAreaRef}
            >
              <Box
                data-name="actual-form"
                sx={{
                  width: "100%",
                  position: isMobile ? "static" : "sticky",
                  marginTop: isMobile ? undefined : `${formMarginTop}px`,
                  top: isMobile ? undefined : `${formMargin}px`,
                  zIndex: 1,
                  marginBottom: isMobile ? undefined : "40px",
                }}
              >
                <Card
                  sx={{
                    background: "#ffffff",
                    borderRadius: 2,
                    p: 3,
                    width: { sx: "auto", md: "502px" },
                  }}
                  elevation={isMobile ? 0 : 5}
                >
                  {screenName.includes(ScreenComponents.PAYMENT_PAGE_USER_FORM) && (
                    <PaymentPageUserForm
                      userFormFields={userFormFields}
                      onChange={ClickHandlers.onUserFieldChange}
                      errors={errors}
                      systemFieldOptionsMap={systemFieldOptionsMap}
                    />
                  )}

                  {screenName.includes(ScreenComponents.PAYMENT_PAGE_PAYMENT_FORM) && (
                    <PaymentPagePaymentForm
                      isOrderMode={isOrderMode}
                      userPaymentFields={userPaymentFields}
                      onChange={ClickHandlers.onPaymentFieldChange}
                      onSelectionChange={ClickHandlers.onPaymentFieldSelectionChange}
                      onSelectionAllChange={ClickHandlers.onPaymentFieldSelectAllChange}
                      totalAmount={totalAmount}
                      errors={errors}
                    />
                  )}

                  {isMobile && UserSummary}
                  {PaymentSummary}
                  {screenName.includes(ScreenComponents.PAYMENT_PAGE_DESKTOP_NAVIGATION_HOLDER) && <Box>{Actions}</Box>}
                </Card>
              </Box>
            </Box>
          )}
        </Box>

        {isMobile && (
          <Box sx={{ marginBottom: "90px", marginLeft: 3 }}>
            <PaymentPageFooter />
          </Box>
        )}

        {screenName.includes(ScreenComponents.PAYMENT_PAGE_MOBILE_NAVIGATION_HOLDER) && (
          <Paper
            elevation={5}
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1,
              paddingY: 2,
              paddingX: 3,
            }}
          >
            {Actions}
          </Paper>
        )}
        {showCountrySelectModal ? (
          <CountrySelectModal
            onClose={ClickHandlers.onCloseSelectCountryModal}
            onOK={ClickHandlers.onSubmitSelectCountryModal}
          />
        ) : null}
      </Box>
    </>
  );
};

export default function PaymentPage({ context, ...props }) {
  if (!context.tazapaySdkEnv || !context.cashfreeSdkEnv) {
    console.error("SDK env is required");
    return <div>SDK ENV should be present, please contact developer</div>;
  }
  return (
    <PaymentPageContext.Provider value={context}>
      <PaymentPageView {...props} />
    </PaymentPageContext.Provider>
  );
}
