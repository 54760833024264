import Grid from "@jodo-tech/ui-kit-common/design/Grid";
import Typography from "@jodo-tech/ui-kit-common/design/Typography";
import PaymentPageFooter from "./PaymentPage/PaymentPage.Footer";

const InfoPage = ({ infographic, title, description }) => {
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" height="100vh">
      <Grid item mb={5}>
        {infographic}
      </Grid>
      <Grid item mb={3}>
        <Typography
          sx={{
            typography: { xs: "h5", md: "h4" },
            overflowWrap: "anywhere",
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item mb={14}>
        <Typography
          sx={{
            typography: { xs: "body2", md: "body1" },
            overflowWrap: "anywhere",
            color: "GrayText",
            textAlign: "center",
            maxWidth: { xs: "350px", md: "500px" },
          }}
        >
          {description}
        </Typography>
      </Grid>
      <Grid item>
        <PaymentPageFooter
          poweredBySx={{
            justifyContent: "center",
          }}
        />
      </Grid>
    </Grid>
  );
};

export default InfoPage;
