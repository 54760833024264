import { NotFoundPaymentPage } from "./../../widget/payment-page";
import { CSSProperties } from "react";
import { useLocation, useParams } from "react-router-dom";

import CaptchaPage from "../Captcha/Captcha.page";
import MakePaymentContainer from "./MakePayment.container";

const Styles: { [key: string]: CSSProperties } = {
  page: {
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    minHeight: "100vh",
  },
};

const MakePaymentPage = () => {
  const params = useParams();
  const location = useLocation();
  const urlPaymentId = params?.["payment_id"];

  let callbackUrl = undefined;
  let orderId = undefined;
  try {
    const urlParams = new URLSearchParams(location?.search || "");
    orderId = urlParams.get("order") || undefined;
    const callbackURLBase64 = urlParams.get("callback_url");
    callbackUrl = callbackURLBase64 ? window.atob(callbackURLBase64) : undefined;
  } catch (e) {
    // do nothing, handle silently like a warning
    console.error("orderId/callback_url is invalid");
  }

  if (!urlPaymentId) return <NotFoundPaymentPage />;

  return (
    <CaptchaPage isEnabled={!orderId}>
      <div style={Styles.page}>
        <MakePaymentContainer paymentPageId={urlPaymentId} orderId={orderId} callbackUrl={callbackUrl} />
      </div>
    </CaptchaPage>
  );
};

export default MakePaymentPage;
