const countries = [
  {
    countryCode: "US",
    countryName: "United States of America",
    countryFlag: "🇺🇸",
  },
  {
    countryCode: "AE",
    countryName: "United Arab Emirates",
    countryFlag: "🇦🇪",
  },
  {
    countryCode: "SA",
    countryName: "Saudi Arabia",
    countryFlag: "🇸🇦",
  },
  {
    countryCode: "BH",
    countryName: "Bahrain",
    countryFlag: "🇧🇭",
  },
  {
    countryCode: "SG",
    countryName: "Singapore",
    countryFlag: "🇸🇬",
  },
  {
    countryCode: "GB",
    countryName: "United Kingdom",
    countryFlag: "🇬🇧",
  },
  {
    countryCode: "MY",
    countryName: "Malaysia",
    countryFlag: "🇲🇾",
  },
  {
    countryCode: "OM",
    countryName: "Oman",
    countryFlag: "🇴🇲",
  },
  {
    countryCode: "VN",
    countryName: "Vietnam",
    countryFlag: "🇻🇳",
  },
  {
    countryCode: "TH",
    countryName: "Thailand",
    countryFlag: "🇹🇭",
  },
  {
    countryCode: "AT",
    countryName: "Austria",
    countryFlag: "🇦🇹",
  },
  {
    countryCode: "BE",
    countryName: "Belgium",
    countryFlag: "🇧🇪",
  },
  {
    countryCode: "BG",
    countryName: "Bulgaria",
    countryFlag: "🇧🇬",
  },
  {
    countryCode: "CY",
    countryName: "Cyprus",
    countryFlag: "🇨🇾",
  },
  {
    countryCode: "HR",
    countryName: "Croatia",
    countryFlag: "🇭🇷",
  },
  {
    countryCode: "CZ",
    countryName: "Czech Republic",
    countryFlag: "🇨🇿",
  },
  {
    countryCode: "DK",
    countryName: "Denmark",
    countryFlag: "🇩🇰",
  },
  {
    countryCode: "EE",
    countryName: "Estonia",
    countryFlag: "🇪🇪",
  },
  {
    countryCode: "FI",
    countryName: "Finland",
    countryFlag: "🇫🇮",
  },
  {
    countryCode: "FR",
    countryName: "France",
    countryFlag: "🇫🇷",
  },
  {
    countryCode: "DE",
    countryName: "Germany",
    countryFlag: "🇩🇪",
  },
  {
    countryCode: "GR",
    countryName: "Greece",
    countryFlag: "🇬🇷",
  },
  {
    countryCode: "HU",
    countryName: "Hungary",
    countryFlag: "🇭🇺",
  },
  {
    countryCode: "IE",
    countryName: "Ireland",
    countryFlag: "🇮🇪",
  },
  {
    countryCode: "IT",
    countryName: "Italy",
    countryFlag: "🇮🇹",
  },
  {
    countryCode: "LV",
    countryName: "Latvia",
    countryFlag: "🇱🇻",
  },
  {
    countryCode: "LT",
    countryName: "Lithuania",
    countryFlag: "🇱🇹",
  },
  {
    countryCode: "LU",
    countryName: "Luxembourg",
    countryFlag: "🇱🇺",
  },
  {
    countryCode: "MT",
    countryName: "Malta",
    countryFlag: "🇲🇹",
  },
  {
    countryCode: "NL",
    countryName: "Netherlands",
    countryFlag: "🇳🇱",
  },
  {
    countryCode: "PL",
    countryName: "Poland",
    countryFlag: "🇵🇱",
  },
  {
    countryCode: "PT",
    countryName: "Portugal",
    countryFlag: "🇵🇹",
  },
  {
    countryCode: "RO",
    countryName: "Romania",
    countryFlag: "🇷🇴",
  },
  {
    countryCode: "SI",
    countryName: "Slovenia",
    countryFlag: "🇸🇮",
  },
  {
    countryCode: "SK",
    countryName: "Slovakia",
    countryFlag: "🇸🇰",
  },
  {
    countryCode: "ES",
    countryName: "Spain",
    countryFlag: "🇪🇸",
  },
  {
    countryCode: "SE",
    countryName: "Sweden",
    countryFlag: "🇸🇪",
  },
  {
    countryCode: "NO",
    countryName: "Norway",
    countryFlag: "🇳🇴",
  },
  {
    countryCode: "LI",
    countryName: "Liechtenstein",
    countryFlag: "🇱🇮",
  },
  {
    countryCode: "IS",
    countryName: "Iceland",
    countryFlag: "🇮🇸",
  },
  {
    countryCode: "CH",
    countryName: "Switzerland",
    countryFlag: "🇨🇭",
  },
  {
    countryCode: "MC",
    countryName: "Monaco",
    countryFlag: "🇲🇨",
  },
  {
    countryCode: "CN",
    countryName: "China",
    countryFlag: "🇨🇳",
  },
  {
    countryCode: "NG",
    countryName: "Nigeria",
    countryFlag: "🇳🇬",
  },
  {
    countryCode: "QA",
    countryName: "Qatar",
    countryFlag: "🇶🇦",
  },
  {
    countryCode: "HK",
    countryName: "Hong Kong",
    countryFlag: "🇭🇰",
  },
  {
    countryCode: "JP",
    countryName: "Japan",
    countryFlag: "🇯🇵",
  },
  {
    countryCode: "KR",
    countryName: "South Korea",
    countryFlag: "🇰🇷",
  },
  {
    countryCode: "TW",
    countryName: "Taiwan",
    countryFlag: "🇹🇼",
  },
  {
    countryCode: "AU",
    countryName: "Australia",
    countryFlag: "🇦🇺",
  },
  {
    countryCode: "ID",
    countryName: "Indonesia",
    countryFlag: "🇮🇩",
  },
  {
    countryCode: "BN",
    countryName: "Brunei",
    countryFlag: "🇧🇳",
  },
  {
    countryCode: "PH",
    countryName: "Philippines",
    countryFlag: "🇵🇭",
  },
  {
    countryCode: "KH",
    countryName: "Cambodia",
    countryFlag: "🇰🇭",
  },
  {
    countryCode: "LK",
    countryName: "Sri Lanka",
    countryFlag: "🇱🇰",
  },
  {
    countryCode: "KW",
    countryName: "Kuwait",
    countryFlag: "🇰🇼",
  },
  {
    countryCode: "KE",
    countryName: "Kenya",
    countryFlag: "🇰🇪",
  },
  {
    countryCode: "BD",
    countryName: "Bangladesh",
    countryFlag: "🇧🇩",
  },
  {
    countryCode: "JO",
    countryName: "Jordan",
    countryFlag: "🇯🇴",
  },
  {
    countryCode: "NP",
    countryName: "Nepal",
    countryFlag: "🇳🇵",
  },
  {
    countryCode: "ZA",
    countryName: "South Africa",
    countryFlag: "🇿🇦",
  },
  {
    countryCode: "IL",
    countryName: "Israel",
    countryFlag: "🇮🇱",
  },
  {
    countryCode: "TR",
    countryName: "Turkey",
    countryFlag: "🇹🇷",
  },
  {
    countryCode: "MO",
    countryName: "Macau",
    countryFlag: "🇲🇴",
  },
  {
    countryCode: "MX",
    countryName: "Mexico",
    countryFlag: "🇲🇽",
  },
  {
    countryCode: "DZ",
    countryName: "Algeria",
    countryFlag: "🇩🇿",
  },
  {
    countryCode: "AR",
    countryName: "Argentina",
    countryFlag: "🇦🇷",
  },
  {
    countryCode: "PR",
    countryName: "Puerto Rico",
    countryFlag: "🇵🇷",
  },
  {
    countryCode: "CO",
    countryName: "Colombia",
    countryFlag: "🇨🇴",
  },
  {
    countryCode: "CL",
    countryName: "Chile",
    countryFlag: "🇨🇱",
  },
  {
    countryCode: "PE",
    countryName: "Peru",
    countryFlag: "🇵🇪",
  },
  {
    countryCode: "BR",
    countryName: "Brazil",
    countryFlag: "🇧🇷",
  },
  {
    countryCode: "KZ",
    countryName: "Kazakhstan",
    countryFlag: "🇰🇿",
  },
  {
    countryCode: "KG",
    countryName: "Kyrgyzstan",
    countryFlag: "🇰🇬",
  },
  {
    countryCode: "UZ",
    countryName: "Uzbekistan",
    countryFlag: "🇺🇿",
  },
  {
    countryCode: "TM",
    countryName: "Turkmenistan",
    countryFlag: "🇹🇲",
  },
  {
    countryCode: "TZ",
    countryName: "Tanzania",
    countryFlag: "🇹🇿",
  },
  {
    countryCode: "MZ",
    countryName: "Mozambique",
    countryFlag: "🇲🇿",
  },
  {
    countryCode: "PG",
    countryName: "Papua New Guinea",
    countryFlag: "🇵🇬",
  },
  {
    countryCode: "ET",
    countryName: "Ethiopia",
    countryFlag: "🇪🇹",
  },
  {
    countryCode: "CM",
    countryName: "Cameroon",
    countryFlag: "🇨🇲",
  },
  {
    countryCode: "GQ",
    countryName: "Equatorial Guinea",
    countryFlag: "🇬🇶",
  },
  {
    countryCode: "GM",
    countryName: "Gambia",
    countryFlag: "🇬🇲",
  },
  {
    countryCode: "SC",
    countryName: "Seychelles",
    countryFlag: "🇸🇨",
  },
  {
    countryCode: "MV",
    countryName: "Maldives",
    countryFlag: "🇲🇻",
  },
  {
    countryCode: "FJ",
    countryName: "Fiji",
    countryFlag: "🇫🇯",
  },
  {
    countryCode: "CA",
    countryName: "Canada",
    countryFlag: "🇨🇦",
  },
  {
    countryCode: "PK",
    countryName: "Pakistan",
    countryFlag: "🇵🇰",
  },
  {
    countryCode: "MM",
    countryName: "Myanmar",
    countryFlag: "🇲🇲",
  },
  {
    countryCode: "GH",
    countryName: "Ghana",
    countryFlag: "🇬🇭",
  },
  {
    countryCode: "MU",
    countryName: "Mauritius",
    countryFlag: "🇲🇺",
  },
  {
    countryCode: "PA",
    countryName: "Panama",
    countryFlag: "🇵🇦",
  },
  {
    countryCode: "EG",
    countryName: "Egypt",
    countryFlag: "🇪🇬",
  },
  {
    countryCode: "UG",
    countryName: "Uganda",
    countryFlag: "🇺🇬",
  },
  {
    countryCode: "NZ",
    countryName: "New Zealand",
    countryFlag: "🇳🇿",
  },
  {
    countryCode: "BW",
    countryName: "Botswana",
    countryFlag: "🇧🇼",
  },
  {
    countryCode: "MA",
    countryName: "Morocco",
    countryFlag: "🇲🇦",
  },
  {
    countryCode: "BF",
    countryName: "Burkina Faso",
    countryFlag: "🇧🇫",
  },
  {
    countryCode: "CI",
    countryName: "Ivory Coast",
    countryFlag: "🇨🇮",
  },
  {
    countryCode: "MG",
    countryName: "Madagascar",
    countryFlag: "🇲🇬",
  },
  {
    countryCode: "GA",
    countryName: "Gabon",
    countryFlag: "🇬🇦",
  },
  {
    countryCode: "AL",
    countryName: "Albania",
    countryFlag: "🇦🇱",
  },
  {
    countryCode: "AM",
    countryName: "Armenia",
    countryFlag: "🇦🇲",
  },
  {
    countryCode: "CW",
    countryName: "Curaçao",
    countryFlag: "🇨🇼",
  },
  {
    countryCode: "AO",
    countryName: "Angola",
    countryFlag: "🇦🇴",
  },
  {
    countryCode: "AW",
    countryName: "Aruba",
    countryFlag: "🇦🇼",
  },
  {
    countryCode: "AZ",
    countryName: "Azerbaijan",
    countryFlag: "🇦🇿",
  },
  {
    countryCode: "BA",
    countryName: "Bosnia and Herzegovina",
    countryFlag: "🇧🇦",
  },
  {
    countryCode: "BB",
    countryName: "Barbados",
    countryFlag: "🇧🇧",
  },
  {
    countryCode: "BI",
    countryName: "Burundi",
    countryFlag: "🇧🇮",
  },
  {
    countryCode: "BM",
    countryName: "Bermuda",
    countryFlag: "🇧🇲",
  },
  {
    countryCode: "BS",
    countryName: "Bahamas",
    countryFlag: "🇧🇸",
  },
  {
    countryCode: "BZ",
    countryName: "Belize",
    countryFlag: "🇧🇿",
  },
  {
    countryCode: "CR",
    countryName: "Costa Rica",
    countryFlag: "🇨🇷",
  },
  {
    countryCode: "CV",
    countryName: "Cape Verde",
    countryFlag: "🇨🇻",
  },
  {
    countryCode: "DJ",
    countryName: "Djibouti",
    countryFlag: "🇩🇯",
  },
  {
    countryCode: "GL",
    countryName: "Greenland",
    countryFlag: "🇬🇱",
  },
  {
    countryCode: "FO",
    countryName: "Faroe Islands",
    countryFlag: "🇫🇴",
  },
  {
    countryCode: "DO",
    countryName: "Dominican Republic",
    countryFlag: "🇩🇴",
  },
  {
    countryCode: "FK",
    countryName: "Falkland Islands",
    countryFlag: "🇫🇰",
  },
  {
    countryCode: "GE",
    countryName: "Georgia",
    countryFlag: "🇬🇪",
  },
  {
    countryCode: "GI",
    countryName: "Gibraltar",
    countryFlag: "🇬🇮",
  },
  {
    countryCode: "GT",
    countryName: "Guatemala",
    countryFlag: "🇬🇹",
  },
  {
    countryCode: "GY",
    countryName: "Guyana",
    countryFlag: "🇬🇾",
  },
  {
    countryCode: "HN",
    countryName: "Honduras",
    countryFlag: "🇭🇳",
  },
  {
    countryCode: "HT",
    countryName: "Haiti",
    countryFlag: "🇭🇹",
  },
  {
    countryCode: "JM",
    countryName: "Jamaica",
    countryFlag: "🇯🇲",
  },
  {
    countryCode: "KM",
    countryName: "Comoros",
    countryFlag: "🇰🇲",
  },
  {
    countryCode: "KY",
    countryName: "Cayman Islands",
    countryFlag: "🇰🇾",
  },
  {
    countryCode: "LA",
    countryName: "Laos",
    countryFlag: "🇱🇦",
  },
  {
    countryCode: "LB",
    countryName: "Lebanon",
    countryFlag: "🇱🇧",
  },
  {
    countryCode: "LR",
    countryName: "Liberia",
    countryFlag: "🇱🇷",
  },
  {
    countryCode: "LS",
    countryName: "Lesotho",
    countryFlag: "🇱🇸",
  },
  {
    countryCode: "MD",
    countryName: "Moldova",
    countryFlag: "🇲🇩",
  },
  {
    countryCode: "MN",
    countryName: "Mongolia",
    countryFlag: "🇲🇳",
  },
  {
    countryCode: "MR",
    countryName: "Mauritania",
    countryFlag: "🇲🇷",
  },
  {
    countryCode: "MW",
    countryName: "Malawi",
    countryFlag: "🇲🇼",
  },
  {
    countryCode: "NA",
    countryName: "Namibia",
    countryFlag: "🇳🇦",
  },
  {
    countryCode: "NI",
    countryName: "Nicaragua",
    countryFlag: "🇳🇮",
  },
  {
    countryCode: "PY",
    countryName: "Paraguay",
    countryFlag: "🇵🇾",
  },
  {
    countryCode: "RS",
    countryName: "Serbia",
    countryFlag: "🇷🇸",
  },
  {
    countryCode: "SB",
    countryName: "Solomon Islands",
    countryFlag: "🇸🇧",
  },
  {
    countryCode: "SH",
    countryName: "Saint Helena",
    countryFlag: "🇸🇭",
  },
  {
    countryCode: "SL",
    countryName: "Sierra Leone",
    countryFlag: "🇸🇱",
  },
  {
    countryCode: "SR",
    countryName: "Suriname",
    countryFlag: "🇸🇷",
  },
  {
    countryCode: "ST",
    countryName: "São Tomé and Príncipe",
    countryFlag: "🇸🇹",
  },
  {
    countryCode: "SZ",
    countryName: "Eswatini",
    countryFlag: "🇸🇿",
  },
  {
    countryCode: "TJ",
    countryName: "Tajikistan",
    countryFlag: "🇹🇯",
  },
  {
    countryCode: "TO",
    countryName: "Tonga",
    countryFlag: "🇹🇴",
  },
  {
    countryCode: "TT",
    countryName: "Trinidad and Tobago",
    countryFlag: "🇹🇹",
  },
  {
    countryCode: "UA",
    countryName: "Ukraine",
    countryFlag: "🇺🇦",
  },
  {
    countryCode: "UY",
    countryName: "Uruguay",
    countryFlag: "🇺🇾",
  },
  {
    countryCode: "VU",
    countryName: "Vanuatu",
    countryFlag: "🇻🇺",
  },
  {
    countryCode: "WS",
    countryName: "Samoa",
    countryFlag: "🇼🇸",
  },
  {
    countryCode: "TD",
    countryName: "Chad",
    countryFlag: "🇹🇩",
  },
  {
    countryCode: "AI",
    countryName: "Anguilla",
    countryFlag: "🇦🇮",
  },
  {
    countryCode: "AG",
    countryName: "Antigua and Barbuda",
    countryFlag: "🇦🇬",
  },
  {
    countryCode: "DM",
    countryName: "Dominica",
    countryFlag: "🇩🇲",
  },
  {
    countryCode: "GD",
    countryName: "Grenada",
    countryFlag: "🇬🇩",
  },
  {
    countryCode: "KN",
    countryName: "Saint Kitts and Nevis",
    countryFlag: "🇰🇳",
  },
  {
    countryCode: "LC",
    countryName: "Saint Lucia",
    countryFlag: "🇱🇨",
  },
  {
    countryCode: "VC",
    countryName: "Saint Vincent and the Grenadines",
    countryFlag: "🇻🇨",
  },
  {
    countryCode: "BJ",
    countryName: "Benin",
    countryFlag: "🇧🇯",
  },
  {
    countryCode: "GW",
    countryName: "Guinea-Bissau",
    countryFlag: "🇬🇼",
  },
  {
    countryCode: "ML",
    countryName: "Mali",
    countryFlag: "🇲🇱",
  },
  {
    countryCode: "SX",
    countryName: "Sint Maarten",
    countryFlag: "🇸🇽",
  },
  {
    countryCode: "NE",
    countryName: "Niger",
    countryFlag: "🇳🇪",
  },
  {
    countryCode: "SN",
    countryName: "Senegal",
    countryFlag: "🇸🇳",
  },
  {
    countryCode: "TG",
    countryName: "Togo",
    countryFlag: "🇹🇬",
  },
  {
    countryCode: "NC",
    countryName: "New Caledonia",
    countryFlag: "🇳🇨",
  },
  {
    countryCode: "PF",
    countryName: "French Polynesia",
    countryFlag: "🇵🇫",
  },
  {
    countryCode: "WF",
    countryName: "Wallis and Futuna",
    countryFlag: "🇼🇫",
  },
  {
    countryCode: "ZM",
    countryName: "Zambia",
    countryFlag: "🇿🇲",
  },
  {
    countryCode: "MS",
    countryName: "Montserrat",
    countryFlag: "🇲🇸",
  },
  {
    countryCode: "BO",
    countryName: "Bolivia",
    countryFlag: "🇧🇴",
  },
];

export const countriesList = countries.map((country) => ({
  label: country.countryName,
  value: country.countryCode,
  flag: country.countryFlag,
}));
