import React from "react";
import Dialog from "@jodo-tech/ui-kit-common/design/Dialog";
import DialogTitle from "@jodo-tech/ui-kit-common/design/DialogTitle";
import IconButton from "@jodo-tech/ui-kit-common/design/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { isMobile } from "./PaymentPage.utils";

const CashfreeModal = ({ visible, onClose }) => {
  return (
    <Dialog
      open={true}
      // display:none is intentionally, as we always want to render the content of the dialog
      // so that whenever a payment is initiated,
      // the payment sdk makes sure to put the content on the div with that id.
      sx={{ display: visible ? undefined : "none" }}
      disableScrollLock
      fullScreen={isMobile}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
          borderRadius: "16px",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          background: "linear-gradient(89.83deg, #2C67D3 2.91%, #134CB4 99.9%)",
          color: "#fff",
        }}
      >
        <IconButton onClick={onClose} aria-label="close" sx={{ p: 0, color: "#fff" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {/* if you are changing this id, then look for impacted areas */}
      {/* this id should always be rendering over the the dom */}
      <div id="cashfree_modal_content_id" />
    </Dialog>
  );
};

export default CashfreeModal;
