import { EventEmitter } from "@jodo-tech/ui-kit-common/utils/emitter";
import axios, { CreateAxiosDefaults } from "axios";

// import { AUTH_TOKEN_TOPIC } from "../helper/constants";
import { Env } from "../helper/env";

const isStaticAuth = Env.REACT_APP_AUTH_MODE === "STATIC_AUTH";

export const createApi = (config: CreateAxiosDefaults) => {
  const api = axios.create(config);

  api.interceptors.request.use((config: any) => {
    if (Env.REACT_APP_AUTH_MODE === "GATEWAY_TOKEN") {
      // const { token } = EventEmitter.getData(AUTH_TOKEN_TOPIC) || {};
      config.headers = {
        ...config.headers,
        Authorization: "none",
        // "X-Auth-Token": token,
      };
    }
    return config;
  });

  api.interceptors.response.use(
    (response) => {
      // make sure we return response always
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        // you can use this event to handle from anywhere
        EventEmitter.emit("PaymentApp/UnAuthenticate", { source: "AxiosInterceptor" });
      }
      return Promise.reject(error);
    },
  );

  return api;
};

let mdHeaders = {};
let payHeaders = {};

if (isStaticAuth) {
  const staticAuthConfig = Env.REACT_APP_STATIC_AUTH_CONFIG;
  mdHeaders = {
    username: staticAuthConfig.md.username,
    password: staticAuthConfig.md.password,
  };
  payHeaders = {
    username: staticAuthConfig.pay.username,
    password: staticAuthConfig.pay.password,
  };
}

const tokenApi = createApi({
  baseURL: Env.REACT_APP_TOKEN_API_BASE_URL,
  withCredentials: true,
  headers: {
    Authorization: "none",
  },
});
const mdApi = createApi({ baseURL: Env.REACT_APP_MD_API_BASE_URL, headers: mdHeaders, withCredentials: true });
const payApi = createApi({ baseURL: Env.REACT_APP_PAY_API_BASE_URL, headers: payHeaders, withCredentials: true });

export { mdApi, payApi, tokenApi };
